import { gql } from '@apollo/client';

// const CREATE_USER = gql``;

const GET_USER = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

const GET_USER_USING_FIREBASEUID = gql`
  query GetUserUsingFirebaseUid($firebaseUid: String!) {
    getUserUsingFirebaseUid(firebaseUid: $firebaseUid) {
      id
      completedTours
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $location: String
    $aboutMe: String
    $handle: String
    $handleChangeDate: Date
    $phone: String
    $is24HourTime: Boolean
    $showPilotLabsPrompt: Boolean
    $disableSounds: Boolean
    $profilePicture: String
    $coverImage: String
    $labDisabled: Boolean
    $completedTours: [String!]
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      location: $location
      aboutMe: $aboutMe
      phone: $phone
      handle: $handle
      handleChangeDate: $handleChangeDate
      is24HourTime: $is24HourTime
      showPilotLabsPrompt: $showPilotLabsPrompt
      disableSounds: $disableSounds
      profilePicture: $profilePicture
      coverImage: $coverImage
      labDisabled: $labDisabled
      completedTours: $completedTours
    ) {
      id
      firstName
      lastName
      location
      aboutMe
      phone
      is24HourTime
      showPilotLabsPrompt
      disableSounds
      profilePicture
      coverImage
      labDisabled
      completedTours
    }
  }
`;

const DELETE_USER = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

// const DELETE_USER = gql``;

const GET_TRIP = gql`
  query GetTripData($id: ID!) {
    getTripData(id: $id) {
      items {
        id
        title
        type
        tripId
        likes
        createdBy
        updatedBy
        children
        files {
          id
          name
          url
          storagePath
          size
          mimeType
          createdBy {
            id
            firstName
            lastName
            profilePicture
          }
          createdAt
          updatedAt
          attachedToType
          attachedToId
          attachedItems
          tripId
          type
        }
        mapPin {
          id
          type
          tripId
          lat
          long
          placeId
          hotelId
          categoryId
          pinColor
          pinDetails {
            title
            photo
            rating
            ratingCount
            website
            mapUrl
            types
          }
        }
        content {
          ... on Accommodation {
            checkInDate
            checkOutDate
            address {
              city
              country
              state
              streetAddress
              zipCode
            }
            expense {
              amount
              costType
              currency
            }
            links
            description
            isBooking
            bookingId
          }
          ... on Activity {
            description
            address {
              city
              country
              state
              streetAddress
              zipCode
            }
            expense {
              amount
              costType
              currency
            }
            links
            startDate
            endDate
            version
          }
          ... on Destination {
            address {
              city
              country
              state
              streetAddress
              zipCode
            }
            startDate
            endDate
            version
          }
          ... on Transportation {
            transportType
            connections
          }
          ... on Transport {
            flightNumber
            fromAirport
            toAirport
            startDate
            endDate
            expense {
              amount
              currency
              costType
            }
            description
            links
          }
          ... on Heading {
            startDate
            endDate
            version
          }
        }
      }
      trip {
        id
        title
        bookings {
          id
          packageId
          reference
          orderId
          segmentId
          status
          type
          bookingDetails {
            ... on HotelBookingDetailsV2 {
              bookingId
              hotelId
              status
              checkin
              checkout
              isRefundable
              guests
              hotelRoomsRates
              totalPrice
              currency
              primaryBookerUserId
              hotelName
              price
              currency
              guestId
              cancellationPolicies
              holder
              clientReference
              supplierBookingId
              supplierBookingName
              supplier
              supplierId
              hotelConfirmationCode
              hotel
              bookedRooms
              specialRemarks
              optionalFees
              mandatoryFees
              knowBeforeYouGo
              createdAt
            }
          }
        }
        invitations {
          id
          email
        }
        owner {
          id
          firstName
          lastName
          email
          profilePicture
          firebaseUid
        }
        users {
          id
          firstName
          lastName
          email
          profilePicture
          firebaseUid
        }
        coverImage
        saved {
          id
          thumbnail
          longitude
          latitude
          title
          referenceId
          priceTier
          savedData
          type
          tripId
        }
        startDate
        endDate
        notes
        defaultCurrency
        isDeletedTrip
        children
        saved {
          id
          thumbnail
          longitude
          latitude
          title
          referenceId
          priceTier
          savedData
          type
          tripId
        }
        files {
          id
          name
          url
          storagePath
          size
          mimeType
          createdBy {
            id
            firstName
            lastName
            profilePicture
          }
          createdAt
          updatedAt
          attachedToType
          attachedToId
          attachedItems
          tripId
          type
          import {
            id
            items {
              attachedToItem
              type
              placeId
              accommodation {
                id
                name
                checkInDate
                checkOutDate
                streetAddress
                city
                state
                country
                zipCode
                cost
                currency
                costPer
                notes
                isBooking
                bookingId
              }
              activity {
                id
                title
                description
                cost
                currency
                costPer
                activityTime
                streetAddress
                city
                state
                country
                zipCode
              }
              flight {
                id
                flightNumber
                fromAirport
                toAirport
                fromDate
                fromTime
                toDate
                toTime
                flightTime
                cost
                currency
                costPer
                notes
              }
              transport {
                id
                type
                flightNumber
                fromAirport
                toAirport
                fromDate
                fromTime
                toDate
                toTime
                flightTime
                cost
                currency
                costPer
                notes
              }
            }
            status
            error
          }
        }
        mapPins {
          id
          type
          tripId
          lat
          long
          placeId
          hotelId
          categoryId
          pinColor
          pinDetails {
            title
            photo
            rating
            ratingCount
            website
            mapUrl
            types
          }
        }
      }
    }
  }
`;

const GET_ALL_TRIPS = gql`
  query GetUserTrips($userId: ID) {
    getUserTrips(userId: $userId) {
      id
      title
      coverImage
      startDate
      endDate
      notes
      defaultCurrency
      isDeletedTrip
      invitations {
        id
        email
      }
      owner {
        id
        firstName
        lastName
        email
        profilePicture
      }
      users {
        id
        firstName
        lastName
        email
        profilePicture
      }
    }
  }
`;

const CREATE_TRIP = gql`
  mutation CreateTrip(
    $title: String!
    $startDate: Date
    $endDate: Date
    $coverImage: String
  ) {
    createTrip(
      title: $title
      startDate: $startDate
      endDate: $endDate
      coverImage: $coverImage
    ) {
      title
      coverImage
      startDate
      endDate
      notes
      defaultCurrency
      isDeletedTrip
      id
    }
  }
`;

const ADD_EXAMPLE_TRIP = gql`
  mutation ($userId: ID!) {
    addExampleTrip(userId: $userId)
  }
`;

const UPDATE_TRIP = gql`
  mutation UpdateTrip(
    $id: ID!
    $title: String
    $owner: ID
    $isDeletedTrip: Boolean
    $coverImage: String
    $startDate: Date
    $endDate: Date
    $notes: [ID!]
  ) {
    updateTrip(
      id: $id
      title: $title
      owner: $owner
      isDeletedTrip: $isDeletedTrip
      coverImage: $coverImage
      startDate: $startDate
      endDate: $endDate
      notes: $notes
    ) {
      coverImage
      startDate
      endDate
      notes
      defaultCurrency
      title
      id
      isDeletedTrip
      notes
    }
  }
`;

const DELETE_TRIP = gql`
  mutation DeleteTrip($id: ID!) {
    deleteTrip(id: $id)
  }
`;

const GET_ITEM = gql`
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      title
      type
      tripId
      children
      files {
        id
        name
        url
        storagePath
        size
        mimeType
        createdBy {
          id
          firstName
          lastName
          profilePicture
        }
        createdAt
        updatedAt
        attachedToType
        attachedToId
        attachedItems
        tripId
        type
      }
      content {
        ... on Accommodation {
          checkInDate
          checkOutDate
          address {
            city
            country
            state
            streetAddress
            zipCode
          }
          expense {
            amount
            costType
            currency
          }
          links
          description
          isBooking
          bookingId
        }
        ... on Activity {
          activityName
          duration
          location
        }
        ... on Destination {
          description
        }
        ... on Transport {
          flightNumber
          fromAirport
          toAirport
          startDate
          endDate
          expense {
            amount
            currency
            costType
          }
          description
          links
        }
        ... on Heading {
          startDate
          endDate
        }
      }
    }
  }
`;

const GET_ALL_ITEMS = gql`
  query GetAllItems($tripId: ID!) {
    getAllItems(tripId: $tripId) {
      id
      title
      type
      tripId
      likes
      files {
        id
        name
        url
        storagePath
        size
        mimeType
        createdBy {
          id
          firstName
          lastName
          profilePicture
        }
        createdAt
        updatedAt
        attachedToType
        attachedToId
        attachedItems
        tripId
        type
      }
      content {
        ... on Accommodation {
          checkInDate
          checkOutDate
          address {
            city
            country
            state
            streetAddress
            zipCode
          }
          expense {
            amount
            costType
            currency
          }
          links
          description
          isBooking
          bookingId
        }
        ... on Activity {
          activityName
          duration
          location
        }
        ... on Destination {
          description
        }
        ... on Transport {
          flightNumber
          fromAirport
          toAirport
          startDate
          endDate
          expense {
            amount
            currency
            costType
          }
          description
          links
        }
        ... on Heading {
          startDate
          endDate
        }
      }
      children
    }
  }
`;

const CREATE_ITEM = gql`
  mutation CreateItem(
    $title: String
    $type: ItemType!
    $parentId: ID
    $tripId: ID!
    $mapPin: ID
    $content: ContentInput
    $index: Int!
    $ignoreParentUpdate: Boolean
  ) {
    createItem(
      title: $title
      type: $type
      tripId: $tripId
      parentId: $parentId
      content: $content
      index: $index
      ignoreParentUpdate: $ignoreParentUpdate
      mapPin: $mapPin
    ) {
      id
      title
      type
      tripId
      likes
      createdBy
      updatedBy
      mapPin {
        id
      }
      files {
        id
        name
        url
        storagePath
        size
        mimeType
        createdBy {
          id
          firstName
          lastName
          profilePicture
        }
        createdAt
        updatedAt
        attachedToType
        attachedToId
        attachedItems
        tripId
        type
      }
      content {
        ... on Accommodation {
          checkInDate
          checkOutDate
          address {
            city
            country
            state
            streetAddress
            zipCode
          }
          expense {
            amount
            costType
            currency
          }
          links
          description
          isBooking
          bookingId
        }
        ... on Activity {
          description
          startDate
          links
          address {
            city
            country
            state
            streetAddress
            zipCode
          }
        }
        ... on Destination {
          startDate
          endDate
          address {
            city
            country
            state
            streetAddress
            zipCode
          }
        }
        ... on Transport {
          flightNumber
          fromAirport
          toAirport
          startDate
          endDate
          expense {
            amount
            currency
            costType
          }
          description
          links
        }
        ... on Transportation {
          transportType
          connections
        }
        ... on Heading {
          startDate
          endDate
        }
      }
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation UpdateItem(
    $id: ID!
    $title: String
    $type: ItemType
    $mapPin: ID
    $files: [ID!]
    $likes: [ID!]
    $content: ContentInput
  ) {
    updateItem(
      id: $id
      title: $title
      type: $type
      content: $content
      mapPin: $mapPin
      likes: $likes
      files: $files
    ) {
      id
      title
      type
      tripId
      likes
      createdBy
      updatedBy
      files {
        id
        name
        url
        storagePath
        size
        mimeType
        createdBy {
          id
          firstName
          lastName
          profilePicture
        }
        createdAt
        updatedAt
        attachedToType
        attachedToId
        attachedItems
        tripId
        type
      }
      content {
        ... on Accommodation {
          checkInDate
          checkOutDate
          address {
            city
            country
            state
            streetAddress
            zipCode
          }
          expense {
            amount
            costType
            currency
          }
          links
          description
          isBooking
          bookingId
        }
        ... on Transport {
          flightNumber
          fromAirport
          toAirport
          startDate
          endDate
          expense {
            amount
            currency
            costType
          }
          description
          links
        }
        ... on Activity {
          description
          startDate
          links
          expense {
            amount
            currency
            costType
          }
          address {
            city
            country
            state
            streetAddress
            zipCode
          }
        }
      }
    }
  }
`;

const DELETE_ITEM = gql`
  mutation DeleteItem($id: ID!, $parentId: ID, $tripId: ID) {
    deleteItem(id: $id, parentId: $parentId, tripId: $tripId) {
      id
    }
  }
`;

const CREATE_MAP_PIN = gql`
  mutation createMapPin(
    $lat: Float
    $long: Float
    $pinDetails: PinDetailsInput
    $type: String
    $placeId: String
    $hotelId: String
    $categoryId: String
    $pinColor: String
    $tripId: ID
    $parentId: ID
  ) {
    createMapPin(
      lat: $lat
      long: $long
      pinDetails: $pinDetails
      type: $type
      placeId: $placeId
      hotelId: $hotelId
      categoryId: $categoryId
      pinColor: $pinColor
      tripId: $tripId
      parentId: $parentId
    ) {
      id
      type
      tripId
      lat
      long
      placeId
      hotelId
      categoryId
      pinColor
      pinDetails {
        title
        photo
        rating
        ratingCount
        website
        mapUrl
        types
      }
    }
  }
`;

const DELETE_MAP_PIN = gql`
  mutation DeleteMapPin($id: ID!, $itemId: ID, $itemType: String) {
    deleteMapPin(id: $id, itemId: $itemId, itemType: $itemType)
  }
`;

const UPDATE_MAP_PIN = gql`
  mutation UpdateMapPin(
    $id: ID!
    $lat: Float
    $long: Float
    $pinDetails: PinDetailsInput
    $type: String
    $placeId: String
    $categoryId: String
    $pinColor: String
  ) {
    updateMapPin(
      id: $id
      lat: $lat
      long: $long
      pinDetails: $pinDetails
      type: $type
      placeId: $placeId
      categoryId: $categoryId
      pinColor: $pinColor
    ) {
      id
    }
  }
`;

const MOVE_ITEM = gql`
  mutation MoveItem(
    $itemId: ID!
    $sourceId: ID!
    $sourceType: MoveItemType!
    $targetId: ID!
    $targetType: MoveItemType!
    $targetIndex: Int
  ) {
    moveItem(
      itemId: $itemId
      sourceId: $sourceId
      sourceType: $sourceType
      targetId: $targetId
      targetType: $targetType
      targetIndex: $targetIndex
    ) {
      message
      error
    }
  }
`;

const CREATE_FILE = gql`
  mutation CreateFile($file: FileInput!) {
    createFile(file: $file) {
      id
      name
      url
      storagePath
      size
      mimeType
      createdBy {
        id
        firstName
        lastName
        profilePicture
      }
      createdAt
      updatedAt
      attachedToType
      attachedToId
      attachedItems
      tripId
      type
    }
  }
`;

const UPDATE_FILE = gql`
  mutation UpdateFile($id: ID!, $file: FileInput!) {
    updateFile(id: $id, file: $file) {
      id
      name
      url
      storagePath
      size
      mimeType
      createdBy {
        id
        firstName
        lastName
        profilePicture
      }
      createdAt
      updatedAt
      attachedToType
      attachedToId
      attachedItems
      tripId
      type
    }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`;

const ATTACH_FILE_TO_ITEMS = gql`
  mutation updateFileAttachmentToItems(
    $id: ID!
    $removeFromItems: [ID!]
    $addToItems: [ID!]
  ) {
    updateFileAttachmentToItems(
      id: $id
      removeFromItems: $removeFromItems
      addToItems: $addToItems
    ) {
      id
    }
  }
`;

const CREATE_INVITATION = gql`
  mutation CreateInvitation($tripId: ID!, $userEmail: String!) {
    createInvitation(tripId: $tripId, userEmail: $userEmail) {
      id
    }
  }
`;

const DELETE_INVITATION = gql`
  mutation DeleteInvitation($id: ID!, $tripId: ID!, $joinTrip: Boolean) {
    deleteInvitation(id: $id, tripId: $tripId, joinTrip: $joinTrip) {
      id
    }
  }
`;

const ADD_USER_TO_TRIP = gql`
  mutation AddUserToTrip(
    $tripId: ID
    $userId: ID
    $firebaseUid: String
    $userEmail: String
  ) {
    addUserToTrip(
      tripId: $tripId
      userId: $userId
      firebaseUid: $firebaseUid
      userEmail: $userEmail
    ) {
      tripExists
      userAddedToTrip
    }
  }
`;

const REMOVE_USER_FROM_TRIP = gql`
  mutation RemoveUserFromTrip($tripId: ID, $userId: ID) {
    removeUserFromTrip(tripId: $tripId, userId: $userId)
  }
`;

const GET_TRIP_META = gql`
  query getTripMeta($id: ID!) {
    getTripMeta(id: $id) {
      id
      title
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      users {
        id
        firstName
        lastName
        profilePicture
      }
      coverImage
      startDate
      endDate
      firstDestination {
        title
      }
    }
  }
`;

const GET_IMPORT = gql`
  query getImport($importId: ID!) {
    getImport(importId: $importId) {
      id
      name
      file
      trip
      source
      items {
        placeId
        attachedToItem
        type
        accommodation {
          id
          name
          checkInDate
          checkOutDate
          streetAddress
          city
          state
          country
          zipCode
          cost
          currency
          costPer
          notes
          isBooking
          bookingId
        }
        activity {
          id
          title
          description
          cost
          currency
          costPer
          activityTime
          streetAddress
          city
          state
          country
          zipCode
        }
        flight {
          id
          flightNumber
          fromAirport
          toAirport
          fromDate
          fromTime
          toDate
          toTime
          flightTime
          cost
          currency
          costPer
          notes
        }
        transport {
          id
          type
          flightNumber
          fromAirport
          toAirport
          fromDate
          fromTime
          toDate
          toTime
          flightTime
          cost
          currency
          costPer
          notes
        }
      }
      status
      error
    }
  }
`;
const UPDATE_IMPORT_ATTACHED_TO = gql`
  mutation updateImportAttachedTo(
    $importId: ID!
    $itemIndex: Int!
    $attachedToItem: String
  ) {
    updateImportItemAttachedTo(
      importId: $importId
      itemIndex: $itemIndex
      attachedToItem: $attachedToItem
    ) {
      file
      items {
        attachedToItem
      }
    }
  }
`;

const DELETE_IMPORT_ITEM = gql`
  mutation deleteImportItem($importId: ID!, $itemIndex: Int!) {
    deleteImportItem(importId: $importId, itemIndex: $itemIndex) {
      file
      status
    }
  }
`;

const GET_FORWAEDED_FILES_V2 = gql`
  query getForwardedFilesByUser {
    getForwardedFilesByUser {
      id
      name
      url
      size
      createdBy {
        id
        firstName
        lastName
      }
      type
      attachedToType
      attachedToId
      import {
        id
        items {
          attachedToItem
          type
          placeId
          accommodation {
            id
            name
            checkInDate
            checkOutDate
            streetAddress
            city
            state
            country
            zipCode
            cost
            currency
            costPer
            notes
            isBooking
            bookingId
          }
          activity {
            id
            title
            description
            cost
            currency
            costPer
            activityTime
            streetAddress
            city
            state
            country
            zipCode
          }
          flight {
            id
            flightNumber
            fromAirport
            toAirport
            fromDate
            fromTime
            toDate
            toTime
            flightTime
            cost
            currency
            costPer
            notes
          }
          transport {
            id
            type
            flightNumber
            fromAirport
            toAirport
            fromDate
            fromTime
            toDate
            toTime
            flightTime
            cost
            currency
            costPer
            notes
          }
        }
        status
        error
      }
    }
  }
`;
const CREATE_SAVED_ITEM = gql`
  mutation CreateSavedItem($tripId: ID!, $itemToSave: SavedItemInput!) {
    createSavedItem(tripId: $tripId, itemToSave: $itemToSave) {
      id
    }
  }
`;

const DELETE_SAVED_ITEM = gql`
  mutation DeleteSavedItem($id: ID!) {
    deleteSavedItem(id: $id)
  }
`;

const UPDATE_USER_HANDLE = gql`
  mutation UpdateUserHandle($newHandleName: String!) {
    updateUserHandle(newHandleName: $newHandleName)
  }
`;

export default {
  // CREATE_USER,
  GET_USER,
  GET_USER_USING_FIREBASEUID,
  UPDATE_USER,
  DELETE_USER,

  GET_TRIP,
  GET_ALL_TRIPS,
  CREATE_TRIP,
  ADD_EXAMPLE_TRIP,
  UPDATE_TRIP,
  DELETE_TRIP,
  GET_TRIP_META,

  CREATE_SAVED_ITEM,
  DELETE_SAVED_ITEM,

  GET_ITEM,
  GET_ALL_ITEMS,
  CREATE_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
  MOVE_ITEM,
  CREATE_MAP_PIN,
  DELETE_MAP_PIN,
  UPDATE_MAP_PIN,

  CREATE_FILE,
  UPDATE_FILE,
  DELETE_FILE,
  ATTACH_FILE_TO_ITEMS,

  CREATE_INVITATION,
  DELETE_INVITATION,

  ADD_USER_TO_TRIP,
  REMOVE_USER_FROM_TRIP,
  GET_IMPORT,
  UPDATE_IMPORT_ATTACHED_TO,
  GET_FORWAEDED_FILES_V2,
  DELETE_IMPORT_ITEM,
  UPDATE_USER_HANDLE,
};
