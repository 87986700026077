import { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getCheckoutDetails } from '../organisms/Stays/checkout/api';
import { CHECKOUT_SESSION_STATUS } from '../organisms/PublicCheckout/constants';

function PublicCheckoutLoading() {
  const { slug: checkoutSessionId } = useParams();
  const navigate = useNavigate();
  const [polling, setPolling] = useState(true);

  useEffect(() => {
    const pollStatus = async () => {
      const checkoutSession = await getCheckoutDetails(checkoutSessionId);

      if (!checkoutSession || !checkoutSession.data) return;

      if (
        checkoutSession.data?.status !== CHECKOUT_SESSION_STATUS.PAYMENT_PENDING
      ) {
        setPolling(false);
        navigate(`/checkout/${checkoutSessionId}`);
      }
    };

    const interval = setInterval(() => {
      if (polling) {
        pollStatus();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [polling, checkoutSessionId, navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#f5f5f5',
      }}>
      <CircularProgress color="primary" />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Processing your request...
      </Typography>
    </Box>
  );
}

export default PublicCheckoutLoading;
