import { useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Fade,
  InputBase,
  IconButton,
  Typography,
  Paper,
  Popper,
  TextField,
  ListItem,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AttachMoney, Link, SearchRounded } from '@mui/icons-material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EditIcon } from '@iconicicons/react';
import { LocationPinIcon, PaperClip } from '../../../atoms/Icon';
import {
  getTextWidth,
  removeTimezoneOffset,
  parseISODate,
  cleanFloatString,
} from '../../../../utils';
import { FileUploadModal, FilePreviewModal } from '../Modal';
import IconDatePicker, {
  IconDatePickerButton,
} from '../../../molecules/IconDatePicker';
import actions from '../../../../redux/actions';
import 'currency-flags/dist/currency-flags.min.css';
import { updateTripUsingAtc } from '../../../../redux/slices/TripV2';
import { trackEvents, Events } from '../../../../intercom';
import { switchLink, trackTpLink } from '../../../../tp-switcher';

const currencies = require('../../../../assets/currencies.json');

const currenciesList = [];

const useStyles = makeStyles(() => ({
  font: {
    color: '#222222',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '30px',
    alignItems: 'center',
    margin: '4px 0px 2px 0px',
  },
  subContainers: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  icon: {
    fontSize: '14px',
    color: '#4E4E4E',
  },
  iconContainer: {
    borderRadius: '4px',
    padding: '2px',
    margin: '0 4px',
    '&:hover': {
      backgroundColor: '#DEDDDD',
    },
  },
  dateIconContainer: {
    backgroundColor: ({ isPaperHovered, isPaperActive }) =>
      isPaperActive ? '#E0AD81' : isPaperHovered ? '#EEB98C' : '#FDC798',
    fillHeight: 'true',
    transition: 'none',
    padding: '2px 8px',
    color: '#4E4E4E',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
  filled: {
    display: 'flex',
    maxWidth: '140px',
    borderStyle: 'hidden',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderRadius: '4px',
    height: '80%',
    margin: '0 4px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkContainer: {
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  linkText: {
    color: '#1877F2',
    fontSize: '12px',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  linkEditIcon: {
    height: 16,
    width: 16,
    minHeight: 16,
    minWidth: 16,
    color: '#222',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  costPer: {
    padding: '0 4px',
    marginLeft: '8px',
    borderRadius: '4px',
    fontSize: '12px',
    color: '#8A8A8A',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  perList: {
    backgroundColor: '#F4F4F4',
    margin: '4px 0',
    borderRadius: '4px',
    border: '1px solid #DEDDDD',
  },
  perOption: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    padding: '4px 8px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#D9D9D9',
    },
  },
  emptyCurrencyButton: {
    borderRadius: '4px',
    color: '#4E4E4E',
    width: '14px',
    height: '14px',
    fontSize: '16px',
    '&:hover': {
      color: '#4E4E4E',
      cursor: 'pointer',
      backgroundColor: 'rgba(97, 154, 163, 0.15)',
    },
  },
  filledCurrencyButton: {
    display: 'flex',
    backgroundColor: ({ currencyListAnchor }) =>
      currencyListAnchor ? '#C6D4D6' : 'transparent',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '0 2px',
    marginRight: '4px',
    height: '14px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#C6D4D6',
    },
  },
  currencyOption: {
    fontSize: '12px',
    color: '#8A8A8A',
  },
  tooltip: {
    backgroundColor: '#4E4E4E',
    border: 'none',
    fontSize: 12,
    borderRadius: '4px',
    padding: '2px 4px',
    marginTop: '15px',
  },
  tooltipArrow: {
    border: 'none',
    color: '#4E4E4E',
  },
  currencyOptionContainer: {
    backgroundColor: '#F4F4F4',
    margin: 0,
    borderRadius: '4px',
    padding: 0,
    border: '1px solid #DEDDDD',
    boxShadow: 'none',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      maxHeight: '150px',
    },
  },
  noBorder: {
    border: 'none',
  },
  input: {
    '&::placeholder': {
      opacity: 1,
      color: '#8A8A8A',
    },
  },
}));

function TooltipWrapper({ title, children }) {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      arrow
      title={title}
      enterDelay={500}
      disableInteractive
      TransitionComponent={Fade}>
      {children}
    </Tooltip>
  );
}

function IconsDrawer({
  hasDate,
  dateVal = null,
  updateDateCallback,
  hasCost,
  costVal = null,
  updateCostCallback,
  hasLink,
  linkVal = null,
  hasLocation,
  handleAddLocation = () => {},
  updateLinkCallback,
  hasCustomCalendar = false,
  updateCalendarClickCallback = () => {},
  perList,
  hasFiles = true,
  fileAttachmentModalProps,
  itemId,
  activityVersion,
  trackFileView = () => {},
}) {
  const dispatch = useDispatch();
  const [date, setDate] = useState(
    dateVal ? parseISODate(dateVal, activityVersion === '2') : null
  );
  const [costAmount, setCostAmount] = useState(
    costVal?.amount?.toString() || ''
  );
  const [costAmountActive, setCostAmountActive] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);
  const [costPer, setCostPer] = useState(costVal?.per || 0);
  const [displayCost, setDisplayCost] = useState(costVal?.amount || null);
  const [costBoxActive, setCostBoxActive] = useState(false);
  const [link, setLink] = useState(linkVal || null);
  const [linkActive, setLinkActive] = useState(false);
  const [perListAnchor, setPerListAnchor] = useState(null);
  const [perListHovered, setPerListHovered] = useState(false);
  const [currencyListAnchor, setCurrencyListAnchor] = useState(null);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [filesHovered, setFilesHovered] = useState(false);
  const [isPaperHovered, setIsPaperHovered] = useState(false);
  const [isPaperActive, setIsPaperActive] = useState(false);
  const userObj = useSelector((state) => state.Auth.userData);
  const is24HourFormat = userObj.user.is24HourTime;

  const classes = useStyles({
    isPaperHovered,
    isPaperActive,
    currencyListAnchor,
  });

  const linkInputRef = useRef(null);
  const costPerRef = useRef(null);
  const costAmountRef = useRef(null);
  const perRef = useRef(null);
  const ref = useRef({
    costAmountZero: false,
  });
  const { slug: tripId } = useParams();
  const defaultCurrency = useSelector(
    (state) => state.Trips.trips[tripId]?.defaultCurrency || 'USD'
  );
  const [costCurrency, setCostCurrency] = useState(
    costVal?.currency || defaultCurrency
  );

  useEffect(() => {
    if (currenciesList.length) return;
    Object.keys(currencies).forEach((currencyCode) => {
      currenciesList.push({
        id: currencyCode,
        name: currencyCode,
        currency: currencies[currencyCode],
        icon: (
          <div
            className={`currency-flag currency-flag-sm currency-flag-${currencyCode?.toLowerCase()}`}
          />
        ),
      });
    });
  }, []);

  useEffect(() => {
    if (linkActive) {
      linkInputRef?.current?.select();
    } else {
      linkInputRef?.current?.blur();
    }
  }, [linkActive]);

  useEffect(() => {
    if (costAmountActive) {
      costAmountRef?.current?.focus();
    } else {
      costAmountRef?.current?.blur();
    }
  }, [costAmountActive]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (costPerRef.current && !costPerRef.current.contains(event.target)) {
        if (perListAnchor === null && currencyListAnchor === null) {
          setCostBoxActive(false);
          if (ref.current.costAmountZero) {
            setDisplayCost(false);
            setCostPer(0);
          }
        }
      }
    }

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [costPerRef, perListAnchor, currencyListAnchor]);

  const updateDate = (newDate) => {
    const isoDate = removeTimezoneOffset(newDate)?.toISOString();
    setDate(newDate);
    updateDateCallback(isoDate);
  };

  const updateCost = (property, val) => {
    const newCostObj = {
      amount: costAmount,
      currency: costCurrency,
      per: costPer,
    };
    newCostObj[property] = val;
    ref.current.costAmountZero = ['', '0'].includes(newCostObj.amount);
    newCostObj.amount = parseFloat(cleanFloatString(newCostObj.amount));
    updateCostCallback(newCostObj);
  };

  const defaultFilterOptions = createFilterOptions();

  const filledList = [];
  const iconsList = [];
  // const allFiles = useSelector((state) => state.Files.files);

  if (hasFiles) {
    const files = fileAttachmentModalProps?.attachedFiles || null;
    iconsList.push(
      <>
        {openFileUploadModal && (
          <FileUploadModal
            open={openFileUploadModal}
            handleClose={() => setOpenFileUploadModal(false)}
            {...fileAttachmentModalProps}
          />
        )}
        <TooltipWrapper title="Files">
          <IconButton
            className={classes.iconContainer}
            size="small"
            onClick={() => {
              trackFileView();
              setOpenFileUploadModal(true);
            }}>
            <PaperClip className={classes.icon} />
          </IconButton>
        </TooltipWrapper>
      </>
    );
    if (files?.length) {
      filledList.push(
        <TooltipWrapper title="Files">
          <Paper
            className={classes.filled}
            sx={{
              backgroundColor: '#E1ECE0',
              padding: '4px 8px',
              '&:hover': {
                background: '#D7E3D6',
              },
              '&:active': {
                background: '#CCD9CB',
              },
            }}
            variant="outlined">
            <PaperClip className={classes.icon} />
            <Typography
              style={{
                fontSize: '12px',
                color: '#4E4E4E',
                paddingLeft: '4px',
                textDecoration: filesHovered ? 'underline' : '',
              }}
              onMouseEnter={() => setFilesHovered(true)}
              onMouseLeave={() => setFilesHovered(false)}
              onClick={() => {
                dispatch(actions.FilesV2.setMultipleFilesPreview(files));
              }}>
              Files x {files.length}
            </Typography>
            <FilePreviewModal
              detachFunc={fileAttachmentModalProps?.attachFunc}
              fileUploadTrigger={setOpenFileUploadModal}
              bladeView
              itemId={itemId}
            />
          </Paper>
        </TooltipWrapper>
      );
    }
  }
  if (hasLocation) {
    iconsList.push(
      <TooltipWrapper title="Location">
        <IconButton
          className={classes.iconContainer}
          size="small"
          onClick={handleAddLocation}>
          <LocationPinIcon className={classes.icon} />
        </IconButton>
      </TooltipWrapper>
    );
  }
  if (hasDate) {
    if (date) {
      const options = {
        month: 'short',
        day: 'numeric',
      };

      if (date.getHours() !== 0 || date.getMinutes() !== 0) {
        options.hour = 'numeric';
        options.minute = 'numeric';
        options.hour12 = !is24HourFormat;
      }
      const formatted = date.toLocaleString('default', options);

      filledList.push(
        <Paper
          onMouseOver={() => setIsPaperHovered(true)}
          onMouseLeave={() => setIsPaperHovered(false)}
          className={classes.filled}
          sx={{
            backgroundColor: isPaperActive ? '#E0AD81' : '#FDC798',
            transition: 'none',
            '&:hover': {
              backgroundColor: isPaperActive ? '#E0AD81' : '#EEB98C',
            },
          }}
          variant="outlined">
          <IconDatePicker
            useRange={false}
            onDateUpdate={(d) => updateDate(d)}
            displayDate={false}
            defaultDate={date}
            toDoId={itemId}
            customDisplay={
              <Typography
                className={classes.font}
                style={{ fontSize: '12px', paddingLeft: '5px' }}>
                {formatted}
              </Typography>
            }
            iconButtonProps={{
              className: classes.dateIconContainer,
            }}
            iconProps={{
              style: {
                fontSize: '12px',
                color: '#222222',
                height: 14,
                width: 14,
              },
            }}
            onMouseDownEvent={() => {
              setIsPaperActive(true);
            }}
            onMouseUpEvent={() => {
              setIsPaperActive(false);
              setIsPaperHovered(false);
            }}
            onCalanderClose={() => {
              setIsPaperHovered(false);
              setIsPaperActive(false);
            }}
          />
        </Paper>
      );
    } else {
      iconsList.push(
        <IconDatePicker
          useRange={false}
          onDateUpdate={(d) => updateDate(d)}
          displayDate={false}
          toDoId={itemId}
          iconButtonProps={{
            size: 'small',
            className: classes.iconContainer,
          }}
          iconProps={{
            fontSize: 'small',
            className: classes.icon,
            sx: {
              height: 14,
              width: 14,
            },
          }}
        />
      );
    }
  }
  if (hasCustomCalendar) {
    iconsList.push(
      <IconDatePickerButton
        displayDate={false}
        iconButtonProps={{
          size: 'small',
          className: classes.iconContainer,
        }}
        iconProps={{
          fontSize: 'small',
          className: classes.icon,
          sx: {
            height: 14,
            width: 14,
          },
        }}
        useRange
        openCalendar={updateCalendarClickCallback}
      />
    );
  }
  if (hasCost) {
    if (displayCost) {
      const costBoxWidth = `${getTextWidth(costAmount || '0.0')}px`;
      filledList.push(
        costBoxActive ? (
          <Paper
            className={classes.filled}
            style={{
              border: '1px solid #DEDDDD',
            }}
            sx={{
              backgroundColor: '#D2DFE2',
              padding: '0px 8px',
            }}
            variant="outlined"
            ref={costPerRef}>
            <Paper
              elevation={0}
              className={classes.filledCurrencyButton}
              onClick={() => setCurrencyListAnchor(costPerRef.current)}>
              <Typography style={{ fontSize: '12px', color: '#8A8A8A' }}>
                {costCurrency}
              </Typography>
            </Paper>
            {currencyListAnchor && (
              <ClickAwayListener
                onClickAway={() => setCurrencyListAnchor(null)}>
                <Popper
                  open={Boolean(currencyListAnchor)}
                  anchorEl={currencyListAnchor}>
                  <Box
                    className={classes.perList}
                    style={{
                      width: costPerRef?.current?.offsetWidth,
                      border: 0,
                    }}>
                    <Autocomplete
                      filterOptions={defaultFilterOptions}
                      open={Boolean(currencyListAnchor)}
                      value={
                        costCurrency
                          ? {
                              id: costCurrency,
                              name: currencies[costCurrency],
                              currency: costCurrency,
                            }
                          : null
                      }
                      options={currenciesList}
                      getOptionLabel={(option) =>
                        `${option.currency} (${option.name})`
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        setCostCurrency(newValue?.id || null);
                        updateCost('currency', newValue?.id || null);
                        setCurrencyListAnchor(null);
                        if (newValue?.id !== costCurrency) {
                          dispatch(
                            updateTripUsingAtc({
                              variables: {
                                id: tripId,
                                defaultCurrency: newValue?.id || null,
                              },
                            })
                          );
                        }
                      }}
                      style={{ width: '100%' }}
                      PaperComponent={({ children }) => (
                        <Paper className={classes.currencyOptionContainer}>
                          {children}
                        </Paper>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          placeholder="Search"
                          style={{
                            backgroundColor: '#F4F4F4',
                            border: '1px solid #DEDDDD',
                            borderBottomWidth: '0px',
                            borderRadius: '4px',
                          }}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontSize: '12px',
                              padding: 0,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <SearchRounded
                                style={{
                                  height: 16,
                                  width: 16,
                                  color: '#8A8A8A',
                                  marginRight: 8,
                                }}
                              />
                            ),
                            style: {
                              paddingRight: 8,
                              borderRadius: 0,
                            },
                            classes: {
                              notchedOutline: classes.noBorder,
                              input: classes.input,
                            },
                          }}
                        />
                      )}
                      forcePopupIcon={false}
                      disablePortal
                      clearIcon={null}
                      renderOption={(props, option) => (
                        <ListItem
                          {...props}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#EAEAEA !important',
                            },
                          }}>
                          {option.icon}
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#8A8A8A',
                              textOverflow: 'ellipsis',
                              marginLeft: 8,
                            }}>
                            {option.name}
                          </Typography>
                        </ListItem>
                      )}
                      classes={{ input: classes.currencyOption }}
                    />
                  </Box>
                </Popper>
              </ClickAwayListener>
            )}
            <InputBase
              value={costAmount}
              placeholder="0.0"
              style={{
                width: costBoxWidth,
              }}
              inputRef={costAmountRef}
              onChange={(e) => setCostAmount(e.target.value)}
              onBlur={(e) => {
                setCostAmountActive(false);
                updateCost('amount', e.target.value);
              }}
              onClick={() => setCostAmountActive(true)}
              inputProps={{
                className: classes.font,
                style: {
                  fontSize: '12px',
                  color: costAmount ? '#222222' : '#8A8A8A',
                  textAlign: costAmountActive ? 'left' : 'center',
                  padding: 0,
                  marginBottom: '-1px', // aligning input with the icon
                },
              }}
            />
            <div ref={perRef}>
              <Typography
                className={[classes.costPer, classes.font]}
                style={{
                  backgroundColor:
                    perListAnchor || perListHovered ? '#C6D4D6' : 'transparent',
                }}
                noWrap
                onClick={() => setPerListAnchor(perRef.current)}
                onMouseEnter={() => setPerListHovered(true)}
                onMouseLeave={() => setPerListHovered(false)}>
                {perList[costPer]}
              </Typography>
            </div>
            {perListAnchor && (
              <ClickAwayListener onClickAway={() => setPerListAnchor(null)}>
                <Popper open={Boolean(perListAnchor)} anchorEl={perListAnchor}>
                  <Box className={classes.perList}>
                    {perList.map((choice, idx) => (
                      <Box
                        className={classes.perOption}
                        onClick={() => {
                          setCostPer(idx);
                          setPerListAnchor(null);
                          updateCost('per', idx);
                        }}>
                        <Typography
                          style={{
                            fontSize: '12px',
                            color: '#8A8A8A',
                          }}>
                          {choice}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Popper>
              </ClickAwayListener>
            )}
          </Paper>
        ) : (
          <TooltipWrapper title="Cost">
            <Paper
              className={classes.filled}
              sx={{
                backgroundColor: '#E3EFF1',
                borderBottom: '1px solid #000000',
                padding: '0px 8px',
                '&:hover': {
                  background: '#D2DFE2',
                },
                '&:active': {
                  background: '#C6D4D6',
                },
              }}
              variant="outlined"
              onClick={() => {
                setCostAmountActive(true);
                setCostBoxActive(true);
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  '&:hover::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '3px',
                    left: 0,
                    right: 0,
                    borderBottom: '1px solid black',
                  },
                }}>
                <Typography
                  style={{
                    fontSize: '12px',
                    color: '#222222',
                    paddingRight: '2px',
                  }}>
                  {costCurrency}
                </Typography>
                <Typography
                  className={classes.font}
                  style={{
                    display: 'flex',
                    flex: 1,
                    overflow: 'clip',
                    fontSize: '12px',
                    textAlign: 'center',
                  }}>
                  {costAmount}
                </Typography>
                <Typography
                  className={classes.font}
                  style={{ fontSize: '12px', padding: '0 0 0 4px' }}>
                  {perList[costPer]}
                </Typography>
              </Box>
            </Paper>
          </TooltipWrapper>
        )
      );
    } else {
      iconsList.push(
        <TooltipWrapper title="Cost">
          <IconButton
            className={classes.iconContainer}
            size="small"
            onClick={() => {
              setDisplayCost(true);
              setCostBoxActive(true);
              setCostAmountActive(true);
            }}>
            <AttachMoney className={classes.icon} fontSize="small" />
          </IconButton>
        </TooltipWrapper>
      );
    }
  }
  if (hasLink) {
    if (link !== null) {
      const linkBoxWidth = `${Math.max(
        getTextWidth(link, '12px Inter') + 2,
        link.length === 0 ? 98 : 18
      )}px`;

      const updatedLink = `${
        link.match(/^https?:\/\//i) ? '' : 'http://'
      }${link}`;

      filledList.push(
        <Paper
          className={classes.filled}
          onMouseOver={() => setLinkHovered(true)}
          onMouseLeave={() => setLinkHovered(false)}
          style={{
            border: linkActive ? '1px solid #DEDDDD' : '',
          }}
          sx={{
            backgroundColor: linkActive ? '#F4F4F4' : '#DEE7F4',
            padding: '4px 8px',
            cursor: 'auto',
            '&:hover': {
              backgroundColor: '#D4DDEA',
            },
          }}
          variant="outlined">
          {linkActive ? (
            <InputBase
              onClick={() => setLinkActive(true)}
              value={link}
              placeholder="www.example.com"
              onChange={(e) => setLink(e.target.value)}
              onBlur={(e) => {
                setLinkActive(false);

                updateLinkCallback(e.currentTarget.value || null);
                if (e.currentTarget.value === '') setLink(null);
                else trackEvents(Events.LinkAdded);
              }}
              inputRef={linkInputRef}
              style={{
                width: linkBoxWidth,
              }}
              inputProps={{
                className: classes.font,
                style: {
                  fontSize: '12px',
                  color: '#8A8A8A',
                  padding: 0,
                },
              }}
            />
          ) : (
            <TooltipWrapper title="Link">
              <div
                style={{
                  overflow: 'clip',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography
                  component="a"
                  href={switchLink(updatedLink) || updatedLink}
                  target="_blank"
                  noWrap
                  onClick={() => trackTpLink(updatedLink)}
                  className={`${classes.font} ${classes.linkText} ${classes.linkContainer}`}>
                  {link.replace(/^(www\.)/, '')}
                </Typography>
                {linkHovered ? (
                  <EditIcon
                    className={classes.linkEditIcon}
                    onClick={() => {
                      setLinkActive(true);
                    }}
                  />
                ) : null}
              </div>
            </TooltipWrapper>
          )}
        </Paper>
      );
    } else {
      iconsList.push(
        <TooltipWrapper title="Link">
          <IconButton
            className={classes.iconContainer}
            size="small"
            onClick={() => {
              setLink('');
              setLinkActive(true);
            }}>
            <Link className={classes.icon} fontSize="small" />
          </IconButton>
        </TooltipWrapper>
      );
    }
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      if (costAmountActive) {
        setCostAmountActive(false);
        updateCost('amount', costAmount);
        setCostBoxActive(false);
        if (ref.current.costAmountZero) {
          setDisplayCost(false);
          setCostPer(0);
        }
      } else if (linkActive) {
        setLinkActive(false);
        updateLinkCallback(link || null);
        if (link === '') setLink(null);
      }
    }
  };

  return (
    <Box className={classes.iconsContainer}>
      <Box
        className={classes.subContainers}
        style={{ flex: 1 }}
        onKeyPress={handleEnterPress}>
        {filledList}
      </Box>
      <Box className={classes.subContainers} style={{ flex: 0 }}>
        {iconsList}
      </Box>
    </Box>
  );
}

export default IconsDrawer;
