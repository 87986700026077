import * as React from 'react';
import Box from '@mui/material/Box';

import {
  DialogTitle,
  styled,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseIcon } from '@iconicicons/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import ImportStep from './ImportStep';
import actions from '../../../../redux/actions';
import { importModelViews } from '../../../../redux/slices/View';
import ImportHistory from './ImportHistory';
import { isTripV2 } from '../../../../utils';
import ImportStepV2 from './ImportStepV2';
import ImportHistoryV2 from './ImportHistoryV2';
import ReviewStep from './ReviewStep';

const Title = styled(DialogTitle)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: 0,
  paddingLeft: '30px',
  '& .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: 700,
  },
}));

// Style the close button
const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
  padding: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

export function ForwardedFiles({ isModalOpen, setView, importsModelView }) {
  const { showUnreadForwardedFilesNotificationDot, importIdToReview } =
    useSelector((state) => state.View);
  const forwardedFiles = useSelector(
    (state) => state?.Auth?.userData?.user?.forwardedFiles || []
  );
  const tripId = useParams().slug;
  const isV2 = isTripV2(tripId);

  useEffect(() => {
    if (
      isModalOpen &&
      showUnreadForwardedFilesNotificationDot &&
      forwardedFiles.length > 0
    ) {
      const hasInProgressImports = forwardedFiles.some(
        (file) =>
          file?.import?.status === 'pending' ||
          file?.import?.status === 'in_progress'
      );
      // dispatch(actions.View.setShowUnreadForwardedFilesNotificationDot(false));
      if (hasInProgressImports) {
        setView(importModelViews.review);
      }
    }
  }, [isModalOpen, forwardedFiles, showUnreadForwardedFilesNotificationDot]);

  useEffect(() => {
    if (importIdToReview && importsModelView !== importModelViews.history) {
      setView(importModelViews.review);
    }
  }, [importIdToReview]);

  if (importsModelView === importModelViews.review) {
    return <ReviewStep />;
  }
  if (importsModelView === importModelViews.history) {
    if (isV2) {
      return <ImportHistoryV2 />;
    }
    return <ImportHistory />;
  }
  if (isV2) {
    return <ImportStepV2 />;
  }
  return <ImportStep />;
}

export default function ForwardedFilesModal({ open, handleClose }) {
  const { importsModelView } = useSelector((state) => state.View);
  const dispatch = useDispatch();
  const setView = (view) => dispatch(actions.View.setImportsModelView(view));
  const [left, setLeft] = useState('68%');
  const [top, setTop] = useState('76%');
  const [width, setWidth] = useState(640);
  const [zIndex, setZIndex] = useState(850);
  const mapContainer = document.getElementById('map-container');

  const setModelWidth = () => {
    const windowWidth = window.innerWidth;
    const planner = document.getElementById('planner');
    if (planner) {
      // setLeft(planner.clientWidth * 1.58 + 8);
      setLeft(planner?.clientWidth);
      setTop(planner?.offsetTop);
    } else {
      setTop(0);
    }
    if (windowWidth > 770) {
      setZIndex(850);
    }
    if (mapContainer && windowWidth < 1200) {
      setWidth('50%');
      // setLeft(planner.clientWidth * 1.57 + 8);
      setLeft(planner?.clientWidth);
    }
    if (windowWidth < 770) {
      // setLeft('50%');
      setLeft('1%');
      setWidth('100%');
      setZIndex(1200);
    }
  };

  useEffect(() => {
    setTimeout(setModelWidth, 500);
    window.addEventListener('resize', setModelWidth);
    return () => window.removeEventListener('resize', setModelWidth);
  }, [open]);

  if (!open) return null;

  return (
    <Box
      id="forwarded-files-modal"
      position="fixed"
      left={left}
      bgcolor="rgba(0, 0, 0, 0.5)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        zIndex,
        top,
        marginTop: {
          xs: 0,
          sm: '24px',
        },
        marginLeft: {
          xs: 0,
          sm: '24px',
        },
        height: '76%',
        width,
        backgroundColor: '#FFF',
        maxWidth: {
          xs: '100%',
          sm: '620px',
        },
        boxShadow: '0px 0px 24px rgba(0,0,0,0.1)',
        borderRadius: '4px',
        overflowY: 'auto',
      }}>
      <Stack height="100%" direction="column" spacing={2} width="100%">
        <Title>
          {importsModelView === importModelViews.history ? (
            <Stack direction="row" alignItems="center" gap={1}>
              <IconButton
                onClick={() => setView(importModelViews.import)}
                sx={{ padding: 0 }}>
                <ChevronLeft />
              </IconButton>
              <Typography fontWeight={700} fontSize="16px">
                Email Imports
              </Typography>
            </Stack>
          ) : (
            <Typography fontWeight={700} fontSize="16px">
              Import Files
            </Typography>
          )}
          <CloseButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </CloseButton>
        </Title>

        <ForwardedFiles
          isModalOpen={open}
          setView={setView}
          importsModelView={importsModelView}
        />
      </Stack>
    </Box>
  );
}
