import { styled } from '@mui/material/styles';
import { Typography, TextField } from '@mui/material';
import CollapsibleSection from './CollapsibleSection';

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FFF',
  '& .MuiInputLabel-root': {
    color: '#8A8A8A',
    fontSize: 14,
  },
  '& .MuiInputLabel-shrink': {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  '& .MuiInputBase-input': {
    padding: '2px 4px',
  },
  '& .MuiInputBase-root': {
    '& fieldset': {
      border: '0.5px solid #E3E3E3 !important',
    },
  },
}));

function GuestRequests({ value, handleChange }) {
  return (
    <CollapsibleSection title="Special Requests">
      <Typography variant="h5" color="text.secondary" mb={2}>
        Please make your request here. The hotel will try their best to
        accommodate.
      </Typography>
      <StyledTextField
        fullWidth
        variant="outlined"
        placeholder="Send a request to the hotel"
        value={value}
        onChange={handleChange}
        multiline
        rows={4}
      />
    </CollapsibleSection>
  );
}

export default GuestRequests;
