import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Modal,
  Typography,
  IconButton,
  InputBase,
  CircularProgress,
  Fade,
  Grid,
  CardMedia,
  Link,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { UploadIcon } from '../../../atoms/Icon';
import { SecondaryOutlinedButton } from '../../../atoms/Button/index';
import { getImagesFromAPI } from '../../../../utils';

const useStyles = makeStyles(({ palette }) => ({
  changeCoverModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    outline: 'none',
    padding: 24,
    maxWidth: '480px',
    width: '100%',
  },
  changeCoverHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 24,
  },
  closeSelectCoverIcon: {
    position: 'absolute',
    right: 0,
    padding: 2,
    top: 0,
  },
  closeIcon: {
    height: 24,
    width: 24,
    color: '#1D1D1D',
  },
  searchImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 399.98px)': {
      flexDirection: 'column-reverse',
      gap: '8px',
    },
    width: '100%',
    padding: '0 4px',
  },
  imageSearchbar: {
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 7,
    border: '1px solid transparent',
    '&:hover': {
      boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    },
  },
  imageSearchbarFocused: {
    boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    border: '1px solid',
    borderColor: palette.primary.main,
  },
  searchCoverIcon: {
    color: '#A7A7A7',
    width: 16,
    height: 16,
    marginRight: 8,
    marginLeft: 4,
  },
  coverImageOption: {
    height: '16vh',
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: '4px',
    position: 'relative',
    margin: 1,
    border: '3px solid white',
    '&:hover': {
      cursor: 'pointer',
      border: '3px solid',
      borderColor: palette.primary.light,
      borderRadius: '4px',
    },
  },
  coverImageOptionEmpty: {
    height: '16vh',
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: '4px',
    position: 'relative',
    margin: 1,
    border: '3px solid white',
    backgroundColor: palette.system.lightgrey,
  },
  imagesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    '@media (max-width: 479.98px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    overflow: 'auto',
    maxHeight: 'calc(32vh + 8px)',
    marginTop: 16,
  },
  imageAuthor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '50%',
    height: '20%',
    backgroundColor: 'grey',
    opacity: '70%',
  },
}));

const USER_REDIRECT = (username) =>
  `https://unsplash.com/@${username}?utm_source=Pilot&utm_medium=referral`;

function ImageSelectorModal({
  handleUpload,
  changeCoverModalOpen,
  setChangeCoverModalOpen,
  handleUpdateCoverImage,
  tempImageSelect = null,
  showInitialImages = false,
  setErrorSnackbarOpened = null,
  trip = null,
  snackbarOpened = false,
}) {
  const uploadRef = useRef(null);

  const destination = useSelector((state) => {
    const locationItem = trip?.items.find((item) =>
      Object.prototype.hasOwnProperty.call(item, 'location')
    );
    if (locationItem) {
      const locationId = locationItem.location;
      return state.Location.locations[locationId];
    }
    return null;
  });

  const [coverImageUploading, setCoverImageUploading] = useState(false);
  const [searchImageKeyword, setSearchImageKeyword] = useState('');
  const [loadingSearchResults, setloadingSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const classes = useStyles();

  const handleCloseCoverModal = () => {
    setSearchImageKeyword('');
    setloadingSearchResults(false);
    setSearchResults([]);
    setChangeCoverModalOpen(false);
  };

  const handleCoverImageUpload = async (e) => {
    if (tempImageSelect) {
      tempImageSelect(e.target.files[0]);
      handleCloseCoverModal();
      return;
    }
    try {
      const image = await handleUpload(e);
      if (image === 'Image Size too Big!!' && setErrorSnackbarOpened) {
        setErrorSnackbarOpened(true);
      } else {
        await handleUpdateCoverImage(image.url);
        handleCloseCoverModal();
      }
    } catch {
      // do nothing
    }
  };

  const handleImageClick = async (image) => {
    if (tempImageSelect) {
      tempImageSelect(image.urls.regular);
      handleCloseCoverModal();
      return;
    }

    await handleUpdateCoverImage(image.urls.regular);
    handleCloseCoverModal();
  };

  const searchImagesHandler = async (searchKeyword) => {
    if (searchKeyword === '') return;
    setSearchResults([]);
    setloadingSearchResults(true);

    const Images = await getImagesFromAPI(searchKeyword);
    setSearchResults(Images);
  };

  useEffect(() => {
    if (changeCoverModalOpen && showInitialImages) {
      searchImagesHandler(trip?.title || destination?.name || 'travel');
    }
  }, [changeCoverModalOpen]);

  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
      }}
      open={changeCoverModalOpen}
      onClose={() => handleCloseCoverModal()}>
      <div className={classes.changeCoverModal}>
        <div className={classes.changeCoverHeader}>
          <Typography variant="h3Sub">Select a cover photo</Typography>
          <IconButton
            className={classes.closeSelectCoverIcon}
            onClick={() => handleCloseCoverModal()}
            size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <div className={classes.searchImageContainer}>
          <input
            ref={uploadRef}
            accept="image/*"
            style={{ display: 'none' }}
            type="file"
            onChange={async (e) => {
              setCoverImageUploading(true);
              await handleCoverImageUpload(e);
              setCoverImageUploading(false);
            }}
          />
          <span style={{ marginRight: 4 }}>
            <SecondaryOutlinedButton
              sx={{
                padding: '8px 16px',
                minWidth: '100%',
              }}
              onClick={() => uploadRef?.current?.click()}
              startIcon={
                coverImageUploading ? (
                  <Fade in={coverImageUploading} unmountOnExit>
                    <CircularProgress size={14} style={{ marginRight: 4 }} />
                  </Fade>
                ) : (
                  <UploadIcon
                    style={{
                      height: 20,
                      width: 20,
                    }}
                  />
                )
              }>
              {coverImageUploading ? 'Uploading' : 'Upload'}
            </SecondaryOutlinedButton>
          </span>
          <InputBase
            fullWidth
            classes={{
              root: classes.imageSearchbar,
              focused: classes.imageSearchbarFocused,
            }}
            placeholder="Search the Unsplash library"
            onChange={(e) => setSearchImageKeyword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') searchImagesHandler(searchImageKeyword);
            }}
            inputProps={{
              style: {
                padding: 0,
              },
            }}
            startAdornment={<SearchIcon className={classes.searchCoverIcon} />}
          />
        </div>
        {snackbarOpened && (
          <span style={{ color: 'red' }}>Image must be under 4MB</span>
        )}
        <ImagesList
          searchResults={searchResults}
          loadingSearchResults={loadingSearchResults}
          handleImageClick={handleImageClick}
        />
      </div>
    </Modal>
  );
}

export function ImagesList({
  searchResults,
  loadingSearchResults,
  handleImageClick,
}) {
  const imagesArr = searchResults.slice();
  const classes = useStyles();

  return (
    <Grid container className={classes.imagesGrid}>
      {imagesArr.length === 0
        ? Array.from(Array(6).keys()).map((key) => (
            <Skeleton
              className={classes.coverImageOptionEmpty}
              variant="rectangular"
              animation={loadingSearchResults ? 'wave' : false}
              key={key}
            />
          ))
        : imagesArr.map((image, idx) => (
            <CardMedia
              className={classes.coverImageOption}
              image={image.urls.regular}
              onClick={() => handleImageClick(image)}
              key={idx}>
              <div className={classes.imageAuthor}>
                <Link
                  noWrap
                  target="_blank"
                  href={USER_REDIRECT(image.user.username)}
                  style={{ color: 'white', paddingLeft: 1 }}>
                  {image.user.name}
                </Link>
              </div>
            </CardMedia>
          ))}
    </Grid>
  );
}

export default ImageSelectorModal;
