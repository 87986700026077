import getCfConnector from '../../../cfConnector';
import config from '../../../config';

export const confirmAvailability = async ({ offerId, checkoutSessionId }) => {
  const response = await (
    await getCfConnector()
  ).post(`${process.env.REACT_APP_NUITEE_BOOKING_API}/hotel/prebook`, {
    offerId,
    checkoutSessionId,
  });
  return response.data;
};

export const updateCheckoutSession = async ({
  checkoutSessionId,
  data,
  checkoutData,
}) => {
  const response = (await getCfConnector()).put(
    `${process.env.REACT_APP_CHECKOUT_API}/${checkoutSessionId}`,
    {
      data,
      checkoutData,
    }
  );
  return response.data;
};

export const createPaymentIntent = async ({
  checkoutSessionId,
  userId,
  userEmail,
  currency,
  amount,
  hotelDetails,
}) => {
  return (
    await (
      await getCfConnector()
    ).post(`${config.paymentsAPI}/create-payment-intent`, {
      amount: Math.round(amount * 100),
      currency,
      userId,
      userEmail,
      metadata: {
        userId,
        checkoutSessionId,
        hotelCity: hotelDetails?.content?.cityName,
        hotelLocation: JSON.stringify({
          latitude: hotelDetails?.content?.lat,
          longitude: hotelDetails?.content?.lng,
        }),
      },
    })
  ).data?.clientSecret;
};

export const confirmBooking = async ({ checkoutSessionId }) => {
  const response = (await getCfConnector()).post(
    `${config.paymentsAPI}/confirm-booking`,
    {
      checkoutSessionId,
    }
  );
  return response.data;
};

export const getCheckoutSession = async ({ checkoutSessionId }) => {
  const response = (await getCfConnector()).get(
    `${config.paymentsAPI}/checkout-session/${checkoutSessionId}`
  );
  return response.data;
};

export const getHotelDetails = async ({ hotelId }) => {
  if (hotelId) {
    const response = await (
      await getCfConnector()
    ).get(`${process.env.REACT_APP_HOTELS_API}/nuitee/hotel/${hotelId}`);
    return response.data;
  }
  return null;
};
