import { IconButton, Typography, Collapse } from '@mui/material';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { useState } from 'react';

function CollapsibleSection({
  children,
  title,
  defaultOpen = false,
  collapseButtonId,
}) {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography variant="h2">{title}</Typography>
        <IconButton
          id={collapseButtonId}
          data-collapsed={!open}
          onClick={() => setOpen(!open)}
          disableRipple
          sx={{ color: '#222' }}>
          {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        </IconButton>
      </div>
      <Collapse in={open}>{children}</Collapse>
    </div>
  );
}

export default CollapsibleSection;
