import React, { useContext, useEffect, useState } from 'react';

import {
  Grid,
  TextField,
  Typography,
  Popper,
  Autocomplete,
  List,
  ListItem,
  Box,
  Skeleton,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { LocationOn } from '@mui/icons-material';

import parse from 'autosuggest-highlight/parse';

import { useQuery } from '@apollo/client';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { firebaseAuth } from '../../../provider/AuthProvider';
import QUERY from '../../../V3/graphql/queries';

import LabelInputField from '../../atoms/Input/LabelInputField';
import LoadingLayout from '../../templates/LoadingLayout';
import { getCategoryIdFromTypes, PIN_ICONS } from '../../../utils';
import { useMapUtils } from '../organisms/Map';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  dropdownPopper: {
    padding: '6px 10px',
  },
  listElem: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
    paddingTop: 'inherit',
    paddingBottom: 'inherit',
    paddingLeft: 8,
    paddingRight: 8,
    borderBottom: '1px solid #DEDDDD',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  categoryIcon: {
    minHeight: 24,
    minWidth: 24,
    backgroundColor: '#ED702E',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
}));

export function SkeletonPlacesSearchBar({ isLast }) {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
        <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
          <Skeleton variant="text" sx={{ width: '30%' }} />
          <Skeleton variant="text" sx={{ width: '40%' }} />
        </Box>
      </Box>
      {!isLast && <Divider sx={{ marginBottom: '4px' }} />}
    </Box>
  );
}

export function PlacesSearchBar(props) {
  const classes = useStyles();
  const inTrips = props.tripLocation;
  const [loading, setLoading] = useState(false);
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(0);
  const searchParams = props.isCitySearch
    ? {
        types: [
          'country',
          'administrative_area_level_1',
          'administrative_area_level_2',
          'administrative_area_level_3',
          'locality',
        ],
      }
    : {};
  const { viewport } = props?.disableLocationBias ? {} : useMapUtils();
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    ready,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      ...searchParams,
      ...(props.disableLocationBias
        ? {}
        : {
            locationBias:
              props?.locationBias?.lat && props?.locationBias?.lng
                ? window.google?.maps &&
                  new window.google.maps.Circle({
                    center: props.locationBias,
                    radius: 30000,
                  })
                : window.google?.maps &&
                  new window.google.maps.Circle({
                    center: {
                      lat: viewport?.latitude,
                      lng: viewport?.longitude,
                    },
                    radius: 30000,
                  }),
          }),
    },
    cacheKey:
      `${props?.locationBias?.lat}-${props?.locationBias?.lng}` || 'default',
    debounce: 800,
  });

  useEffect(() => {
    if (props.autoSearch && ready) {
      setValue(props?.controlledValue);
    }
  }, [props.autoSearch, ready]);

  useEffect(() => {
    if (status !== 'OK' || data.length > 0) {
      setLoading(false);
    }
    if (status === 'OK') {
      setHighlightedOptionIndex(0);
    }
  }, [status, data]);

  function PopperOption(popperProps) {
    return (
      <Popper
        {...popperProps}
        open={props?.defaultPopperComponent ? true : popperProps?.open}
        placement={inTrips || props.popperPlacement ? 'bottom-start' : 'left'}
        id="autocomplete-popper">
        <List {...props?.popperListProps}>
          {props?.defaultPopperComponent && data?.length === 0
            ? props.defaultPopperComponent
            : popperProps.children}
        </List>
      </Popper>
    );
  }

  return (
    <Autocomplete
      openOnFocus={props?.openOnFocus || false}
      id={props?.id}
      forcePopupIcon={false}
      clearIcon={null}
      autoHighlight={props?.enterToSelect}
      freeSolo
      fullWidth
      getOptionLabel={(option) => {
        return typeof option === 'string'
          ? option
          : option?.structured_formatting?.main_text;
      }}
      filterOptions={(x) => x}
      options={loading ? [1, 2, 3, 4, 5] : (status === 'OK' && data) || []} // Dummy options to show loading
      autoComplete
      disableClearable
      includeInputInList
      filterSelectedOptions
      value={
        // controlled value ensures the input component is controlled from the parent.
        props.controlledValue !== undefined
          ? props.controlledValue
          : value === ''
          ? props.value
          : value
      }
      onChange={(e, option) => {
        if (option && typeof option !== 'string') {
          setValue(option.structured_formatting.main_text, false);
          clearSuggestions();
          props.handleSelect(option);
          if (props?.handleClickAway) {
            props?.handleClickAway();
          }
        }
      }}
      onInputChange={(_, newInputValue) => {
        setLoading(newInputValue.length > 0);
        props.onChange(newInputValue);
        setValue(newInputValue);
      }}
      loading={loading}
      renderInput={(params) => {
        const {
          inputProps: paramsInputProps,
          InputProps,
          ...paramsRest
        } = params;
        const { searchBarProps = {} } = props;
        const {
          inputProps: searchBarInputProps = {},
          startIcon = null,
          endIcon = null,
          ...propsRest
        } = searchBarProps;
        return props?.CustomSearchBar ? (
          <props.CustomSearchBar
            handleKeyDown={(e) => {
              if (e.key === 'Enter' && props?.enterToSelect) {
                if (status === 'OK' && data?.length > highlightedOptionIndex) {
                  const option = data[highlightedOptionIndex];
                  if (option && typeof option !== 'string') {
                    setValue(option.structured_formatting.main_text, false);
                    clearSuggestions();
                    props.handleSelect(option);
                  }
                }
              }
            }}
            {...paramsRest}
            {...InputProps}
            {...propsRest}
            startAdornment={startIcon}
            endAdornment={
              endIcon ? (
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '2px',
                  }}>
                  {endIcon}
                </div>
              ) : null
            }
            className={`${InputProps.className} ${
              searchBarProps.className || ''
            }`}
            inputProps={{
              ...paramsInputProps,
              ...searchBarInputProps,
            }}
            inputRef={props.inputRef}
            value={props?.value}
          />
        ) : (
          <LabelInputField
            style={{ width: '100%' }}
            label={props.label}
            params={params}
            isHovered={props.isHovered}
            isActive={props.isActive}
            tripLocation={inTrips}
            value={props.value}
            onChange={params.inputProps.onChange}
            placeholder={props.placeholder}
            inputRef={props.inputRef}
            {...props.searchBarProps}
          />
        );
      }}
      renderOption={(optionProps, option) => {
        if (loading) {
          const isLast = option === 5;
          return <SkeletonPlacesSearchBar isLast={isLast} />;
        }

        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];
        const parts = parse(
          option?.structured_formatting?.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );
        const categoryId = getCategoryIdFromTypes(option?.types) || 'default';
        const CategoryIcon = PIN_ICONS[categoryId]?.icon;
        return (
          <ListItem
            {...optionProps}
            className={classes.listElem}
            onMouseEnter={() =>
              setHighlightedOptionIndex(optionProps['data-option-index'])
            }
            sx={
              props?.enterToSelect
                ? {
                    backgroundColor:
                      highlightedOptionIndex ===
                      optionProps['data-option-index']
                        ? 'primary.extraLight'
                        : '#FFF',
                    color:
                      highlightedOptionIndex ===
                      optionProps['data-option-index']
                        ? 'primary.main'
                        : '#222',
                  }
                : {}
            }>
            <div className={classes.categoryIcon}>
              <CategoryIcon sx={{ height: 14, width: 14, fill: '#FFF' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              <Typography noWrap>
                {parts.map((part, index) => (
                  <span
                    key={index.toString()}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}>
                    {part.text}
                  </span>
                ))}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {option?.structured_formatting?.secondary_text}
              </Typography>
            </div>
          </ListItem>
        );
      }}
      PopperComponent={PopperOption}
      ListboxProps={{ className: classes.dropdownPopper }}
    />
  );
}

export function FriendsSearchBar({ setChat, setActiveChat }) {
  const { user } = useContext(firebaseAuth);
  const { loading, error, data } = useQuery(QUERY.GET_FRIENDS_FOR_USER, {
    variables: { id: user.uid },
  });

  if (loading) {
    return <LoadingLayout fullPage={false} />;
  }
  if (error) {
    return (
      <Typography>
        We could not find a friend. Add a friend to start a new chat!
      </Typography>
    );
  }
  const friendProps = {
    options: data.getUser.friends
      ? data.getUser.friends.map((friend) => friend.user)
      : [],
    getOptionLabel: (option) => {
      return option.firstName;
    },
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Autocomplete
          {...friendProps}
          id="friend-in-list"
          autoComplete
          onChange={(e, option) => {
            if (option) {
              setActiveChat({
                id: [option.id, user.uid].sort().join('_'),
                chatName: option.firstName,
                isTrip: false,
              });
              setChat(true);
            }
          }}
          includeInputInList
          renderInput={(params) => (
            <TextField
              {...params}
              label="To:"
              defaultValue="Type your friends name"
              margin="normal"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
