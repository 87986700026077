import { PaymentElement } from '@stripe/react-stripe-js';
import CollapsibleSection from './CollapsibleSection';

function PaymentForm({ setPaymentDisabled = () => {} }) {
  return (
    <CollapsibleSection title="Pay With" defaultOpen>
      <div style={{ marginTop: 12 }} />
      <PaymentElement
        onChange={(e) => {
          setPaymentDisabled(!e?.complete);
        }}
        options={{
          layout: 'accordion',
          fields: { billingDetails: { address: 'auto' } },
        }}
      />
    </CollapsibleSection>
  );
}

export default PaymentForm;
