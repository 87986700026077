const googlePlacesKey = process.env.REACT_APP_GOOGLE_PLACES_API;
const imagesAPI = process.env.REACT_APP_IMAGES_API;
const flightsAPI = process.env.REACT_APP_FLIGHTS_API;
const emailAPI = process.env.REACT_APP_EMAIL_SERVICE_API;
const recommendationsAPI = process.env.REACT_APP_RECOMMENDATIONS_API;
const paymentsAPI = process.env.REACT_APP_PAYMENTS_API;
const bookingsAPI = process.env.REACT_APP_HOTELS_API;
const exportTripAPI = process.env.REACT_APP_EXPORT_TRIP_API;
const checkoutAPI = process.env.REACT_APP_CHECKOUT_API;
const nuiteAPI = process.env.REACT_APP_NUITEE_BOOKING_API;
const hotelDetailsPage = process.env.HOTEL_DETAILS_PAGE;

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const tomtomApiKey = process.env.REACT_APP_TOMTOM_API_KEY;

const config = {
  googlePlacesKey,
  imagesAPI,
  flightsAPI,
  emailAPI,
  recommendationsAPI,
  mapboxAccessToken,
  tomtomApiKey,
  paymentsAPI,
  bookingsAPI,
  exportTripAPI,
  checkoutAPI,
  nuiteAPI,
  hotelDetailsPage,
};

export default config;
