import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  FacebookLogo,
  GoogleLogoNeutral,
  Visibility,
  VisibilityOff,
} from '../../../atoms/Icon';
import { firebaseAuth } from '../../../../provider/AuthProvider';

function SocialAuthenticationSection() {
  window.localStorage.setItem('redirectURL', window.location.pathname);
  const { handleFacebookSignin, handleGoogleSignin } = useContext(firebaseAuth);
  return (
    <Box>
      <Button
        fullWidth
        onClick={handleGoogleSignin}
        startIcon={<GoogleLogoNeutral />}
        sx={{
          py: 1.5,
          maxHeight: 48,
          borderRadius: 6,
          textTransform: 'none',
          backgroundColor: '#F2F2F2',
          color: '#000',
          border: '1px solid #e0e0e0',
          '&:hover': {
            backgroundColor: '#F2F2F2',
            color: '#000',
            border: '1px solid #d0d0d0',
          },
        }}>
        Continue with Google
      </Button>

      <Button
        fullWidth
        onClick={handleFacebookSignin}
        startIcon={<FacebookLogo />}
        sx={{
          mt: 1.5,
          py: 1.5,
          borderRadius: 6,
          textTransform: 'none',
          backgroundColor: '#1877F2',
          '&:hover': {
            backgroundColor: '#166FE5',
          },
        }}>
        Continue with Facebook
      </Button>
    </Box>
  );
}

function EmailAuthenticationSection() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const errors = useSelector((state) => state.Auth.error);
  const [emailProps, setEmailProps] = useState({
    email: '',
    valid: false,
    exists: false,
    signInMethods: [],
  });

  const navigate = useNavigate();

  const {
    fetchSignInMethodsForEmail,
    handleGoogleSignin,
    handleFacebookSignin,
    handleSignin,
    sendSignInLinkToEmail,
  } = useContext(firebaseAuth);

  const checkEmailExists = async (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(value);

    if (!isValidEmail) {
      setEmailProps({
        ...emailProps,
        email: value,
        valid: false,
      });
      return;
    }
    setEmailProps({
      ...emailProps,
      email: value,
      checkingEmail: true,
      valid: true,
    });

    try {
      // Use Firebase's authentication service to check if email exists
      const providers = await fetchSignInMethodsForEmail(value);

      setEmailProps({
        ...emailProps,
        email: value,
        valid: true,
        exists: (providers && providers.length > 0) || false,
        checkingEmail: false,
        signInMethods: providers,
      });
    } catch (error) {
      // Error occurred while checking email existence
      // Set to false if there's an error (e.g., Firebase API error)
      setEmailProps({
        ...emailProps,
        email: value,
        valid: true,
        exists: false,
        checkingEmail: false,
      });
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    checkEmailExists(value);
  };

  const isPasswordFieldVisible = useMemo(
    () => emailProps?.exists && emailProps?.signInMethods?.includes('password'),
    [emailProps]
  );

  const handleSubmit = () => {
    window.localStorage.setItem('redirectURL', window.location.pathname);
    if (isPasswordFieldVisible) {
      // do signin with password
      handleSignin({
        email,
        password,
      });
    } else if (emailProps?.exists) {
      if (emailProps?.signInMethods?.includes('google.com')) {
        handleGoogleSignin();
      } else if (emailProps?.signInMethods?.includes('facebook.com')) {
        handleFacebookSignin();
      }
    } else {
      sendSignInLinkToEmail(email);
      navigate('/email-verification', {
        state: { email, isPasswordReset: false },
      });
    }
  };

  const handleForgotPassword = () => {
    window.localStorage.setItem('redirectURL', window.location.pathname);
    navigate('/passwordrecovery', {
      state: { email: email || '' },
    });
  };

  return (
    <Box>
      <TextField
        fullWidth
        placeholder="Enter email..."
        value={email}
        onChange={handleEmailChange}
        sx={{ mt: 1.5 }}
        InputProps={{
          sx: {
            borderRadius: 2,
          },
          endAdornment: emailProps.checkingEmail ? (
            <CircularProgress size={20} sx={{ ml: 1 }} />
          ) : emailProps?.exists ? (
            <InputAdornment position="end">
              <Check sx={{ color: '#3AA031' }} />
            </InputAdornment>
          ) : null,
        }}
      />

      {isPasswordFieldVisible && (
        <Box>
          <TextField
            fullWidth
            placeholder="Enter password..."
            value={password}
            name="password"
            type={isPasswordVisible ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mt: 1.5 }}
            InputProps={{
              sx: {
                borderRadius: 2,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                  <IconButton aria-label="toggle password visibility">
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            variant="h5"
            onClick={handleForgotPassword}
            sx={{
              textAlign: 'right',
              cursor: 'pointer',
              color: 'primary.main',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            my={1}>
            Forgot password?
          </Typography>
          {errors && (
            <Typography variant="body2" color="error">
              {errors}
            </Typography>
          )}
        </Box>
      )}

      <Button
        fullWidth
        disabled={
          !emailProps.valid ||
          emailProps.checkingEmail ||
          (isPasswordFieldVisible && !password)
        }
        onClick={handleSubmit}
        sx={{
          py: 1.5,
          fontFamily: 'Futura',
          mt: 1,
          borderRadius: 26,
          textTransform: 'none',
          backgroundColor: '#ED9FBB',
          '&:hover': {
            backgroundColor: '#D87E96',
          },
          '&:disabled': {
            fontFamily: 'Futura',
          },
        }}>
        {isPasswordFieldVisible ? 'Sign in' : "Let's Begin"}
      </Button>
    </Box>
  );
}

function AuthenticationSection() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E3E3E3',
        p: 5,
        borderRadius: 2,
      }}>
      <Typography variant="h3" sx={{ fontWeight: 500, fontSize: 20 }}>
        Log in or sign up to book
      </Typography>
      <Box sx={{ maxWidth: 400, margin: '40px auto 0px auto' }}>
        <SocialAuthenticationSection />

        <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography variant="body2" color="text.secondary" sx={{ mx: 2 }}>
            OR
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>

        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          Continue with email
        </Typography>
        <EmailAuthenticationSection />
      </Box>
    </Box>
  );
}

export default AuthenticationSection;
