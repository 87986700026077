import { useCallback, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useSelector } from 'react-redux';
import {
  getAuth,
  linkWithCredential,
  PhoneAuthProvider,
  RecaptchaVerifier,
} from 'firebase/auth';
import countryCodes from '../../../../assets/CountryCodes.json';

function OTPInput({ otp, setOTP }) {
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleChange = useCallback(
    (index, value) => {
      if (value === '' || /^\d$/.test(value)) {
        const newOTP = [...otp];
        newOTP[index] = value;
        setOTP(newOTP);
        if (value && index < 5) {
          inputRefs[index + 1].current.focus();
        }
      }
    },
    [otp]
  );

  const handleKeyDown = useCallback(
    (index, e) => {
      if (e.key === 'Backspace' && !otp[index] && index > 0) {
        inputRefs[index - 1].current.focus();
      } else if (e.key === 'ArrowLeft' && index > 0) {
        inputRefs[index - 1].current.focus();
      } else if (e.key === 'ArrowRight' && index < 5) {
        inputRefs[index + 1].current.focus();
      }
    },
    [otp]
  );

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(pastedText)) {
      const newOTP = pastedText.split('');
      setOTP(newOTP);
      inputRefs[5].current.focus();
    }
  }, []);

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
      onPaste={handlePaste}>
      {[...Array(6)].map((_, index) => (
        <TextField
          key={index}
          variant="outlined"
          inputProps={{
            maxLength: 1,
            style: { textAlign: 'center', fontSize: '20px' },
          }}
          sx={{
            width: '40px',
            height: '50px',
            mx: 0.5,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
          value={otp[index] || ''}
          inputRef={inputRefs[index]}
          onChange={(e) => {
            handleChange(index, e.target.value);
          }}
          onKeyDown={(e) => {
            handleKeyDown(index, e);
          }}
        />
      ))}
    </Box>
  );
}

function PhoneNumberAuthenticaiton() {
  const auth = getAuth();
  const [selectedCountry, setSelectedCountry] = useState({
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [step, setStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const user = useSelector((state) => state.Auth.firebaseUser);

  const handleClose = () => {
    setStep(0);
  };

  const recaptchaVerifier = new RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'invisible',
      callback: () => {},
    },
    auth
  );

  const handleSendOTP = async () => {
    try {
      let formattedPhoneNumber = phoneNumber.trim();
      formattedPhoneNumber = `${selectedCountry.dial_code}${formattedPhoneNumber}`;

      const provider = new PhoneAuthProvider(auth);
      const verificationIdResponse = await provider.verifyPhoneNumber(
        formattedPhoneNumber,
        recaptchaVerifier
      );
      setVerificationId(verificationIdResponse);
      setStep(1);
    } catch (e) {
      console.error('Error sending OTP:', e);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const otpString = otp.join('');
      const credential = PhoneAuthProvider.credential(
        verificationId,
        otpString
      );

      const userObj = auth.currentUser;
      // Link phone number to existing user
      await linkWithCredential(userObj, credential);
    } catch (error) {
      if (error.code === 'auth/invalid-verification-code') {
        setErrorMessage('Invalid OTP');
      } else if (
        error.code === 'auth/account-exists-with-different-credential'
      ) {
        setErrorMessage(
          'This phone number is already linked to another account'
        );
      } else {
        setErrorMessage('Something went wrong');
      }
    }
  };

  return (
    <div>
      {!user?.phoneNumber ? (
        <Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseOutlinedIcon />
          </IconButton>
          {step === 0 ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '30px',
                  gap: 1,
                }}>
                <FormControl fullWidth>
                  <InputLabel>Country/Region</InputLabel>
                  <Select
                    value={selectedCountry.code}
                    onChange={(e) => {
                      const country = countryCodes.find(
                        (c) => c.code === e.target.value
                      );
                      setSelectedCountry(country);
                    }}
                    fullWidth
                    sx={{
                      backgroundColor: 'transparent',
                      border: '1px solid #ECE7E3',
                      padding: '4px',
                      boxShadow: 'none',
                      borderRadius: '8px 8px 0px 0px',
                      '&.MuiPaper-root': {
                        maxHeight: 400,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}>
                    {countryCodes.map((country) => (
                      <MenuItem
                        key={country.code}
                        value={country.code}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'primary.lightPink',
                          },
                        }}>
                        {country.name} ({country.dial_code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                sx={{
                  borderRadius: '0px 0px 8px 8px',
                  border: '1px solid #ECE7E3',
                  borderTop: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    outline: 'none',
                    border: 'none',
                  },
                }}
                type="tel"
              />
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ marginTop: 2 }}>
                <Button
                  color="secondary"
                  onClick={handleSendOTP}
                  disabled={!phoneNumber}>
                  Continue
                </Button>
              </Box>
            </>
          ) : (
            <Box sx={{ marginTop: '30px' }}>
              <Typography sx={{ mt: 1, fontSize: '14px' }}>
                OTP has been sent to your phone number.
              </Typography>
              <OTPInput otp={otp} setOTP={setOtp} />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ mt: 3 }}>
                <Button color="secondary" onClick={handleVerifyOTP}>
                  Verify OTP
                </Button>
                {errorMessage && (
                  <Typography
                    sx={{ mt: 1, fontSize: '14px', color: 'error.main' }}>
                    {errorMessage}
                  </Typography>
                )}
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: '14px',
                    color: 'primary.main',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => setStep(0)}>
                  Entered the wrong number? Go back
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Typography
          sx={{
            color: 'green',
          }}>
          Phone number verified
        </Typography>
      )}
    </div>
  );
}

export default PhoneNumberAuthenticaiton;
