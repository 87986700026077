const importsLocalStoreKeys = {
  importIdToReview: 'importIdToReview',
  reviewQueue: 'reviewQueue',
  reviewsDone: 'reviewsDone',
  reviewsPending: 'reviewsPending',
  showUnreadForwardedFilesNotificationDot:
    'showUnreadForwardedFilesNotificationDot',
};

export default importsLocalStoreKeys;

export const getItemFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);

  if (item === 'null') {
    return null;
  }
  if (
    key === importsLocalStoreKeys.reviewsDone ||
    key === importsLocalStoreKeys.reviewsPending
  ) {
    return parseInt(item, 10);
  }
  if (key === importsLocalStoreKeys.reviewQueue) {
    return JSON.parse(item || '[]');
  }
  if (key === importsLocalStoreKeys.showUnreadForwardedFilesNotificationDot) {
    return item === 'true';
  }
  return item;
};

export const setItemInLocalStorage = (key, value) => {
  if (key === importsLocalStoreKeys.reviewQueue) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const loadStorageToStore = (dispatch, actions) => {
  try {
    const importIdToReview = getItemFromLocalStorage(
      importsLocalStoreKeys.importIdToReview
    );
    const reviewQueue = getItemFromLocalStorage(
      importsLocalStoreKeys.reviewQueue
    );
    const reviewsDone = getItemFromLocalStorage(
      importsLocalStoreKeys.reviewsDone
    );
    const reviewsPending = getItemFromLocalStorage(
      importsLocalStoreKeys.reviewsPending
    );
    const showUnreadForwardedFilesNotificationDot = getItemFromLocalStorage(
      importsLocalStoreKeys.showUnreadForwardedFilesNotificationDot
    );
    dispatch(actions.View.setImportIdToReviewIfNotSet(importIdToReview));
    dispatch(actions.View.setReviewQueue(reviewQueue));
    dispatch(actions.View.setReviewsDone(reviewsDone));
    dispatch(actions.View.setReviewsPending(reviewsPending));
    dispatch(
      actions.View.setShowUnreadForwardedFilesNotificationDot(
        showUnreadForwardedFilesNotificationDot
      )
    );
  } catch (error) {
    console.error(error);
  }
};

export const resetLocalStorage = () => {
  localStorage.removeItem(importsLocalStoreKeys.importIdToReview);
  localStorage.removeItem(importsLocalStoreKeys.reviewQueue);
  localStorage.removeItem(importsLocalStoreKeys.reviewsDone);
  localStorage.removeItem(importsLocalStoreKeys.reviewsPending);
  localStorage.removeItem(
    importsLocalStoreKeys.showUnreadForwardedFilesNotificationDot
  );
};

export const getStore = () => {
  const importIdToReview = getItemFromLocalStorage(
    importsLocalStoreKeys.importIdToReview
  );
  const reviewQueue = getItemFromLocalStorage(
    importsLocalStoreKeys.reviewQueue
  );
  const reviewsDone = getItemFromLocalStorage(
    importsLocalStoreKeys.reviewsDone
  );
  const reviewsPending = getItemFromLocalStorage(
    importsLocalStoreKeys.reviewsPending
  );
  const showUnreadForwardedFilesNotificationDot = getItemFromLocalStorage(
    importsLocalStoreKeys.showUnreadForwardedFilesNotificationDot
  );
  return {
    importIdToReview,
    reviewQueue,
    reviewsDone,
    reviewsPending,
    showUnreadForwardedFilesNotificationDot,
  };
};
