import { WarningAmberOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import config from '../../../config';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    maxWidth: '720px',
    justifyContent: 'center',
    padding: '40px 95px',
    [breakpoints.down('lg')]: {
      padding: '40px 40px',
    },
    [breakpoints.down('sm')]: {
      padding: '40px 40px',
    },
  },
}));

function BookingFailurePage({ hotelId }) {
  const classes = useStyles();

  const handleRedirect = () => {
    window.location.href = `${config.hotelDetailsPage}/stays/${hotelId}`;
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center" gap={1}>
        <WarningAmberOutlined />
        <Typography sx={{ fontSize: '28px' }}>
          Sorry, something went wrong
        </Typography>
      </Box>
      <Box sx={{ marginLeft: '30px', marginTop: '22px' }}>
        <Typography
          sx={{ fontSize: '16px', marginTop: '22px', fontWeight: 500 }}>
          We weren’t able to complete your booking. Please try again.
        </Typography>
        <Typography
          sx={{ color: '#D31054', marginTop: '28px', cursor: 'pointer' }}
          onClick={handleRedirect}>
          Return To Payments Page
        </Typography>
      </Box>
    </Box>
  );
}

export default BookingFailurePage;
