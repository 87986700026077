import { Grid, Typography, Box } from '@mui/material';
import CollapsibleSection from './CollapsibleSection';
import CancellationPolicy from './CancellationPolicy';

function HouseRules({
  policies,
  isRefundable,
  cancellationPolicies,
  hotelRoomRates,
  totalPrice,
}) {
  return (
    <CollapsibleSection
      defaultOpen
      title="Hotel Rules"
      collapseButtonId="hotel-rules-collapse-button">
      <Box sx={{ mt: 2 }}>
        <CancellationPolicy
          isRefundable={isRefundable}
          cancellationPolicies={cancellationPolicies}
          hotelRoomRates={hotelRoomRates}
          totalPrice={totalPrice}
        />
        <Typography variant="h3" noWrap minWidth={200} mb={1}>
          Fine Print
        </Typography>
        {policies?.map((policy) => {
          return (
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mb: 0.5, fontWeight: 450 }}>
                {policy.name}:
              </Typography>
              <Typography variant="h5" sx={{ mb: 1 }}>
                {policy.description}
              </Typography>
            </Grid>
          );
        })}
      </Box>
    </CollapsibleSection>
  );
}

export default HouseRules;
