import { makeStyles } from '@mui/styles';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classList from '../../../classList';
import {
  removeTimezoneOffset,
  parseISODate,
  getLocalItemId,
  isLocalItem,
  setLastEditedDestination,
} from '../../../../utils';
import { useSounds, SOUNDS } from '../../../../sounds';
import useTour from '../../../molecules/Tour/useTour';
import { stepName } from '../../../../assets/onboarding/steps';
import { useMapUtils } from '../Map';
import BlockHeader from '../BlockHeader';
import {
  createItem,
  deleteItem,
  updateItem,
} from '../../../../redux/slices/Item';
import ITEM_TYPES from '../../../../const';
import actions from '../../../../redux/actions';

const useItineraryHeaderStyles = makeStyles((theme) => ({
  titleInput: {
    ...theme.typography.h2,
    fontSize: '24px',
    paddingRight: '1em',
    width: '100%',
  },
}));

function DestinationItem({
  itemId,
  parentId,
  index,
  dragHandleProps,
  isDragging,
  isParentDragging,
  isSectionHighlight,
  citySuggestion,
  isCollapsed,
  setCollapsed,
}) {
  const inputRef = useRef(null);
  const { slug: tripId } = useParams();
  const classes = useItineraryHeaderStyles();
  const dispatch = useDispatch();
  const tripItems = useSelector((state) => state.items.items);
  const mapPins = useSelector((state) => state.MapV2.mapPins[tripId]);
  const destination = useSelector((state) => state.items.items[itemId]);
  const commandBarState = useSelector((state) => state.View.commandBarV2);
  const currentTrip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);
  const { playSound } = useSounds();
  const { getTour } = useTour();
  const { createMapPinForPlaceId, handleLocationSelect } = useMapUtils();

  const handleAddDestination = async () => {
    const destinationProps = {
      id: getLocalItemId(ITEM_TYPES.DESTINATION),
      type: ITEM_TYPES.DESTINATION,
      title: '',
    };
    dispatch(
      actions.Item.createLocalItem({
        localId: destinationProps.id,
        item: destinationProps,
        parentId,
        index: index + 1,
        tripId,
        updateNewItemId: true,
      })
    );
    if (parentId === tripId) {
      dispatch(
        actions.TripsV2.addChildToTrip({
          tripId,
          childId: destinationProps.id,
          parentId,
          index: index + 1,
        })
      );
    }
  };

  const handleDeleteDestination = async () => {
    const activeLocationId = commandBarState?.activeLocationId;

    let nextLocationId;
    let nextSectionId;

    if (activeLocationId === itemId) {
      // find the next location to set as active from children of the parent
      const currentLocationIndex = currentTrip?.children?.findIndex(
        (locId) => locId === itemId
      );
      nextLocationId =
        currentTrip?.children?.[currentLocationIndex + 1] ||
        currentTrip?.children?.[currentLocationIndex - 1] ||
        null;
      nextSectionId = tripItems[nextLocationId]?.children?.[0];
    }

    await dispatch(
      deleteItem({
        variables: {
          id: itemId,
          tripId,
          parentId,
          mapPin: destination?.mapPin,
        },
        parentId,
        tripId,
      })
    );

    dispatch(
      actions.View.setCommandBarV2({
        activeLocationId: nextLocationId,
        activeSectionId: nextSectionId || null,
      })
    );
  };

  // callback to update the title onBlur
  const updateHeaderTitle = async (title, placeId = null) => {
    if (!title && isLocalItem(itemId, ITEM_TYPES.DESTINATION)) {
      dispatch(
        deleteItem({
          variables: { id: itemId, tripId, parentId },
        })
      );
      return;
    }
    if (!title) return;
    let mapPinId;
    if (destination?.mapPin && placeId) {
      await handleLocationSelect(placeId, destination?.mapPin, 'LOCATION');
      mapPinId = destination?.mapPin;
    } else if (placeId) {
      mapPinId = await createMapPinForPlaceId(placeId, 'LOCATION');
    } else if (!placeId && destination?.mapPin) {
      mapPinId = destination?.mapPin;
    }
    // save last edited destination in local storage
    setLastEditedDestination(tripId, mapPins[mapPinId], title);
    try {
      if (isLocalItem(itemId, ITEM_TYPES.DESTINATION)) {
        const res = await dispatch(
          createItem({
            variables: {
              title,
              type: ITEM_TYPES.DESTINATION,
              parentId,
              tripId,
              index,
              mapPin: mapPinId,
            },
            parentId,
            tripId,
            localId: itemId,
          })
        );
        // update activeLocationId in command bar
        dispatch(
          actions.View.setCommandBarV2({
            activeLocationId: res?.payload?.createItem?.id,
          })
        );
      } else {
        if (title === destination?.title) return;
        if (mapPinId !== destination?.mapPin) playSound(SOUNDS.mapZoom);
        await dispatch(
          updateItem({
            variables: {
              id: itemId,
              title,
              type: ITEM_TYPES.DESTINATION,
              mapPin: mapPinId,
            },
          })
        );
      }
      getTour().onActiveStep(stepName.SET_HEADING).openTour();
    } catch (err) {
      console.error('ERERE', err);
      // handle error here
    }
  };

  // callback to update the location start date and end date
  const updateDestinationDate = async (event) => {
    const newStartDate = event?.from === undefined ? null : event.from;
    const newEndDate = event?.to === undefined ? null : event.to;
    try {
      dispatch(
        updateItem({
          variables: {
            id: itemId,
            content: {
              startDate:
                newStartDate &&
                removeTimezoneOffset(newStartDate)?.toISOString(),
              endDate:
                newEndDate && removeTimezoneOffset(newEndDate)?.toISOString(),
            },
          },
        })
      );
    } catch (err) {
      // handle error here
    }
  };

  return (
    <BlockHeader
      updateDate={updateDestinationDate}
      updateTitle={updateHeaderTitle}
      handleDelete={handleDeleteDestination}
      handleAdd={handleAddDestination}
      inputRef={inputRef}
      dragHandleProps={dragHandleProps}
      defaultTitle={destination?.title}
      inputProps={{
        placeholder:
          citySuggestion && citySuggestion?.current?.suggestion
            ? `Try "${citySuggestion.current.suggestion}"`
            : 'Where do you want to visit?',
        name: 'locationName',
        className: classes.titleInput,
      }}
      classIdentifier={classList.itineraryHeader}
      location={itemId}
      disableCollapse={!destination?.children?.length}
      isCollapsed={isCollapsed}
      setCollapsed={setCollapsed}
      iconDatePickerProps={{
        useRange: true,
        defaultDate: {
          from: destination?.content?.startDate
            ? parseISODate(
                destination?.content?.startDate,
                destination?.content?.version
              )
            : null,
          to: destination?.content?.endDate
            ? parseISODate(
                destination?.content?.endDate,
                destination?.content?.version
              )
            : null,
        },
        placement: 'bottom-end',
      }}
      mapPin={destination?.mapPin}
      isDragging={isDragging}
      isSectionHighlight={isSectionHighlight}
      showDatePicker={!isLocalItem(itemId, ITEM_TYPES.DESTINATION)}
      isParentDragging={isParentDragging}
    />
  );
}

export default DestinationItem;
