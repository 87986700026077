import { useState, useRef, useEffect } from 'react';
import {
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  ErrorOutline,
  ExpandLess,
  ExpandMore,
  HistoryRounded,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Popper,
  Fade,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
} from '@mui/material';
import { CloseIcon, SearchIcon, GlobeIcon } from '@iconicicons/react';
import Recommendations from './Recommendations';
import { BasicButton } from '../../../../../atoms/Button/index';
import { useMapUtils } from '../../../Map';
import CategorySelector from '../../../../../organisms/Recommendations/CategorySelector';
import generateSuggestions from './api';
import recommendationCategories from '../../../../../../assets/newRecommendationCategories.json';
import { ArrowUpCircle, Sparkles } from '../../../../../atoms/Icon';
import { EVENTS, phTrackEvent } from '../../../../../../analytics';
import actions from '../../../../../../redux/actions';
import ITEM_TYPES from '../../../../../../const';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  snackBar: {
    position: 'absolute',
    top: -50,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'auto',
  },
  headingContainer: {
    fontSize: '14px',
    fontWeight: 500,
  },
  searchField: {
    width: '100%',
    backgroundColor: '#f4f4f4',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
  listItemContainer: {
    height: '40px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      color: '#000000',
    },
  },
  listItemText: {
    fontWeight: 300,
    '&:hover': {
      color: '#000000',
    },
  },
  searchIcon: {
    height: '30px',
    width: '30px',
    color: '#aaaaaa',
  },
  expandIcon: {
    height: 24,
    width: 24,
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    '&:active': {
      backgroundColor: '#d9d9d9',
    },
  },
});

function SuggestActivities({
  tripId,
  handleButtonClick,
  activeLocationId,
  locations,
  setAiAnchorEl,
  locationIds,
  // setActiveSection,
  // setValues,
  // setSuggestion,
}) {
  const { focusPlace } = useMapUtils();
  const dispatch = useDispatch();

  const mapPins = useSelector((state) => state.MapV2.mapPins[tripId]);
  const tripItems = useSelector((state) => state.Item.items);
  const activities = useSelector((state) => state.Recommendations.activities);
  const isExpanded = useSelector(
    (state) => state.View.commandBar.isRecommendationsOpen
  );
  const { sessionActive, conversationHistory } = useSelector(
    (state) => state.View.commandBar
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [lastPrompt, setLastPrompt] = useState('');
  const [userPrompts, setUserPrompts] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [filterLabels, setFilterLabels] = useState([]);
  const catButtonRef = useRef(null);
  const [data, setData] = useState([]);
  const [showActivityCard, setShowActivityCard] = useState(false);
  const [existingResults, setExistingResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationAnchorEl, setLocationAnchorEl] = useState(false);
  const [chatContext, setChatContext] = useState([]);
  const [historyAnchorEl, setHistoryAnchorEl] = useState(null);

  const classes = useStyles();

  // Keyboard event listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && anchorEl) {
        setAnchorEl(null);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [anchorEl, handleButtonClick]);

  const setRecommendationMenu = (isRecommendationsOpen) => {
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen,
      })
    );
  };

  const appendPlacesDataToExistingResults = (placesDataArray) => {
    if (placesDataArray.length === 0) return;
    const newPlaces = placesDataArray.map((place) => ({
      pinName: place?.placesData?.pinName,
      placeId: place?.placesData?.place_id,
      coordinates: {
        lat: place?.placesData?.geometry?.location?.lat,
        long: place?.placesData?.geometry?.location?.lng,
      },
    }));

    setExistingResults((prevResults) => [...prevResults, ...newPlaces]);
  };

  const computeCoordinates = (location) => {
    if (location?.mapPin && mapPins[location?.mapPin]) {
      return {
        lat: mapPins[location?.mapPin].lat,
        long: mapPins[location?.mapPin].long,
      };
    }
    return null;
  };

  const updateConversationHistory = (userPrompt, res, lables) => {
    if (sessionActive) {
      // Update the latest session in the conversation history
      const updatedHistory = [...conversationHistory];
      const latestSession = [...updatedHistory[updatedHistory.length - 1]];
      latestSession.push({
        prompt: userPrompt,
        activities: res,
        filterLabels: lables,
      });

      updatedHistory[updatedHistory.length - 1] = latestSession;

      dispatch(
        actions.View.setCommandBar({
          conversationHistory: updatedHistory,
        })
      );
    } else {
      const newSession = [
        {
          prompt: userPrompt,
          activities: res,
          filterLabels,
        },
      ];
      dispatch(
        actions.View.setCommandBar({
          conversationHistory: [...conversationHistory, newSession],
        })
      );
    }
  };

  const handleSuggestActivities = async () => {
    // Don't proceed if prompt is empty or interest aren't selected
    if ((prompt.trim().length === 0 && filterLabels.length === 0) || loading)
      return;
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: true,
      })
    );
    setFilterOpen(false);
    setLoading(true);
    setShowActivityCard(true);
    setConversation((prev) => [...prev, { type: 'prompt', content: prompt }]);
    // set the filter labels if any
    if (filterLabels.length > 0) {
      setConversation((prev) => [
        ...prev,
        { type: 'filterLabels', content: filterLabels },
      ]);
    }
    const coordinates = computeCoordinates(locations[activeLocationId]);
    const userPrompt = prompt;
    setPrompt('');
    const response = await generateSuggestions({
      userPrompt,
      destinationName: locations[activeLocationId]?.title,
      existingResults,
      interests: filterLabels,
      coordinates,
      chatContext,
    });
    const [res, updatedChatContext] = response || [];
    if (res && res.length > 0) {
      // last prompt is empty, this is the first prompt
      if (lastPrompt === '') {
        phTrackEvent({
          event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_LOAD_RESULTS,
        });
      } else {
        phTrackEvent({
          event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_SUBSEQUENT_LOAD_RESULTS,
        });
      }
      dispatch(actions.Recommendations.setActivities([...activities, ...res]));
      focusPlace(
        {
          longitude: res[0]?.long,
          latitude: res[0]?.lat,
          zoom: 8,
        },
        false
      );
      setChatContext(updatedChatContext || []);
    }
    if (!res && lastPrompt === '') {
      phTrackEvent({
        event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_INITIAL_ERROR,
      });
    } else if (!res && lastPrompt !== '') {
      phTrackEvent({
        event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_SUBSEQUENT_RESULTS_ERROR,
      });
    }
    // Add the response activities to the conversation
    setConversation((prev) => [...prev, { type: 'activity', content: res }]);
    setLastPrompt(userPrompt);
    // update the history
    updateConversationHistory(userPrompt, res, filterLabels);
    setData(res || []);
    setUserPrompts((prevPrompts) => [...prevPrompts, prompt]);
    appendPlacesDataToExistingResults(res || []);
    setLoading(false);
    dispatch(
      actions.View.setCommandBar({
        sessionActive: true,
      })
    );
  };

  const handleMouseEnter = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setInfoAnchorEl(null);
  };

  const handleSnackBarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };

  const handleHistoryClick = (sessionItems) => {
    const updatedConversation = [];
    const historyActivities = [];
    sessionItems.forEach((item) => {
      updatedConversation.push({ type: 'prompt', content: item?.prompt });
      if (item?.filterLabels && item?.filterLabels.length > 0) {
        updatedConversation.push({
          type: 'filterLabels',
          content: item?.filterLabels,
        });
      }

      if (item?.activities && item?.activities.length > 0) {
        updatedConversation.push({
          type: 'activity',
          content: item?.activities,
        });
        historyActivities.push(...item.activities);
      }
    });
    setConversation(updatedConversation);
    setShowActivityCard(true);
    setHistoryAnchorEl(null);
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: true,
      })
    );
    dispatch(actions.Recommendations.setActivities(historyActivities));
  };

  const handleHistoryIconClick = (e) => {
    historyAnchorEl
      ? setHistoryAnchorEl(null)
      : setHistoryAnchorEl(e.currentTarget);
  };

  const renderHistory = () =>
    conversationHistory.map((item) => {
      let historyPrompt = item[0]?.prompt;
      if (historyPrompt.trim().length === 0) {
        const filterLablesName = item[0]?.filterLabels.map((filter) => {
          return recommendationCategories.ref[filter].name;
        });
        historyPrompt = filterLablesName.join(', ');
      }
      return (
        <ListItem
          onClick={() => handleHistoryClick(item)}
          className={classes.listItemContainer}>
          <ListItemText
            primary={historyPrompt}
            primaryTypographyProps={{
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </ListItem>
      );
    });
  const handleDestinationClick = (e) => {
    locationAnchorEl
      ? setLocationAnchorEl(null)
      : setLocationAnchorEl(e.currentTarget);
  };

  const handleLocationClick = (locationId) => {
    const activeSectionId = locations[locationId]?.children?.find(
      (childId) => tripItems[childId]?.type === ITEM_TYPES.HEADING || null
    );

    dispatch(
      actions.View.setCommandBarV2({
        activeLocationId: locationId,
        activeSectionId,
      })
    );
    setLocationAnchorEl(null);
  };

  const handleClickAway = () => {
    setLocationAnchorEl(null);
  };

  const renderLocations = () =>
    locationIds &&
    locationIds.map((locationId) => {
      const location = locations[locationId];
      if (!location) return null;
      const { id, title } = location;

      return (
        <ListItem
          key={id}
          onClick={() => handleLocationClick(id)}
          className={classes.listItemContainer}
          alignItems="center">
          <ListItemIcon
            style={{
              minWidth: 0,
              marginRight: 4,
            }}>
            <GlobeIcon className={classes.globeIconContainer} />
          </ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
        </ListItem>
      );
    });

  const open = Boolean(infoAnchorEl);
  const id = open ? 'suggestions-popper' : undefined;

  return (
    <>
      <Paper
        sx={{
          padding: isExpanded ? '16px' : '12px',
          width: '499px',
          borderRadius: '12px',
          boxShadow: 3,
        }}>
        <Box
          className={classes.headerContainer}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: isExpanded ? '16px' : '0px',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Sparkles
              style={{
                height: '35px',
                width: '35px',
                color: !isExpanded ? '#A12BFF' : '#000000',
              }}
            />
            {isExpanded ? (
              <>
                <Typography
                  className={classes.headingContainer}
                  sx={{ marginLeft: 0, fontWeight: 'bold' }}>
                  Suggest activities
                </Typography>
                <ChevronRight
                  sx={{
                    height: 16,
                    width: 16,
                    alignItems: 'center',
                    marginLeft: '4px',
                  }}
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '4px',
                    borderRadius: '4px',
                    '&:hover': { backgroundColor: '#F4F4F4' },
                    '&:active': { backgroundColor: '#D9D9D9' },
                  }}
                  onClick={handleDestinationClick}>
                  <Typography
                    className={classes.headingContainer}
                    sx={{ fontWeight: 'bold' }}>
                    {locations[activeLocationId]?.title || 'New Destination'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <IconButton
                    sx={{
                      marginLeft: '5px',
                      borderRadius: '8px',
                      padding: '2px',
                      transform: 'rotate(180deg)',
                      bgcolor: infoAnchorEl ? '#F4F4F4' : 'transparent',
                      '&:hover': {
                        color: 'none',
                      },
                    }}
                    aria-describedby={id}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <ErrorOutline sx={{ fontSize: '18px', color: '#8A8A8A' }} />
                  </IconButton>
                  <Popper
                    id={id}
                    open={open}
                    anchorEl={infoAnchorEl}
                    placement="top-start"
                    transition
                    sx={{ zIndex: 9300 }}>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper
                          sx={{
                            padding: '8px',
                            maxWidth: '430px',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <ErrorOutline
                            sx={{
                              marginRight: '12px',
                              color: '#8A8A8A',
                              transform: 'rotate(180deg)',
                            }}
                          />
                          <Typography sx={{ fontSize: '12px' }}>
                            With each use of the Suggest feature, the aggregated
                            data is constantly being improved. We acknowledge at
                            this time it is impossible to guarantee real-time
                            accuracy. We recommend verifying details based on
                            the suggestions presented prior to your travels.
                          </Typography>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </Box>
              </>
            ) : (
              <Typography
                sx={{
                  marginLeft: 1,
                  color: '#8A8A8A',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '430px',
                }}>
                {userPrompts.length > 0 && userPrompts[userPrompts.length - 1]}
              </Typography>
            )}
          </Box>

          {!showActivityCard && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                handleButtonClick('ai');
                setAiAnchorEl(null);
                dispatch(
                  actions.View.setCommandBar({
                    isAiMenuOpen: false,
                  })
                );
                // setActiveSection('suggestIdeas');
                // setValues('Suggest Ideas ');
                // setSuggestion('Activities');
              }}>
              {/* <ChevronLeft
                sx={{
                  height: 16,
                  width: 16,
                  alignItems: 'center',
                  color: 'gray',
                }}
              /> */}
              <Typography sx={{ color: 'gray', fontSize: '14px' }}>
                Esc
              </Typography>
            </Box>
          )}

          {showActivityCard &&
            (isExpanded ? (
              <ExpandMore
                className={classes.expandIcon}
                onClick={() => setRecommendationMenu(!isExpanded)}
              />
            ) : (
              <ExpandLess
                className={classes.expandIcon}
                onClick={() => setRecommendationMenu(!isExpanded)}
              />
            ))}
        </Box>

        {showActivityCard ? (
          <>
            <Recommendations
              tripId={tripId}
              activities={data}
              conversation={conversation}
              loading={loading}
              isExpanded={isExpanded}
              lastPrompt={lastPrompt}
              setPrompt={setPrompt}
              setShowActivityCard={setShowActivityCard}
              setToastMessage={setToastMessage}
              setShowSnackBar={setShowSnackBar}
            />
            {isExpanded && (
              <>
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '60px',
                    left: '52%',
                  }}
                  ref={catButtonRef}
                />
                <CategorySelector
                  filterLabels={filterLabels}
                  setFilterLabels={(selected) => {
                    setFilterLabels(selected);
                  }}
                  filterOpen={filterOpen ? catButtonRef.current : null}
                  setFilterOpen={setFilterOpen}
                  isQuickFilter
                  source="subsequent_suggestions"
                />
                <TextField
                  placeholder="refine your search"
                  multiline
                  value={prompt}
                  disabled={loading}
                  sx={{
                    width: '100%',
                    marginTop: '12px',
                    '& .MuiOutlinedInput-root': {
                      padding: '0px 12px',
                      '&.Mui-focused fieldset': {
                        borderColor: '#c3c3c3',
                        borderWidth: '1px',
                      },
                    },
                  }}
                  autoFocus
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      if (
                        (prompt.trim().length === 0 &&
                          filterLabels.length === 0) ||
                        loading
                      )
                        return;
                      e.preventDefault();
                      phTrackEvent({
                        event:
                          EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_SUBSEQUENT_SUBMIT,
                      });
                      handleSuggestActivities();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {showActivityCard ? (
                          <ChevronLeft
                            sx={{
                              height: 24,
                              width: 24,
                              cursor: 'pointer',
                              alignItems: 'center',
                              color: '#000000',
                              borderRadius: '8px',
                              '&:hover': {
                                backgroundColor: '#f4f4f4',
                              },
                              '&:active': {
                                backgroundColor: '#d9d9d9',
                              },
                            }}
                            onClick={() => {
                              setConversation([]);
                              setShowActivityCard(false);
                              dispatch(
                                actions.View.setCommandBar({
                                  sessionActive: false,
                                })
                              );
                              dispatch(
                                actions.Recommendations.setActivities([])
                              );
                            }}
                          />
                        ) : (
                          <SearchIcon sx={{ width: 30, height: 30 }} />
                        )}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          onClick={() => {
                            phTrackEvent({
                              event:
                                EVENTS.PLAN_UNIVERESAL_ADD
                                  .SUGGEST_SUBSEQUENT_INTEREST_CLICK,
                            });
                            setFilterOpen(!filterOpen);
                          }}
                          sx={{
                            color: '#000000',
                            padding: '0 8px',
                            cursor: 'pointer',
                            fontSize: '14px',
                          }}>
                          {`Add Interests ${
                            filterLabels?.length > 0
                              ? `(${filterLabels.length})`
                              : ''
                          }`}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    sx: {
                      padding: '8px',
                    },
                  }}
                />
              </>
            )}
          </>
        ) : (
          <Box sx={{ marginTop: 2, position: 'relative' }}>
            <TextField
              value={prompt}
              placeholder="Try 'hikes nearby' or 'museums & galleries'"
              sx={{
                display: 'block',
                flexGrow: 1,
                outline: 'none',
                backgroundColor: '#f5f5f5',
                '& .MuiOutlinedInput-root': {
                  paddingBottom: '50px',
                  '&:hover fieldset': {
                    borderColor: '#d3d3d3',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#d3d3d3',
                  },
                },
              }}
              multiline
              rows={3}
              maxRows={5}
              fullWidth
              margin="dense"
              autoFocus
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  phTrackEvent({
                    event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_SUBMIT,
                  });
                  handleSuggestActivities();
                }
              }}
              onChange={(e) => setPrompt(e.target.value)}
            />
            <Box
              sx={{
                position: 'fixed',
                bottom: '70px',
                left: '52%',
              }}
              ref={catButtonRef}
            />

            <BasicButton
              marginRight={1}
              onClick={() => {
                phTrackEvent({
                  event:
                    EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_INTEREST_CLICK,
                });
                setFilterOpen(!filterOpen);
              }}
              sx={{
                position: 'absolute',
                cursor: 'pointer',
                bottom: '8px',
                left: '8px',
                backgroundColor: '#FFFFFF',
                color: '#4E4E4E',
                border: '1px solid #D9D9D9',
                maxHeight: '32px',
                margin: '4px',
                padding: '8px 12px',
                '&:hover': {
                  border: '1px solid #D9D9D9',
                  backgroundColor: '#F4F4F4',
                  color: '#4E4E4E',
                },
              }}
              disableRipple>
              Add interests
            </BasicButton>
            {conversationHistory && conversationHistory.length > 0 && (
              <IconButton
                variant="contained"
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  bottom: '8px',
                  left: '130px',
                  maxHeight: '32px',
                  borderRadius: '6px',
                  color: '#4E4E4E',
                  border: '1px solid #D9D9D9',
                  backgroundColor: '#FFFFFF',
                  boxShadow: 'none',
                  margin: '4px',
                  padding: '4px',
                  minWidth: '0px',
                  '&:hover': {
                    border: '1px solid #D9D9D9',
                    backgroundColor: '#F4F4F4',
                    color: '#4E4E4E',
                  },
                }}
                onClick={handleHistoryIconClick}>
                <HistoryRounded sx={{ fontSize: '22px' }} />
              </IconButton>
            )}
            <IconButton
              variant="contained"
              sx={{
                position: 'absolute',
                cursor: 'pointer',
                bottom: '5px',
                right: '4px',
                color:
                  prompt.length > 0 || filterLabels.length > 0
                    ? '#A12BFF'
                    : '#000000',
                boxShadow: 'none',
                margin: '4px',
                padding: '4px',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => {
                phTrackEvent({
                  event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_SUBMIT,
                });
                handleSuggestActivities();
              }}>
              <ArrowUpCircle
                fillColor={
                  prompt.length > 0 || filterLabels.length > 0
                    ? '#A12BFF'
                    : '#BEBEBE'
                }
              />
            </IconButton>

            <Box
              sx={{
                position: 'absolute',
                bottom: '4px',
                left: conversationHistory.length > 0 ? '170px' : '150px',
                display: 'flex',
                flexWrap: 'nowrap',
                gap: '8px',
                overflowX: 'auto',
                width:
                  conversationHistory.length > 0
                    ? 'calc(100% - 220px)'
                    : 'calc(100% - 200px)',
                paddingBottom: '8px',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#b0b0b0',
                  borderRadius: '4px',
                },
              }}>
              {filterLabels.map((label, index) => (
                <Chip
                  key={index}
                  size="medium"
                  label={recommendationCategories.ref[label].name}
                  onDelete={() => {
                    setFilterLabels(
                      filterLabels.filter((item) => item !== label)
                    );
                  }}
                  deleteIcon={<CloseIcon />}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    color: '#4E4E4E',
                    borderRadius: '8px',
                    border: '1px solid #D9D9D9',
                    '&:hover': {
                      backgroundColor: '#F4F4F4',
                      color: '#4E4E4E',
                    },
                  }}
                />
              ))}
            </Box>

            <CategorySelector
              filterLabels={filterLabels}
              setFilterLabels={(selected) => {
                setFilterLabels(selected);
              }}
              filterOpen={filterOpen ? catButtonRef.current : null}
              setFilterOpen={setFilterOpen}
              isQuickFilter
              source="suggestions"
            />
          </Box>
        )}
      </Paper>
      <Popper
        open={Boolean(historyAnchorEl)}
        anchorEl={historyAnchorEl}
        style={{ zIndex: 1123 }}
        placement="top-start"
        disablePortal>
        <ClickAwayListener onClickAway={() => setHistoryAnchorEl(null)}>
          <Paper>
            <List style={{ maxHeight: 210, overflowY: 'auto', width: 280 }}>
              {renderHistory()}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Popper
        open={Boolean(locationAnchorEl)}
        anchorEl={locationAnchorEl}
        style={{ zIndex: 1123 }}
        placement="bottom"
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ]}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper>
            <List style={{ maxHeight: 150, overflowY: 'auto' }}>
              {renderLocations()}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Snackbar
        className={classes.snackBar}
        open={showSnackBar}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          maxWidth: '600px',
          width: '100%',
        }}>
        <Alert
          severity="success"
          variant="filled"
          iconMapping={{
            success: <CheckCircleOutline fontSize="inherit" />,
          }}
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '8px',
            paddingLeft: '8px',
            paddingTop: '1px',
            paddingBottom: '1px',
          }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 1,
            }}>
            {toastMessage}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default SuggestActivities;
