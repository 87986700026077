import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NBookingView from './NBookingView';
import GBookingView from './GBookingView';

function BookingView() {
  const { slug: tripId } = useParams();
  const trip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);
  const selectedBooking = trip?.bookings?.find(
    (item) => item?.id === trip?.activeBookingId
  );
  return selectedBooking?.type === 'N_HOTEL_BOOKING' ? (
    <NBookingView />
  ) : (
    <GBookingView />
  );
}

export default BookingView;
