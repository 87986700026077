import { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ChevronLeftRounded } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import CheckoutNavbar from '../organisms/PublicCheckout/CheckoutNavbar';
import { CHECKOUT_SESSION_STATUS } from '../organisms/PublicCheckout/constants';
import GuestRequests from '../organisms/PublicCheckout/GuestRequests';
import HouseRules from '../organisms/PublicCheckout/HouseRules';
import PaymentForm from '../organisms/PublicCheckout/PaymentForm';
import CartSummary from '../organisms/PublicCheckout/CartSummary';
import actions from '../../../redux/actions';
import CheckoutBottomPriceBar from '../organisms/Checkout/CheckoutBottomPriceBar';
import { getCheckoutDetails } from '../organisms/Stays/checkout/api';
import pilotThemeV2 from '../../../theme/V2';
import AuthenticationSection from '../organisms/PublicCheckout/AuthenticationSection';
import PrimaryGuestsInfo from '../organisms/PublicCheckout/PrimaryGuestsInfo';
import BookingSuccessPage from '../organisms/PublicCheckout/BookingSuccessPage';
import BookingFailurePage from '../organisms/PublicCheckout/BookingFailurePage';
import {
  confirmAvailability,
  createPaymentIntent,
  getHotelDetails,
  updateCheckoutSession,
} from '../organisms/PublicCheckout/api';
import PhoneAuthSection from '../organisms/PublicCheckout/PhoneAuthSection';

const useStyles = makeStyles(({ breakpoints }) => ({
  spacing: {
    padding: '24px 80px',
    [breakpoints.down('lg')]: {
      padding: '18px 8px',
    },
    [breakpoints.down('sm')]: {
      padding: '0px',
      flexDirection: 'column',
    },
  },
  CartSummaryContainer: {
    width: '100%',
    padding: '0px 24px',
    [breakpoints.down('lg')]: {
      padding: '0px 8px',
    },
    [breakpoints.down('sm')]: {
      padding: '0px 8px',
    },
  },
}));

function RightStickyInfoContainer({ checkoutData, hotelDetails }) {
  const classes = useStyles();
  const guests = checkoutData?.guests;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const nextStepAvailable =
    Object.values(guests || {})
      .map((g) =>
        Object.values(g)?.every(
          (v) => v !== '' && v !== null && v !== undefined
        )
      )
      .filter(Boolean)?.length === Object.values(guests || {})?.length;

  return (
    <Grid
      item
      xs={12}
      sm={4.83}
      sx={
        isMobile
          ? { display: 'none' }
          : { position: 'sticky', top: 120, height: 'max-content' }
      }>
      <Box className={classes.CartSummaryContainer}>
        <CartSummary
          disabledBtn={
            !nextStepAvailable || Object.values(guests || {})?.length === 0
          }
          {...checkoutData}
          hotelDetails={hotelDetails}
        />
      </Box>
    </Grid>
  );
}

function GuestInfo({ checkoutData, setCheckoutData = () => {} }) {
  const dispatch = useDispatch();

  const guests = checkoutData?.guests;
  const guestRequest = checkoutData?.guestRequest;
  const user = checkoutData?.userDetails;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeGuestRequest = (e) => {
    setCheckoutData((prev) => ({
      ...prev,
      guestRequest: e.target.value,
    }));
  };

  const rooms = checkoutData?.hotelRooms;

  const nextStepAvailable =
    Object.values(guests || {})
      .map((g) =>
        Object.values(g)?.every(
          (v) => v !== '' && v !== null && v !== undefined
        )
      )
      .filter(Boolean)?.length === Object.values(guests || {})?.length;

  useEffect(() => {
    dispatch(
      actions.Bookings.setCartGuests(
        new Array(rooms?.length)
          .fill(0)
          ?.map((_, index) => ({
            id: index === 0 ? user?.id : `${user?.id}-${index}`,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
          }))
          ?.reduce((acc, curr) => {
            acc[curr?.id] = curr;
            return acc;
          }, {})
      )
    );
  }, []);
  return (
    <>
      <Grid container flexDirection={isMobile ? 'column' : 'row'}>
        <Grid item xs={12} container direction="column">
          <PrimaryGuestsInfo
            rooms={rooms}
            checkoutData={checkoutData}
            setCheckoutData={setCheckoutData}
          />
          <Divider sx={{ my: 3, bgColor: '#E3E3E3' }} />
          <GuestRequests
            value={guestRequest}
            handleChange={handleChangeGuestRequest}
          />
        </Grid>
      </Grid>
      {isMobile && (
        <CheckoutBottomPriceBar
          onNextClick={() => {}}
          isDisabledBtn={
            !nextStepAvailable || Object.values(guests || {})?.length === 0
          }
        />
      )}
    </>
  );
}

function HeadingDescription({ checkoutData }) {
  const user = useSelector((state) => state.Auth.userData?.user);
  const isSameMonth =
    format(new Date(checkoutData.checkInDate), 'MMMM') ===
    format(new Date(checkoutData.checkOutDate), 'MMMM');
  const infoSections = [
    {
      title: 'Dates',
      subtitle: `${format(
        new Date(checkoutData.checkInDate),
        'MMMM do'
      )} - ${format(
        new Date(checkoutData.checkOutDate),
        `${isSameMonth ? 'do' : 'MMM do'}, yyyy`
      )}`,
    },
    {
      title: 'Room',
      subtitle: `${
        checkoutData?.hotelRooms?.[0]?.name || 'Deluxe King Room with City View'
      } x${checkoutData?.hotelRooms?.length || '2'}`,
      noOfTravellers: checkoutData?.rooms?.reduce(
        (acc, curr) => acc + curr.adults + curr.children.length,
        0
      ),
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Typography variant="h4" fontSize={22}>
        {user?.firstName
          ? `Welcome back, ${
              user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)
            }`
          : 'Your trip'}
      </Typography>
      <div style={{ marginTop: 0 }}>
        {/* <div
          style={{
            padding: '12px 18px',
            border: '1px solid #E3E3E3',
            borderRadius: 8,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>
              <Typography variant="h4" fontWeight={600}>
                You're saving with this deal
              </Typography>
              <Typography variant="h5" color="text.secondary" mt={1}>
                Most sites have this room listed for at least $
                {checkoutData?.price || '412'} per night
              </Typography>
            </div>
            <img src="/images/roomAnimation.svg" alt="Bed" />
          </div>
        </div> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 24,
          }}>
          {infoSections?.map(({ title, subtitle, noOfTravellers }, index) => (
            <div style={{ marginTop: 24 }} key={`info-section-${index}`}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div>
                  <Typography variant="h4" fontWeight={600}>
                    {title}
                  </Typography>
                  <Typography variant="h5" mt={1}>
                    {subtitle}
                  </Typography>
                  {title === 'Room' && (
                    <Typography variant="h5" mt={1} sx={{ color: '#453C37' }}>
                      {noOfTravellers} travellers
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function PaymentInfo({
  hotelDetails,
  totalPrice,
  guestDetails,
  guestRequest,
  hotelRoomRates,
  ...props
}) {
  const { slug: checkoutSessionId } = useParams();
  const user = useSelector((state) => state.Auth.userData?.user);
  const firebaseUser = useSelector((state) => state.Auth.firebaseUser);
  const userId = useSelector((state) => state.Auth.userId);
  const elements = useElements();
  const stripe = useStripe();

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentDisabled, setPaymentDisabled] = useState(true);

  const isGuestDetailsValid =
    guestDetails?.length === hotelRoomRates?.length &&
    guestDetails?.every((guest) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return (
        guest?.firstName &&
        guest?.lastName &&
        guest?.email &&
        emailRegex.test(guest?.email)
      );
    });

  async function handlePayment() {
    // check if phone number is verified
    if (!firebaseUser?.phoneNumber) {
      return;
    }
    setPaymentLoading(true);
    elements.submit();
    const availabilityResponse = await confirmAvailability({
      offerId: props?.offerId,
      checkoutSessionId,
    });

    await updateCheckoutSession({
      checkoutSessionId,
      data: {
        email: user?.email,
      },
      checkoutData: {
        primaryBooker: {
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phoneNumber: user?.phone || firebaseUser?.phoneNumber,
          firebaseUid: firebaseUser?.uid,
          id: userId,
        },
        guestDetails,
        specialRequests: guestRequest,
        prebookInfo: {
          prebookId: availabilityResponse?.result?.prebookId,
          availabilityUpdated: Date.now(),
        },
      },
    });

    const clientSecret = await createPaymentIntent({
      checkoutSessionId,
      userId,
      userEmail: user?.email,
      currency: totalPrice?.currency.toLowerCase(),
      amount: totalPrice.amount,
      hotelDetails,
    });

    if (checkoutSessionId) {
      const { error = false } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/checkout/${checkoutSessionId}/loading`,
        },
      });
      if (error) {
        console.error(error);
      }
    }

    setPaymentLoading(false);
  }

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container>
        <Grid item xs={12} container>
          <div style={{ width: '100%' }}>
            <div style={{ marginTop: 24 }} />
            <PaymentForm setPaymentDisabled={setPaymentDisabled} />
            <div style={{ marginTop: 24 }} />
            <PhoneAuthSection />
            <div style={{ marginTop: 24 }} />
            <HouseRules
              policies={props?.policies}
              isRefundable={props?.isRefundable}
              cancellationPolicies={props?.cancellationPolicies}
              hotelRoomRates={hotelRoomRates}
              totalPrice={totalPrice}
            />
          </div>
        </Grid>
        <Button
          sx={{
            marginTop: '24px',
            '&: disabled': {
              fontFamily: 'Futura',
            },
          }}
          fullWidth
          disabled={
            paymentDisabled ||
            !isGuestDetailsValid ||
            !firebaseUser?.phoneNumber
          }
          onClick={handlePayment}>
          {paymentLoading ? 'Confirming...' : 'Confirm Booking'}
        </Button>
      </Grid>
      {/* {isMobile && (
          <CheckoutBottomPriceBar
            isStripeStep
            isPaymentInfoPending={paymentDisabled}
          />
        )} */}
    </>
  );
}

function PublicCheckout() {
  const classes = useStyles();
  const firebaseUser = useSelector((state) => state.Auth.firebaseUser);

  const { slug: checkoutId } = useParams();
  const [checkoutData, setCheckoutData] = useState({});
  const [apiResponse, setApiResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const [hotelDetails, setHotelDetails] = useState(null);

  const hotelId = checkoutData?.hotelId;
  const theme = useTheme();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(async () => {
    const response = await getCheckoutDetails(checkoutId);
    setApiResponse(response?.data);
    setCheckoutData(response?.data?.checkoutData);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (firebaseUser && !hotelDetails) {
      getHotelDetails({ hotelId }).then((res) => {
        setHotelDetails(res?.result);
      });
    }
  }, [firebaseUser, hotelId]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    !loading && (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          overflow: 'auto',
        }}>
        <CheckoutNavbar />
        {apiResponse?.status === CHECKOUT_SESSION_STATUS.PAYMENT_PENDING ||
        apiResponse?.status === CHECKOUT_SESSION_STATUS.PAYMENT_FAILED ? (
          <>
            <div style={{ marginTop: 48 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Grid
                container
                className={classes.spacing}
                flexDirection="column"
                sx={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    maxWidth: 1250,
                    width: '100%',
                  }}>
                  <IconButton
                    onClick={handleBackButtonClick}
                    sx={{ color: '#222' }}>
                    <ChevronLeftRounded />
                  </IconButton>

                  <Typography variant="h2" fontSize={28} ml={1}>
                    Confirm your reservation
                  </Typography>
                </div>
                <Grid container py={3}>
                  <Grid
                    item
                    xs={12}
                    sm={7.16}
                    sx={isMobile ? { px: 2.5 } : { pl: 6, pr: 15 }}>
                    <HeadingDescription checkoutData={checkoutData} />
                    {firebaseUser ? (
                      <>
                        <Divider sx={{ my: 3 }} />
                        <GuestInfo
                          checkoutId={checkoutId}
                          checkoutData={checkoutData}
                          setCheckoutData={setCheckoutData}
                        />
                        {checkoutData && (
                          <Elements
                            stripe={stripePromise}
                            options={{
                              mode: 'payment',
                              amount: Math.round(
                                (checkoutData?.totalPrice?.amount || 0) * 100
                              ),
                              currency:
                                checkoutData?.totalPrice?.currency?.toLowerCase(),
                              appearance: {
                                variables: { colorPrimary: '#D31054' },
                              },
                            }}>
                            <PaymentInfo
                              {...checkoutData}
                              hotelDetails={hotelDetails}
                            />
                          </Elements>
                        )}
                      </>
                    ) : (
                      <AuthenticationSection />
                    )}
                  </Grid>
                  <RightStickyInfoContainer
                    checkoutData={checkoutData}
                    checkoutId={checkoutId}
                    hotelDetails={hotelDetails}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : apiResponse?.status ===
          CHECKOUT_SESSION_STATUS.BOOKING_CONFIRMED ? (
          <BookingSuccessPage
            {...checkoutData}
            hotelDetails={hotelDetails}
            tripId={apiResponse?.tripId}
          />
        ) : (
          <BookingFailurePage hotelId={hotelDetails?.content?.id} />
        )}
      </div>
    )
  );
}

function ThemedPublicCheckout() {
  return (
    <ThemeProvider theme={pilotThemeV2}>
      <PublicCheckout />
    </ThemeProvider>
  );
}

export default ThemedPublicCheckout;
