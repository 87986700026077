import { Navigate, Route, useParams } from 'react-router-dom';
import SentryRoutes from './SentryRoutes';
import Trips from '../components/pages/Trips';
import Itinerary from '../components/pages/Itinerary';
import Friends from '../components/pages/Friends';
import Activity from '../components/pages/Activity';
import Settings from '../components/pages/Settings';
import Wishlist from '../components/pages/Wishlist';
import Discover from '../components/pages/Discover';
import ProfilePage from '../components/pages/ProfilePage';
import Destination from '../components/pages/Destination';
import DestinationArticle from '../components/pages/DestinationArticle';
import HotelDetails from '../components/pages/HotelDetails';
import HotelDetailsV2 from '../components/V2/pages/HotelDetails';
import CreateATripFlow from '../components/pages/CreateATripFlow';
import CreateATripFlowV2 from '../components/V2/pages/createATripFlowV2';
import JoinTrip from '../components/pages/JoinTrip';
import Checkout from '../components/pages/Checkout';
import CancelBooking from '../components/pages/CancelBooking';
import CancelBookingV2 from '../components/V2/pages/CancelBookingV2';
import { isTripV2 } from '../utils';
import ItineraryV2 from '../components/V2/pages/ItineraryV2';
import CheckoutV2 from '../components/V2/pages/CheckoutV2';
import JoinTripV2 from '../components/V2/pages/JoinTrip';
import PublicCheckout from '../components/V2/pages/PublicCheckout';
import PublicCheckoutLoading from '../components/V2/pages/PublicCheckoutLoading';

function DynamicItinerary() {
  const { slug } = useParams();
  if (isTripV2(slug)) {
    return <ItineraryV2 />;
  }
  return <Itinerary />;
}

function DynamicHotelDetails() {
  const prevTripId = window.localStorage.getItem('prevTripId');
  if (isTripV2(prevTripId)) {
    return <HotelDetailsV2 />;
  }
  return <HotelDetails />;
}

function DynamicCheckout() {
  const { slug } = useParams();
  if (isTripV2(slug)) {
    return <CheckoutV2 />;
  }
  return <Checkout />;
}

function DynamicCreateATripFlow() {
  const { slug } = useParams();
  if (isTripV2(slug)) {
    return <CreateATripFlowV2 />;
  }
  return <CreateATripFlow />;
}

function DynamicJoinTrip() {
  const { slug } = useParams();
  if (isTripV2(slug)) {
    return <JoinTripV2 />;
  }
  return <JoinTrip />;
}

function DynamicCancelBooking() {
  const { slug } = useParams();
  if (isTripV2(slug)) {
    return <CancelBookingV2 />;
  }
  return <CancelBooking />;
}

function AppRoutes() {
  const shouldShowBookings = true;

  return (
    <SentryRoutes>
      <Route path="/trips" element={<Trips />} />
      {shouldShowBookings && (
        <Route
          path="/trips/:slug/booking/:bookingId/cancel"
          element={<DynamicCancelBooking />}
        />
      )}
      {/* <Route path="/trips/:slug/:tab" element={<Itinerary />} /> */}
      <Route path="/trips/:slug/:tab" element={<DynamicItinerary />} />
      <Route path="/join/:slug" element={<DynamicJoinTrip />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/discover" element={<Discover />} />
      <Route path="/discover/:slug" element={<Destination />} />
      <Route
        path="/discover/:slug/article/:id"
        element={<DestinationArticle />}
      />
      {/* <Route path="/offers" element={<Offers />} /> */}
      <Route path="/friends" element={<Friends />} />
      <Route path="/activities" element={<Activity />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/profile/:id" element={<ProfilePage />} />
      <Route path="/hotel/:slug" element={<DynamicHotelDetails />} />
      <Route path="/create-a-trip/:slug" element={<DynamicCreateATripFlow />} />
      <Route path="/checkout/:slug" element={<PublicCheckout />} />
      <Route
        path="/checkout/:slug/loading"
        element={<PublicCheckoutLoading />}
      />
      {/* {shouldShowBookings && (
        <Route path="/hotel/:slug/checkout/:sessionId" element={<Checkout />} />
      )} */}
      {shouldShowBookings && (
        <Route
          path="/hotel/:slug/checkout/:sessionId"
          element={<DynamicCheckout />}
        />
      )}

      <Route path="*" element={<Navigate replace to="/trips" />} />
    </SentryRoutes>
  );
}

export default AppRoutes;
