import { useState, useRef, useEffect, useMemo } from 'react';
import {
  Grid,
  Typography,
  InputBase,
  useTheme,
  useMediaQuery,
  Tooltip,
  Fade,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Favorite,
  OutlinedFlagRounded,
  DragIndicatorRounded,
  DeleteOutlineRounded,
  DescriptionOutlined,
  AddRounded,
  AttachMoney,
  Link,
} from '@mui/icons-material';
// import { Draggable } from 'react-beautiful-dnd';
import { gql } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import InlineBlade from '../../molecules/InlineBlade';
import client from '../../../../graphql/index';
import { useMapUtils } from '../Map';
import actions from '../../../../redux/actions';
import { PlacesSearchBar } from '../../molecules/SearchBar';
import classList from '../../../classList';
import { LocationPinIcon, PaperClip } from '../../../atoms/Icon';
import IconDatePicker from '../../../molecules/IconDatePicker';
import AvatarStack from '../../../molecules/AvatarStack';
import CustomLottiePlayer from '../../../molecules/CustomLottiePlayer';

import { getLocalItemId, isLocalItem, parseISODate } from '../../../../utils';
import { useSounds, SOUNDS } from '../../../../sounds';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import useTour from '../../../molecules/Tour/useTour';
import { stepName } from '../../../../assets/onboarding/steps';
import TooltipWrapper from './TooltipWrapper';
import {
  createItem,
  deleteItem,
  updateItem,
} from '../../../../redux/slices/Item';
import ITEM_TYPES, { COST_TYPES } from '../../../../const';

const useTodoItemStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: 4,
    display: 'flex',
    padding: '3px 0.5%',
    margin: '0px 0% 0% 1%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  extraTooltipNumber: {
    fontSize: '12px',
    color: '#8A8A8A',
  },
  isDragging: {
    border: `2px solid ${palette.primary.light}`,
    boxShadow: '2px 2px 15px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    marginLeft: 'calc(3.6rem - 15px)',
  },
  favorite: {
    fontSize: 16,
    color: '#E44F3C',
    fontWeight: 'bold',
    stroke: '#E44F3C',
    strokeWidth: '1.5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  favoriteInactive: {
    fontSize: 16,
    color: 'transparent',
    fontWeight: 'bold',
    stroke: '#A7A7A7',
    strokeWidth: '1.5px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  iconEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  likesCount: {
    marginRight: 4,
    marginLeft: 4,
    minWidth: '9px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
  activityIcon: ({ isHovered, isFocused, shouldHighlight }) => ({
    color: isFocused
      ? palette.primary.main
      : isHovered || shouldHighlight
      ? palette.primary.light
      : '#222',
  }),
  inputContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: '8px',
  },
  actionIcon: {
    marginTop: 0,
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
      color: '#474747',
    },
  },
  actionIconDrag: {
    marginTop: 0,
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  actionIconDragSpan: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionContainer: {
    position: 'absolute',
    margin: 'auto',
    top: 12,
    left: 'calc(-3.6rem - 15px)',
    minWidth: 'calc(3.6rem + 15px)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hovered: {
    border: `1px solid ${palette.primary.light}`,
  },
  focused: {
    border: `1px solid ${palette.primary.main}`,
  },
  spacingRight: {
    paddingRight: '0rem',
  },
  todoItem: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 15px)',
    // marginRight: 12,
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px)',
      marginRight: 12,
    },
  },
  newTodoItem: {
    marginLeft: 'calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px + 8px)',
    },
  },
  activityBladeIconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  activityBladeIcon: {
    fontSize: '16px',
    color: '#8A8A8A',
  },
  filled: {
    display: 'flex',
    maxWidth: '140px',
    borderStyle: 'hidden',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderRadius: '4px',
    height: '80%',
    margin: '0 4px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  greyText: {
    color: '#8A8A8A',
    fontSize: 14,
    marginLeft: '0.25rem',
    alignSelf: 'center',
  },
}));

function Activity({
  itemId,
  index = 0,
  isDragging,
  dragHandleProps,
  isSectionHighlight,
  tripId,
  locationBias,
  parentId,
  isParentDragging,
}) {
  const activity = useSelector((state) => state.items.items[itemId]);
  const isNewActivity = useSelector((state) => state.Item.newItemId === itemId);
  const activeMapPin = useSelector((state) => state.View.activeMapPin);
  const highlightedTodo = useSelector((state) => state.View.highlightedToDo);
  const items = useSelector((state) => state.Item.items);
  const destinationId = Object.keys(items).find(
    // check if parentId exists in children array
    (key) => items[key]?.children?.includes(parentId)
  );

  const {
    inProgress: tourInProgress,
    tourName,
    currentStep: tourCurrentStep,
    currentStepName: tourCurrentStepName,
  } = useSelector((state) => state.View.tour);

  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[tripId]
  );
  const userId = useSelector((state) => state.Auth.userId);
  const { playSound } = useSounds();

  const [isFocused, setFocused] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [openInlineBlade, setOpenInlineBlade] = useState(false);

  // state to handle the activity title changes
  const [title, setTitle] = useState(activity?.title);
  const [notes, setNotes] = useState(activity?.content?.description);
  const [cost, setCost] = useState(activity?.content?.expense?.amount);
  const [currency, setCurrency] = useState(
    activity?.content?.expense?.currency
  );
  const [costPer, setCostPer] = useState(
    activity?.content?.expense?.costType === COST_TYPES[0] ? 0 : 1
  );
  const [date, setDate] = useState(parseISODate(activity?.content?.startDate));
  const [link, setLink] = useState(activity?.content?.links?.[0] || null);
  const [address, setAddress] = useState(
    activity?.content?.address?.streetAddress ||
      activity?.content?.address?.city ||
      activity?.content?.address?.state
  );
  const timeRef = useRef(null);
  const { getTour, tourOpen } = useTour();

  useEffect(() => {
    if (!activity?.content?.address) {
      setAddress(null);
    } else if (activity?.content?.address?.streetAddress) {
      setAddress(activity?.content?.address?.streetAddress);
    }
  }, [activity]);

  // fetching files to display in preview icons
  const files = useSelector((state) => {
    const forwardedFiles = state?.Auth?.userData?.user?.forwardedFiles;
    const fFmapIdToFile = forwardedFiles?.reduce((acc, file) => {
      acc[file?.id] = file;
      return acc;
    }, {});
    if (activity?.files && activity?.files.length !== 0 && state.Files?.files) {
      return activity?.files.map(
        (file) =>
          state.FilesV2.files[file?.id || file] ||
          fFmapIdToFile[file?.id || file]
      );
    }
    return [];
  });

  /* LIKE ops handling */
  const [likeAnimation, setLikeAnimation] = useState(false);
  const [isLiked, setLike] = useState(
    activity?.likes?.some((likeObj) => likeObj === userId)
  );
  const [likes, setCount] = useState(activity?.likes || []);

  const likedUsers = useSelector((state) => {
    const trip = state.TripsV2.tripsAtc[tripId];
    return [...(trip?.users || [])]?.filter((tripUser) =>
      likes?.includes(tripUser?.id)
    );
  });

  const likedUsersCopy = useMemo(() => {
    const likedUserNames = likedUsers
      .map((tripUser) => tripUser?.firstName || null)
      ?.filter(Boolean);
    let copy = likedUserNames?.join(', ');
    if (likedUserNames?.length > 1) {
      const lastCommaIndex = copy.lastIndexOf(', ');
      copy = `${copy.slice(0, lastCommaIndex)} &${copy.slice(
        lastCommaIndex + 1
      )}`;
    }
    return copy;
  }, [likedUsers]);
  /* en LIKE ops handling */

  // states to handle conditional styles
  const shouldHighlight = useMemo(() => {
    return activeMapPin && activity?.mapPin === activeMapPin;
  }, [activeMapPin, activity?.mapPin]);
  const classes = useTodoItemStyles({
    isFocused,
    isHovered,
    shouldHighlight,
  });

  // refs to keep track of elements that trigger events
  const inputRef = useRef();
  const containerRef = useRef();
  const delayRef = useRef();

  // for checking single and double clicks on mobile
  const [mobileSingleClick, setMobileSingleClick] = useState(false);
  let wait = false;
  let timer;
  let waitForStep = true;

  // state to coordinate if the blur event is due to the enter keypress.
  const [enterTrigger, setEnterTrigger] = useState(false);

  const dispatch = useDispatch();
  // focus on map on select
  const { focusPin, place, setPlace, handleLocationSelect } = useMapUtils();

  useEffect(() => {
    if (highlightedTodo) {
      const highlightTimer = setTimeout(() => {
        dispatch(actions.View.setHighlightedToDo({ todo: null }));
      }, 4000);
      return () => clearTimeout(highlightTimer);
    }
    return () => {};
  }, [highlightedTodo]);

  const isIOS = useSelector((state) => state.SysInfo.isIOS);

  const handleDelete = async () => {
    try {
      if (isLocalItem(itemId, ITEM_TYPES.ACTIVITY)) {
        dispatch(deleteItem({ variables: { id: itemId, parentId, tripId } }));
        return;
      }

      const filesToBeAdded = [];
      if (activity?.files?.length > 0) {
        const tempArr = fileRelations?.filter((relation) =>
          activity.files.includes(relation.fileId)
        );
        tempArr?.forEach((relation) => {
          if (
            tempArr.filter((rel) => rel.fileId === relation.fileId).length === 1
          ) {
            filesToBeAdded.push(relation.fileId);
          }
        });
      }
      dispatch(
        deleteItem({
          variables: {
            id: itemId,
            parentId,
            tripId,
            mapPin: activity?.mapPin,
            // ...(filesToBeAdded?.length > 0 && {
            //   filesToBeAdded,
            // }),
          },
          // mapPin: activity?.mapPin,
          // sectionId: parentId,
          // files: activity?.files,
        })
      );

      getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).closeTour();
      // updated the last updated section under destination
      // updateSessionStorageForLastEditedSection(locationId, parentId);
    } catch (err) {
      // handle error here
    }
  };

  const getNewActivityTemplate = () => ({
    id: getLocalItemId(ITEM_TYPES.ACTIVITY),
    title: '',
    type: ITEM_TYPES.ACTIVITY,
  });

  // function to dynamically update activity title
  const updateActivityTitle = (source, updatedActivity = {}) => {
    const { mapPin, title: newTitle, ...activityProps } = updatedActivity;
    if (title || newTitle) {
      if (isLocalItem(itemId, ITEM_TYPES.ACTIVITY)) {
        const newActivity = getNewActivityTemplate();
        if (window?.heap) window?.heap.track('Activity Created');
        dispatch(
          createItem({
            variables: {
              title: newTitle || title,
              type: ITEM_TYPES.ACTIVITY,
              content: {
                ...activityProps,
              },
              children: [],
              parentId,
              index,
              mapPin: mapPin?.id,
              tripId,
            },
            tripId,
            parentId,
            index,
            localId: itemId,
            shouldAppendItem: source === 'Enter',
            localItem: newActivity,
          })
        );

        getTour()
          .onActiveStep(stepName.ACTIVITY_ADDED_TO_TRIP_MOBILE)
          .openTour();

        // updated the last updated section under destination
        // updateSessionStorageForLastEditedSection(locationId, parentId);
        phTrackEvent({
          event: EVENTS.PLAN_ACTIVITY.ADD_START,
        });
        dispatch(
          actions.Activity.setNewActivity({
            activityId: newActivity?.id,
          })
        );
      } else {
        try {
          const { title: cachedTitle } = client.readFragment({
            id: `Todo:${itemId}`,
            fragment: gql`
              fragment todo on Todo {
                title
              }
            `,
          });
          if (cachedTitle === title) return;

          dispatch(
            updateItem({
              variables: {
                id: itemId,
                title,
                likes,
                ...activityProps,
              },
            })
          );
        } catch (err) {
          // do nothing
        }
      }
    } else if (isLocalItem(itemId, ITEM_TYPES.ACTIVITY)) {
      /*
        Checking if the activity is a local-todo, if so, delete it.
        Otherwise, revert the title back to what it was.
      */
      handleDelete();
    } else {
      setTitle(activity?.title);
    }
    if (tourInProgress && tourName === 'sampleTripFlowMobile') {
      if (tourCurrentStep === 2 && !tourOpen) {
        setTimeout(() => {
          // dispatch(actions.View.setTourCurrentStep(tourCurrentStep + 1));
          dispatch(actions.View.setTourOpen(true));
        }, 1000);
      }

      // if (tourCurrentStep === 1) {
      //   dispatch(actions.View.setTourOpen(false));
      // }
      inputRef?.current?.blur();
    }
  };

  const addLocalActivity = async () => {
    const newItemProps = getNewActivityTemplate();

    dispatch(
      actions.Item.createLocalItem({
        localId: newItemProps?.id,
        item: newItemProps,
        parentId,
        index: index + 1,
        tripId,
        updateNewItemId: true,
      })
    );

    if (parentId === tripId) {
      dispatch(
        actions.TripsV2.addChildToTrip({
          tripId,
          childId: newItemProps?.id,
          index: index + 1,
        })
      );
    }
    // sets this to ensure the last added input is highlighted.
    dispatch(
      actions.Activity.setNewActivity({
        activityId: newItemProps?.id,
      })
    );
    getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).closeTour();
  };

  const handleUpdate = (updatedActivity, additionalFields) => {
    const {
      title: extractedTitle,
      mapPin,
      files: updatedFiles,
      likes: updatedLikes,
      ...activityProps
    } = updatedActivity;
    const newTitle = extractedTitle || title;
    dispatch(
      updateItem({
        variables: {
          id: itemId,
          type: ITEM_TYPES.ACTIVITY,
          title: newTitle,
          ...(likes && { likes: updatedLikes }),
          ...(mapPin && { mapPin }),
          ...(updatedFiles && { files: updatedFiles }),
          content: {
            ...activityProps,
          },
        },
        ...additionalFields,
      })
    );
  };

  const handleLikesActivity = (newLikes) => {
    handleUpdate({
      likes: newLikes,
    });
  };

  const handleTitleUpdate = async (newTitle) => {
    if (newTitle === '') return;
    setTitle(newTitle);
    handleUpdate({
      title: newTitle,
    });
  };

  const handleCostUpdate = (newCost) => {
    const {
      amount: costAmount,
      currency: costCurrency,
      per: costPerOption,
    } = newCost;
    setCost(costAmount);
    setCurrency(costCurrency);
    setCostPer(costPerOption);

    handleUpdate({
      expense: {
        amount: costAmount,
        currency: costCurrency,
        costType: COST_TYPES[costPerOption],
      },
    });
  };

  const handleNotesUpdate = (newNotes) => {
    setNotes(newNotes);
    handleUpdate({
      description: newNotes,
    });
  };

  const handleDateUpdate = (newDate) => {
    setDate(newDate);
    handleUpdate({
      startDate: newDate,
    });
  };

  const handleLinkUpdate = (newLink) => {
    setLink(newLink);
    handleUpdate({
      links: [newLink],
    });
  };

  const handleLocationUpdate = async ({
    streetAddress,
    city,
    state,
    country,
    zipCode,
    mapPin: newMapPin,
    title: newTitle,
  }) => {
    if (isLocalItem(itemId, ITEM_TYPES.ACTIVITY)) {
      await updateActivityTitle('', {
        address: {
          streetAddress,
          city,
          state,
          country,
          zipCode,
        },
        mapPin: newMapPin,
        title: newTitle,
      });
      return;
    }
    setAddress(streetAddress);
    handleUpdate({
      address: {
        streetAddress,
        city,
        state,
        country,
        zipCode,
      },
      mapPin: newMapPin?.id,
      title: newTitle,
    });
  };

  // array to keep track of preview icons
  let previewIcons = [];
  let extraTooltip = [];

  if (link) {
    previewIcons.push(
      <TooltipWrapper title={link} link>
        <Link
          className={classes.activityBladeIcon}
          onClick={() => {
            setOpenInlineBlade(true);
          }}
        />
      </TooltipWrapper>
    );
  }

  if (cost) {
    previewIcons.push(
      <TooltipWrapper title={`${cost} ${currency || 'USD'}`}>
        <AttachMoney
          className={classes.activityBladeIcon}
          style={{ fontSize: '14px' }}
          onClick={() => {
            setOpenInlineBlade(true);
          }}
        />
      </TooltipWrapper>
    );
  }

  if (address) {
    previewIcons.push(
      <TooltipWrapper title={address}>
        <div
          style={{
            height: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setOpenInlineBlade(true);
          }}>
          <LocationPinIcon
            className={classes.activityBladeIcon}
            fill="#8A8A8A"
          />
        </div>
      </TooltipWrapper>
    );
  }

  if (activity?.files && activity?.files.length !== 0) {
    const filesNamesArray = files.map((file) => file?.name || '');
    const filesNames = filesNamesArray.join(', ');

    previewIcons.push(
      <TooltipWrapper title={filesNames}>
        <div
          style={{
            height: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setOpenInlineBlade(true);
            phTrackEvent({
              event: EVENTS.PLAN_ACTIVITY.FILE_VIEW,
            });
            dispatch(actions.FilesV2.setMultipleFilesPreview(activity?.files));
          }}>
          <PaperClip className={classes.activityBladeIcon} stroke="#8A8A8A" />
        </div>
      </TooltipWrapper>
    );
  }

  if (notes) {
    previewIcons.push(
      <TooltipWrapper title={notes} maxWidth="135px">
        <DescriptionOutlined
          className={classes.activityBladeIcon}
          style={{ fontSize: '14px' }}
          onClick={() => {
            setOpenInlineBlade(true);
          }}
        />
      </TooltipWrapper>
    );
  }

  const previewIconsLength = previewIcons.length;
  if (previewIconsLength === 3) {
    extraTooltip =
      link ||
      (cost ? `$${cost} ${currency || 'USD'}` : false) ||
      address ||
      (activity?.files.length !== 0 ? 'File name' : false) ||
      notes;
    previewIcons = previewIcons.slice(1);
    previewIcons.unshift(
      <TooltipWrapper title={extraTooltip}>
        <div
          className={classes.extraTooltipNumber}
          onClick={() => {
            setOpenInlineBlade(true);
          }}>
          +1
        </div>
      </TooltipWrapper>
    );
  } else if (previewIconsLength > 3) {
    extraTooltip = (
      <div style={{ display: 'flex' }}>
        {previewIcons.slice(0, previewIconsLength - 2)}
      </div>
    );
    previewIcons = previewIcons.slice(previewIconsLength - 2);
    previewIcons.unshift(
      <TooltipWrapper title={extraTooltip}>
        <div
          className={classes.extraTooltipNumber}
          onClick={() => {
            setOpenInlineBlade(true);
          }}>
          +{previewIconsLength - 2}
        </div>
      </TooltipWrapper>
    );
  }

  if (date) {
    const dateVal = parseISODate(date, true);
    const options = {
      month: 'short',
      day: 'numeric',
    };

    if (dateVal.getHours() !== 0 || dateVal.getMinutes() !== 0) {
      options.hour = 'numeric';
      options.minute = 'numeric';
      options.hour12 = true;
    }
    const formatted = dateVal.toLocaleString('default', options);

    previewIcons.push(
      <TooltipWrapper title={formatted} maxWidth="150px" controlled>
        <div
          style={{
            height: '16px',
            display: 'flex',
            alignItems: 'center',
          }}>
          <IconDatePicker
            useRange={false}
            onDateUpdate={(d) => handleDateUpdate(d)}
            displayDate={false}
            defaultDate={dateVal}
            iconButtonProps={{
              size: 'small',
              style: { padding: 0 },
              className: classes.iconContainer,
            }}
            iconProps={{
              style: { fontSize: '10px', color: '#8A8A8A', width: '13px' },
            }}
            showComponentTooltip={false}
          />
        </div>
      </TooltipWrapper>
    );
  }

  // To check if the todoitem needs to be focused on input
  useEffect(() => {
    if (isNewActivity) {
      inputRef?.current?.focus();
      setHovered(true);
      dispatch(actions.Item.setNewItemId(null));
    }
  }, [isNewActivity]);

  useEffect(() => {
    return () => {
      clearTimeout(timeRef.current);
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // function to hover the associated map pin on activity hover
  const hoverPin = (shouldHover) => {
    if (activity?.mapPin) {
      dispatch(
        actions.Map.setHoveredPin({
          pinId: shouldHover ? activity?.mapPin : null,
        })
      );
    }
  };

  // function to check single or double click on mobile screens
  const todoClickedMobile = () => {
    if (wait === true) {
      wait = false;
      clearTimeout(timer);
      return 'double';
    }
    wait = true;
    timer = setTimeout(() => {
      wait = false;
      return 'single';
    }, 300);

    return 'single';
  };

  useEffect(() => {
    if (
      tourName === 'sampleTripFlowWeb' &&
      tourCurrentStepName === stepName.FIRST_ACTIVITY_HOVER_INFO &&
      index === 0
    ) {
      getTour().openTour();
      setFocused(true);
      // dispatch(actions.View.setTourOpen(true));
    }
    if (
      tourName === 'sampleTripFlowMobile' &&
      tourCurrentStep === 3 &&
      !tourOpen
    ) {
      getTour().openTour();
      setFocused(true);
      setTimeout(() => {
        waitForStep = false;
      }, 1000);
    }
    if (
      waitForStep === false &&
      tourName === 'sampleTripFlowMobile' &&
      tourCurrentStep === 3 &&
      tourOpen
    ) {
      getTour().closeTour();
      setFocused(true);
    }
    if (
      tourName === 'quickStartFlowMobile' &&
      tourCurrentStep === 1 &&
      tourOpen
    ) {
      getTour().goToNextStep();
      setFocused(true);
      setTimeout(() => {
        waitForStep = false;
      }, 1000);
    }
  }, [tourInProgress, tourName, tourCurrentStep, isFocused]);

  useEffect(() => {
    if (
      isFocused &&
      tourOpen &&
      ((tourName === 'sampleTripFlowMobile' && tourCurrentStep === 3) ||
        (tourCurrentStep === 5 &&
          ['emptyTripFlowWeb', 'blankTripFlowWeb'].includes(tourName)))
    ) {
      getTour().goToNextStep().closeTour();
    }
  }, [isFocused]);

  useEffect(() => {
    if (tourOpen) {
      getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).closeTour();
    }
  }, [isDragging]);

  function CloseIfDragging({ isSnapshotDragging }) {
    useEffect(() => {
      if (isSnapshotDragging) {
        getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).closeTour();
      }
    }, [isSnapshotDragging]);
    return null;
  }
  return (
    <div
      className="activity-blade-container"
      id={`activity-blade-${activity?.mapPin}`}>
      <Grid
        item
        container
        xs={12}
        className={`${classes.spacingRight} ${classList.todoItem}`}
        onMouseLeave={() => {
          if (isHovered && !isFocused) {
            hoverPin(false);
            setHovered(false);
          }
        }}>
        {isSectionHighlight || isParentDragging ? null : (
          <div className={classes.todoItem}>
            <Grid
              item
              className={`${classes.actionContainer} ${classList.itemActionContainer}`}>
              {isHovered && !isFocused && !isMobile ? (
                <>
                  <DeleteOutlineRounded
                    className={classes.actionIcon}
                    onClick={() => {
                      playSound(SOUNDS.pongPop);
                      handleDelete();
                    }}
                  />
                  <AddRounded
                    className={classes.actionIcon}
                    onClick={() => {
                      addLocalActivity();
                      phTrackEvent({
                        event: EVENTS.PLAN_ADD.ACTIVITY_ONHOVER_ADD_ICON_CLICK,
                      });
                    }}
                  />
                </>
              ) : null}
              {/* DragHandleProps seems to require a html element hence the span element */}
              <span className={classes.actionIconDragSpan} {...dragHandleProps}>
                {isHovered && !isFocused && !isMobile ? (
                  <DragIndicatorRounded className={classes.actionIconDrag} />
                ) : null}
              </span>
            </Grid>
          </div>
        )}

        <div
          className={classList.item}
          id="activity-blade-close"
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          {!openInlineBlade ? (
            <Grid
              style={{ display: 'flex', alignItems: 'center' }}
              onMouseLeave={() => {
                setMobileSingleClick(false);
              }}>
              {mobileSingleClick && (
                <span
                  className={classes.actionIconDragSpan}
                  style={{ marginLeft: '0', marginRight: '4px' }}
                  {...dragHandleProps}>
                  <DragIndicatorRounded className={classes.actionIconDrag} />
                </span>
              )}
              <CloseIfDragging isSnapshotDragging={false} />
              <Grid
                className={`
                    activity-blade-in-container
                    ${classes.root}
                    ${
                      isHovered || shouldHighlight || highlightedTodo === itemId
                        ? classes.hovered
                        : null
                    } 
                    ${isDragging ? classes.isDragging : null}
                  `}
                style={{
                  marginLeft: isSectionHighlight || isParentDragging ? 12 : 0,
                }}
                onMouseEnter={() => {
                  setHovered(true);
                  hoverPin(true);
                }}
                ref={containerRef}>
                <OutlinedFlagRounded
                  style={{ fontSize: 16, marginLeft: 4 }}
                  className={classes.activityIcon}
                />
                <Grid item className={classes.inputContainer}>
                  <PlacesSearchBar
                    key={`activity-search-${itemId}`}
                    isActive={isFocused && title?.length > 2}
                    locationBias={locationBias}
                    tripLocation={false}
                    popperPlacement="bottom"
                    CustomSearchBar={InputBase}
                    inputRef={inputRef}
                    handleSelect={async (option) => {
                      const location = await handleLocationSelect(
                        option.place_id,
                        undefined,
                        'ACTIVITY'
                      );
                      const addressProps = location?.streetAddress && {
                        streetAddress: location?.streetAddress,
                        city: location?.city,
                        state: location?.state,
                        country: location?.country,
                        zipCode: location?.zipCode,
                      };
                      playSound(SOUNDS.softPop);
                      setEnterTrigger(true);
                      setFocused(false);
                      setHovered(false);
                      hoverPin(false);
                      if (!delayRef.current) {
                        await updateActivityTitle('Enter', {
                          mapPin: location?.mapPin,
                          title: location?.title,
                          address: addressProps,
                        });
                      } else {
                        delayRef.current = null;
                      }
                      inputRef?.current?.blur();
                    }}
                    value={title}
                    onChange={(newTitle) => setTitle(newTitle)}
                    searchBarProps={{
                      autoComplete: 'off',
                      placeholder: 'new activity',
                      onFocus: () => {
                        if (
                          // waitForStep === false &&
                          tourName === 'quickStartFlowMobile' &&
                          tourCurrentStep === 2 &&
                          tourOpen
                        ) {
                          dispatch(actions.View.setTourCurrentStep(3));
                          dispatch(actions.View.setTourOpen(false));
                        }
                        if (
                          tourCurrentStep === 2 &&
                          tourName === 'sampleTripFlowMobile' &&
                          tourInProgress &&
                          tourOpen
                        ) {
                          dispatch(actions.View.setTourCurrentStep(3));
                        }

                        setFocused(true);
                        if (activity?.mapPin) {
                          focusPin(activity.mapPin);
                        }
                      },
                      onBlur: async () => {
                        timeRef.current = setTimeout(async () => {
                          if (!enterTrigger) {
                            setFocused(false);
                            setHovered(false);
                            hoverPin(false);
                            await updateActivityTitle('Blur');
                          } else {
                            setEnterTrigger(false);
                          }
                        }, 250); // Delay to ensure button click is processed first
                      },
                      onKeyPress: async (e) => {
                        if (e.target.value.length > 0 && tourOpen) {
                          getTour().closeTour().goToNextStep();
                        }
                      },
                      onKeyUp: async (e) => {
                        if (e.key === 'Enter' || e.key === 'Escape') {
                          setEnterTrigger(true);
                          setFocused(false);
                          setHovered(false);
                          hoverPin(false);
                          delayRef.current = true;
                          await updateActivityTitle('Enter');
                        }
                      },
                      onClick: () => {
                        if (title !== '') {
                          if (!isMobile) {
                            setOpenInlineBlade(true);
                            setFocused(false);
                            setHovered(false);
                            hoverPin(false);
                            // change nav bar focus
                            dispatch(
                              actions.View.setCommandBarV2({
                                activeLocationId: destinationId,
                                activeSectionId: parentId,
                              })
                            );
                          } else {
                            const clickType = todoClickedMobile() || 'single';
                            if (clickType === 'single') {
                              setFocused(true);
                              setMobileSingleClick(true);
                            } else if (clickType === 'double') {
                              setMobileSingleClick(false);
                              setOpenInlineBlade(true);
                              setFocused(false);
                              setHovered(false);
                              hoverPin(false);
                            }
                          }
                        }
                      },

                      inputProps: {
                        style: { padding: 3, cursor: 'pointer' },
                      },
                    }}
                  />
                </Grid>
                <Grid className={classes.activityBladeIconContainer}>
                  {previewIcons}
                </Grid>
                <Grid item style={{ display: 'flex', shrink: 1 }}>
                  <Typography
                    className={`${classes.text} ${classes.likesCount}`}>
                    {likes?.length > 0 ? likes.length : ''}
                  </Typography>
                  <Typography
                    className={classes.text}
                    style={{ marginRight: 4, position: 'relative' }}>
                    {!isFocused || isMobile ? (
                      !isIOS && likeAnimation ? (
                        <>
                          <Favorite
                            className={classes.favorite}
                            style={{ visibility: 'hidden' }}
                          />
                          <CustomLottiePlayer
                            filePath="/lottiefiles/heart_animation.lottie"
                            style={{
                              width: 65,
                              height: 65,
                              position: 'absolute',
                              right: '-24px',
                            }}
                            autoplay={false}
                            loop={false}
                          />
                        </>
                      ) : likes?.length > 0 ? (
                        <Tooltip
                          classes={{
                            tooltip: classes.tooltip,
                            arrow: classes.tooltipArrow,
                          }}
                          arrow
                          placement="top"
                          title={
                            <div
                              style={{
                                padding: '5px 9px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}>
                              <div style={{ marginRight: '-5px' }}>
                                <AvatarStack
                                  people={likedUsers}
                                  renderSmall={false}
                                  limit={4}
                                  LTR
                                  CustomLimitComponent={({ count }) => (
                                    <span className={classes.greyText}>
                                      + {count}
                                    </span>
                                  )}
                                />
                              </div>

                              <div
                                style={{
                                  maxWidth: '150px',
                                  textWrap: 'wrap',
                                  textAlign: 'center',
                                  marginTop: '0px',
                                  lineHeight: '16px',
                                  whiteSpace: 'normal',
                                }}>
                                {likedUsersCopy}
                              </div>
                            </div>
                          }
                          TransitionComponent={Fade}>
                          {!isLiked ? (
                            <Favorite
                              className={classes.favoriteInactive}
                              onClick={() => {
                                playSound(SOUNDS.heartChime);
                                setLikeAnimation(true);
                                setLike(true);
                                const newLikes = [...likes, userId];
                                setCount(newLikes);
                                handleLikesActivity(newLikes);
                                setTimeout(() => {
                                  setLikeAnimation(false);
                                }, 1000);
                              }}
                            />
                          ) : (
                            <Favorite
                              className={classes.favorite}
                              onClick={() => {
                                playSound(SOUNDS.removeBaritone);
                                setLike(false);
                                const newLikes = [...likes];
                                const userLikeIndex = newLikes.indexOf(userId);
                                newLikes.splice(userLikeIndex, 1);
                                setCount(newLikes);
                                handleLikesActivity(newLikes);
                              }}
                            />
                          )}
                        </Tooltip>
                      ) : (
                        <Favorite
                          className={classes.favoriteInactive}
                          onClick={() => {
                            setLikeAnimation(true);
                            playSound(SOUNDS.heartChime);
                            setLike(true);
                            const newLikes = [...likes, userId];
                            setCount(newLikes);
                            handleLikesActivity(newLikes);
                            setTimeout(() => {
                              setLikeAnimation(false);
                            }, 1000);
                          }}
                        />
                      )
                    ) : null}
                  </Typography>
                </Grid>
              </Grid>
              {mobileSingleClick && (
                <DeleteOutlineRounded
                  className={classes.actionIcon}
                  onClick={handleDelete}
                />
              )}
            </Grid>
          ) : (
            <div
              style={{
                margin: '0px 0% 0% 0px',
              }}>
              <InlineBlade
                className="activity-inlineblade-container"
                open={openInlineBlade}
                id={itemId}
                locationBias={locationBias}
                setOpen={async (isOpen) => {
                  if (!isOpen && activity?.mapPin?.id === place?.pinId) {
                    setPlace(null);
                  }
                  setOpenInlineBlade(isOpen);
                  if (!isOpen)
                    await updateActivityTitle('Close', {
                      expense: {
                        amount: cost,
                        currency,
                        costType: COST_TYPES[Number(costPer)],
                      },
                      startDate: date,
                      description: notes,
                      address: {
                        streetAddress: address,
                      },
                      links: link ? [link] : [],
                      files: activity?.files,
                    });
                  if (isMobile) {
                    setHovered(isOpen);
                    setFocused(isOpen);
                  }
                }}
                handleEnterPress={async (newTitle) => {
                  setTitle(newTitle);
                  await updateActivityTitle('Close', {
                    title: newTitle,
                  });
                  setOpenInlineBlade(false);
                  if (newTitle !== '') {
                    await addLocalActivity();
                  }
                }}
                name={title}
                updateNameCallback={handleTitleUpdate}
                notes={notes}
                updateNotesCallback={handleNotesUpdate}
                hasDate
                dateVal={date}
                updateDateCallback={handleDateUpdate}
                hasCost
                costVal={{
                  amount: cost,
                  currency,
                  per: costPer,
                }}
                updateCostCallback={handleCostUpdate}
                hasLink
                linkVal={link}
                updateLinkCallback={handleLinkUpdate}
                isActivity
                hasLocation
                updateLocationCallback={handleLocationUpdate}
                address={address}
                setAddress={setAddress}
                mapPinId={activity?.mapPin}
                trackFileView={() =>
                  phTrackEvent({
                    event: EVENTS.PLAN_ACTIVITY.FILE_VIEW,
                  })
                }
                fileAttachmentModalProps={{
                  attachedToText: title,
                  attachedToID: itemId,
                  attachedToType: 'Activity',
                  attachedFiles: activity?.files,
                  attachFunc: (newFiles, tripid) => {
                    handleUpdate(
                      {
                        files: newFiles,
                      },
                      {
                        attachedToType: 'Activity',
                        tripId: tripid,
                      }
                    );
                  },
                }}
                isNewActivity={isLocalItem(itemId, ITEM_TYPES.ACTIVITY)}
                addActivityBelow={addLocalActivity}
                activityVersion={activity?.version}
              />
            </div>
          )}
        </div>
        {/* {provided.placeholder} */}
      </Grid>
      {/* )}
      </Draggable> */}
    </div>
  );
}

export default Activity;
