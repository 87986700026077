import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import graphqlClient from '../../graphql/index';
import QUERY from '../../graphql/queries';
import { LocationActions, createLocation, deleteLocation } from './Location';
import { AccommodationActions, deleteAccommodation } from './Accommodation';
import { SectionActions } from './Section';
import {
  TransportationActions,
  createTransportation,
  deleteTransportation,
} from './Transportation';
import getAsyncThunk, { reorderList, getMapPinList } from '../helpers';
import { handleDndItems, createTripMapPin } from './sharedThunks';
import { ActivityActions, deleteActivity } from './Activity';
import { trackEvents, Events } from '../../intercom';
import { RecommendationsActions } from './Recommendations';
import { BookingsActions, createBooking } from './Bookings';

const normalizeTrips = (GQLTrips, existingTrips = {}) => {
  const trips = {};
  GQLTrips.forEach((trip) => {
    trips[trip.id] = {
      // if the getUserTrip completes before the getAllTrips, prioritize information from the user trip.
      ...trip,
      status: 'IDLE',
      ...(existingTrips[trip?.id] || {}),
    };
  });
  return trips;
};

const normalizeSection = (GQLSection, dispatch) => {
  const { todos, ...section } = GQLSection;
  const activityFiles = [];
  const activities = {};
  const activityFilesRelations = [];

  section.todos = todos.map(({ mapPin = null, files = [], ...todo }) => {
    activities[todo.id] = {
      ...todo,
      mapPin: mapPin?.id,
      // parsing activity files and adding to state;
      files: files?.map((file) => {
        activityFiles.push({
          ...file,
          attachedToText: todo?.title,
        });
        activityFilesRelations.push({
          attachedToType: 'Activity',
          attachedToId: todo.id,
          fileId: file.id,
        });
        return file?.id;
      }),
    };
    return todo.id;
  });

  // TODO: function call to update todos to the todoSlice
  dispatch(ActivityActions.initializeActivities({ ...activities }));
  return { section, activityFiles, activityFilesRelations };
};

const normalizeLocation = (GQLLocation, dispatch) => {
  const {
    hotels = [],
    thingsToDo = [],
    mapPin = null,
    ...location
  } = GQLLocation;
  const accommodations = {};
  const sections = {};
  const locationFiles = [];
  const accommodationsFilesRelations = [];
  const todoFilesRelations = [];

  location.hotels = hotels.map(
    ({ mapPin: hotelMapPin, files: hotelFiles, ...hotel }) => {
      accommodations[hotel.id] = {
        mapPin: hotelMapPin?.id,

        // parses files inside accommodation, and pushes to global state.
        files: hotelFiles?.map((file) => {
          locationFiles.push({
            ...file,
            attachedToText: hotel?.name,
          });
          accommodationsFilesRelations.push({
            attachedToType: 'Accommodation',
            attachedToId: hotel.id,
            fileId: file.id,
          });
          return file.id;
        }),
        ...hotel,
      };
      return hotel.id;
    }
  );

  // TODO: function call to update accommodations to the accommodationSlice
  dispatch(
    AccommodationActions.initializeAccommodations({ ...accommodations })
  );

  location.thingsToDo = thingsToDo.map((thingToDo) => {
    const {
      section,
      activityFiles = [],
      activityFilesRelations = [],
    } = normalizeSection(thingToDo, dispatch);
    locationFiles.push(...activityFiles);
    todoFilesRelations.push(...activityFilesRelations);
    sections[thingToDo.id] = { ...section };
    return thingToDo.id;
  });

  // TODO: function call to update sections to the sectionSlice
  dispatch(SectionActions.initializeSections({ ...sections }));

  return {
    location: { ...location, mapPin: mapPin?.id },
    locationFiles,
    locationFilesRelations: [
      ...accommodationsFilesRelations,
      ...todoFilesRelations,
    ],
  };
};

const normalizeTrip = (trips, GQLTrip, dispatch) => {
  const {
    items,
    mapPins = [],
    files = [],
    saved = [],
    ...tripProperties
  } = GQLTrip;
  const trip = {
    ...trips[tripProperties.id],
    ...tripProperties,
    status: 'SUCCESS',
  };
  const allFiles = [];
  const allFilesRelations = [];

  // TRAVERSE THROUGH TRIP TO GET ALL ITEMS
  if (items) {
    const locations = {};
    const transportations = {};

    // append to trip
    trip.items = items.map((item) => {
      if (item.__typename === 'Location') {
        const { location, locationFiles, locationFilesRelations } =
          normalizeLocation(item, dispatch);
        locations[item.id] = location;
        allFiles.push(...locationFiles);
        allFilesRelations.push(...locationFilesRelations);
      } else if (item.__typename === 'Transportation') {
        transportations[item.id] = {
          ...item,
          details: item?.details?.map((flight) => ({
            ...flight,
            files: flight?.files?.map((file) => {
              allFiles.push({ ...file, attachedToText: flight?.flightNumber });
              allFilesRelations.push({
                attachedToType: 'Transportation',
                attachedToId: flight.id,
                fileId: file.id,
              });
              return file.id;
            }),
          })),
        };
      }
      return {
        [item?.__typename?.toLowerCase()]: item.id,
      };
    });

    if (files?.length > 0) {
      files.forEach((file) => {
        allFilesRelations.push({
          attachedToType: 'Trip',
          attachedToId: trip.id,
          fileId: file.id,
        });
      });
    }

    // TODO: function call to update locations to the locationSlice and transportations to the transportationSlice
    dispatch(LocationActions.initializeLocations({ ...locations }));
    dispatch(
      TransportationActions.initializeTransportations({ ...transportations })
    );
  }

  dispatch(
    RecommendationsActions.initializeSaves({
      saved: saved || [],
      tripId: trip?.id,
    })
  );

  dispatch(
    BookingsActions.initializeSaves({
      saved: saved || [],
      tripId: trip?.id,
    })
  );

  // Normalize files into their seperate slice
  trip.files = files.map((file) => file.id);

  return {
    ...trip,
    mapPins: mapPins?.map((mapPin) => mapPin?.id),
    tripFiles: [...files, ...allFiles],
    saved,
    allFilesRelations,
  };
};

const initialState = {
  trips: {},
  status: 'IDLE', // IDLE, LOADING, SUCCESS
  mapPins: [],
  invitations: [],
  travelStats: null,
  exportPdf: {
    options: {
      format: 'pdf',
      includeImages: true,
      includeNotes: true,
    },
    mapPinImages: {},
  },
};

export const getTrips = createAsyncThunk(
  'TRIPS/getTrips',
  async (userId, { rejectWithValue }) => {
    try {
      const { data, error } = await graphqlClient.query({
        query: QUERY.GET_ALL_TRIPS,
        variables: { userId },
        fetchPolicy: 'network-only',
      });
      if (error) throw new Error(error.message);
      // All normalization should happen here to be able to trigger the extra reducers with ready data.
      return {
        trips: data.getAllTrips,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// export const updateTrip = getAsyncThunk('TRIPS/updateTrip', QUERY.UPDATE_TRIP);

export const updateTrip = createAsyncThunk(
  'TRIPS/updateTrip',
  async ({ variables }, { rejectWithValue }) => {
    const { data, error } = await graphqlClient.mutate({
      mutation: QUERY.UPDATE_TRIP,
      variables,
    });
    if (error) {
      rejectWithValue(error);
    }
    return data;
  }
);

export const updateTripName = getAsyncThunk(
  'TRIPS/updateTripName',
  QUERY.UPDATE_TRIP_NAME
);

export const getTravelStats = getAsyncThunk(
  'TRIPS/getTravelStats',
  QUERY.GET_TRAVEL_STATS
);

export const getInvitations = getAsyncThunk(
  'TRIPS/getInvitations',
  QUERY.GET_INVITATIONS
);

export const createTripInvitation = getAsyncThunk(
  'TRIPS/createTripInvitation',
  QUERY.CREATE_INVITATION
);

export const deleteTripInvitation = getAsyncThunk(
  'TRIPS/deleteTripInvitation',
  QUERY.DELETE_INVITATION
);

export const addExampleTripV2 = getAsyncThunk(
  'TRIPS/addExampleTrip',
  QUERY.ADD_EXAMPLE_TRIP
);

export const patchTzForTrip = getAsyncThunk(
  'TRIPS/patchTz',
  QUERY.PATCH_TZ_FOR_TRIP
);

export const removeUserFromTrip = getAsyncThunk(
  'TRIPS/removeUserFromTrip',
  QUERY.REMOVE_USER_FROM_TRIP
);

export const addUserToTrip = getAsyncThunk(
  'TRIPS/addUserToTrip',
  QUERY.ADD_USER_TO_TRIP
);

export const getCompleteTrip = createAsyncThunk(
  'TRIPS/getCompleteTrip',
  async (
    { tripId, ignoreRejection = false },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const { data, error } = await graphqlClient.query({
        query: QUERY.GET_TRIP,
        variables: { id: tripId },
        fetchPolicy: 'network-only',
      });
      if (error) {
        throw new Error(error.message);
      }
      const { tripFiles, allFilesRelations, ...trip } = normalizeTrip(
        getState().Trips.trips,
        data?.getTrip,
        dispatch
      );
      const mapPinList = getMapPinList(data?.getTrip);
      return {
        trip,
        mapPins: data?.getTrip?.mapPins?.map((mapPin) => mapPin?.id) || [],
        mapPinList,
        tripFiles,
        allFilesRelations,
        meta: { tripId },
      };
    } catch (e) {
      if (e.message?.includes('REDIRECT_TO_V2')) {
        const v2TripId = e.message.split('___')[1];
        window.location.href = `/trips/${v2TripId}/planner`;
        return null;
      }
      if (!ignoreRejection) return rejectWithValue(e);
      return {};
    }
  }
);

export const createTrip = createAsyncThunk(
  'TRIPS/createTrip',
  async ({ trip, callback }, { rejectWithValue }) => {
    try {
      const { data, error } = await graphqlClient.mutate({
        mutation: QUERY.CREATE_TRIP,
        variables: trip,
      });
      if (error) throw error;
      trackEvents(Events.TripCreated);
      return { trip: data?.createTrip, callback };
    } catch (err) {
      rejectWithValue(err);
      return null;
    }
  }
);

export const deleteTrip = createAsyncThunk(
  'TRIPS/deleteTrip',
  async (tripId, { rejectWithValue }) => {
    try {
      const { error } = await graphqlClient.mutate({
        mutation: QUERY.DELETE_TRIP,
        variables: { id: tripId },
      });
      if (error) {
        throw error;
      }
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const TripsSlice = createSlice({
  name: 'TRIPS',
  initialState,
  reducers: {
    resetTrip: (state) => {
      state.trips = {};
      state.error = null;
    },
    deleteLocalTrip: (state, { payload }) => {
      state.trips = state.trips.filter((trip) => trip.id !== payload.id);
    },
    deleteTripFile: (state, { payload: { tripId, fileId } }) => {
      state.trips[tripId] = {
        ...state.trips[tripId],
        files: state.trips[tripId].files.filter((file) => file !== fileId),
      };
    },
    setActiveBookingId: (state, { payload: { bookingId, tripId } }) => {
      if (!tripId || !state.trips[tripId]) return;
      state.trips[tripId].activeBookingId = bookingId;
    },
    removeImportedFile: (state, { payload: { tripId, fileId } }) => {
      state.trips[tripId] = {
        ...state.trips[tripId],
        importedFiles: state.trips[tripId]?.importedFiles?.filter(
          (file) => file?.id !== fileId
        ),
      };
    },
    addTripFile: (state, { payload: { tripId, fileId } }) => {
      state.trips[tripId].files = [...state.trips[tripId].files, fileId];
    },
    setTravelStats: (state, { payload }) => {
      state.travelStats = payload;
    },
    setExportPdfMapPinImages: (state, { payload }) => {
      state.exportPdf.mapPinImages = payload;
    },
    setExportPdfOptions: (
      state,
      { payload: { format, includeImages, includeNotes } }
    ) => {
      state.exportPdf.options = {
        ...state.exportPdf.options,
        format,
        includeImages,
        includeNotes,
      };
    },
  },
  extraReducers: {
    [getTrips.pending]: (state, { meta: { setLoader = true } }) => {
      if (setLoader) state.status = 'LOADING';
    },
    [getTrips.fulfilled]: (state, { payload }) => {
      state.status = 'SUCCESS';
      const { trips } = payload;

      state.count = trips?.length || 0;
      if (payload?.trips?.length)
        state.trips = {
          ...(state.trips || {}),
          ...(normalizeTrips(trips, state?.trips) || {}),
        };
      // payload.callback(payload);
    },
    [getTrips.rejected]: (state, { payload }) => {
      state.status = 'IDLE';
      state.error = payload;
    },
    /// /////////////////////////////////////////
    [getCompleteTrip.pending]: (
      state,
      {
        meta: {
          arg: { tripId },
        },
      }
    ) => {
      state.trips[tripId] = {
        ...state.trips[tripId],
        status: 'LOADING',
      };
    },
    [getCompleteTrip.fulfilled]: (state, { payload }) => {
      const { trip, mapPins } = payload;
      const tripId = trip?.id;
      state.trips[tripId] = {
        ...state.trips[tripId],
        ...trip,
        mapPins,
        status: 'SUCCESS',
      };
    },
    [getCompleteTrip.rejected]: (state, { meta: { arg: tripId } }) => {
      if (Object.prototype.hasOwnProperty.call(state.trips, tripId))
        state.trips[tripId].status = 'IDLE';
    },
    [createTrip.fulfilled]: (
      state,
      {
        payload: {
          trip: { id: tripId },
        },
        meta: {
          arg: { trip, shouldUpdateStore = false },
        },
      }
    ) => {
      if (shouldUpdateStore) {
        state.trips[tripId] = {
          id: tripId,
          coverImage: trip?.coverImage,
          title: trip?.title,
          startDate: trip?.startDate,
          endDate: trip?.endDate,
          status: 'IDLE',
          items: [],
        };
      }
    },
    [updateTrip.fulfilled]: (
      state,
      {
        payload: {
          updateTrip: { version },
        },
        meta: {
          arg: { variables, newAddedUser },
        },
      }
    ) => {
      const trip = {
        ...variables,
        ...(newAddedUser
          ? {
              sharedUsers: [
                ...(variables?.sharedUsers?.filter(
                  (user) => user !== newAddedUser?.id
                ) || []),
                newAddedUser,
              ],
            }
          : {}),
        ...(version && { version }),
      };
      state.trips[trip?.id] = {
        ...(state.trips[trip?.id] || {}),
        ...trip,
      };
    },
    [updateTripName.fulfilled]: (
      state,
      {
        meta: {
          arg: { variables: trip },
        },
      }
    ) => {
      state.trips[trip?.id] = {
        ...(state.trips[trip?.id] || {}),
        ...trip,
      };
    },
    [getTravelStats.fulfilled]: (state, { payload }) => {
      state.travelStats = payload?.getTravelStats || [];
    },

    [createTransportation.fulfilled]: (state, { payload, meta }) => {
      const {
        variables,
        extra: { index },
      } = meta.arg;
      const transportId = payload.createTransportation.id;
      const tripId = variables.trip;
      const newItems = [...state.trips[tripId].items];
      newItems.splice(index + 1, 0, {
        transportation: transportId,
      });
      const { type } = variables;
      switch (type) {
        case 'Flight':
          trackEvents(Events.FlightAdded);
          break;
        case 'Bus/Train':
          trackEvents(Events.BusTrainAdded);
          break;
        case 'Other':
          trackEvents(Events.OtherTransportAdded);
          break;

        default:
          trackEvents(Events.FlightAdded);
          break;
      }

      // TODO: update logic to do this along with create transportation on the backend with a single API call
      graphqlClient.mutate({
        mutation: QUERY.UPDATE_TRIP,
        variables: {
          id: tripId,
          items: newItems,
        },
      });
      state.trips[tripId].items = newItems;
    },
    [deleteTransportation.fulfilled]: (state, { meta }) => {
      const {
        variables: { id: transportId, filesToBeAdded },
        extra: { tripId },
      } = meta.arg;

      if (
        state.trips[tripId]?.files?.length > 0 &&
        filesToBeAdded?.length > 0
      ) {
        state.trips[tripId].files = [
          ...state.trips[tripId].files,
          ...filesToBeAdded,
        ];
      }

      let updatedItems = state.trips[tripId]?.items;
      updatedItems = updatedItems.filter(
        (item) =>
          Object.prototype.hasOwnProperty.call(item, 'location') ||
          item.transportation !== transportId
      );

      // TODO: update logic to do this along with delete transportation on the backend with a single API call
      graphqlClient.mutate({
        mutation: QUERY.UPDATE_TRIP,
        variables: {
          id: tripId,
          items: updatedItems,
        },
      });

      state.trips[tripId].items = updatedItems;
    },
    [deleteLocation.fulfilled]: (state, { meta }) => {
      const { id: locationId, tripId } = meta.arg.variables;
      state.trips[tripId].items = state.trips[tripId].items.filter(
        (item) =>
          Object.prototype.hasOwnProperty.call(item, 'transportation') ||
          item.location !== locationId
      );
    },
    [createLocation.fulfilled]: (state, { payload, meta }) => {
      const { id: locationId } = payload.createLocation;

      const {
        variables: { tripID, index },
        shouldUpdateTrip = true,
      } = meta.arg;
      trackEvents(Events.CityAdded);
      if (!shouldUpdateTrip) {
        return;
      }
      const item = {
        location: locationId,
      };
      const newItems = state.trips[tripID]?.items || [];
      newItems.splice(index, 0, item);
      state.trips[tripID].items = newItems;
    },
    [handleDndItems.pending]: (
      state,
      {
        meta: {
          arg: { source, destination },
        },
      }
    ) => {
      const sourceItems = state.trips[source.droppableId]?.items;
      const { sourceList } = reorderList(
        sourceItems,
        sourceItems,
        source,
        destination
      );
      state.trips[source.droppableId].items = sourceList;
    },
    [getInvitations.fulfilled]: (state, { payload }) => {
      state.invitations = payload?.getInvitations || [];
    },
    [createTripInvitation.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { tripId },
          },
        },
        payload: {
          createInvitation: { invitedUsers },
        },
      }
    ) => {
      trackEvents(Events.InviteSent);
      state.trips[tripId].invitedUsers = invitedUsers;
    },
    [deleteTripInvitation.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { tripId, id },
          },
        },
        payload: {
          deleteInvitation: { invitedUsers, sharedUsers },
        },
      }
    ) => {
      state.invitations = state.invitations.filter(
        (invitation) => invitation?.id !== id
      );
      if (tripId && state.trips[tripId]) {
        state.trips[tripId].invitedUsers = invitedUsers;
        state.trips[tripId].sharedUsers = sharedUsers;
      }
    },
    [deleteTrip.fulfilled]: (state, { meta }) => {
      delete state.trips[meta.arg];
    },
    [deleteTrip.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [removeUserFromTrip.pending]: (
      state,
      {
        meta: {
          arg: {
            variables: { tripId, userId },
          },
        },
      }
    ) => {
      state.trips[tripId] = {
        ...state.trips[tripId],
        sharedUsers: (state.trips[tripId]?.sharedUsers || []).filter(
          (user) => user?.id !== userId
        ),
      };
    },
    [createTripMapPin.fulfilled]: (
      state,
      {
        payload: { createTripMapPin: mapPin },
        meta: {
          arg: {
            variables: { tripId },
          },
        },
      }
    ) => {
      if (mapPin?.id && tripId) state.trips[tripId]?.mapPins?.push(mapPin.id);
    },

    ...([deleteActivity.fulfilled, deleteAccommodation.fulfilled].reduce(
      (deleteFileReducers, action) => ({
        ...deleteFileReducers,
        [action]: (
          state,
          {
            meta: {
              arg: {
                variables: { tripId, filesToBeAdded },
              },
            },
          }
        ) => {
          if (
            state.trips[tripId]?.files?.length > 0 &&
            filesToBeAdded?.length > 0
          ) {
            state.trips[tripId].files = [
              ...state.trips[tripId].files,
              ...filesToBeAdded,
            ];
          }
        },
      }),
      {}
    ) || {}),

    [createBooking.fulfilled]: (
      state,
      {
        meta: {
          arg: {
            variables: { input },
          },
        },
        payload,
      }
    ) => {
      if (input.tripId) {
        state.trips[input?.tripId].bookings = [
          ...(state.trips[input?.tripId]?.bookings || []),
          { ...input, id: payload?.createBooking },
        ];
      }
    },
  },
});

export const TripsActions = TripsSlice.actions;
export const TripsReducer = TripsSlice.reducer;
