import React, { useState, useRef, useEffect } from 'react';

import {
  Typography,
  TextField,
  Popper,
  List,
  ListItem,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { LocationOnOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet'; // Import Helmet
import { fetchCities } from '../../redux/slices/Discover';
import AppLayout from '../templates/AppLayout';
import { SearchRoundedIcon } from '../atoms/Icon';
import PopularSection from '../organisms/Discover/PopularSection';
import classList from '../classList';

const useStyles = makeStyles(({ palette, typography }) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    flexDirection: 'column',
  },

  content: {
    padding: '0px 8%',
    marginTop: 40,
  },

  // searchbar
  searchbarContainer: {
    width: '100%',
    border: '1px solid #D9D9D9',
    borderRadius: 40,
    maxWidth: 790,
    padding: '0px 16px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      border: '1px solid #FFA766',
    },
    '& > .MuiInputBase-input:focus': {
      border: '1px solid #FFA766',
    },
  },
  searchFieldInput: {
    border: 0,
  },
  searchField: {
    padding: '12px 8px',
    margin: 0,
  },
  input: {
    ...typography.h4,
    color: palette.text.secondary,
    '&::placeholder': {
      color: palette.text.light,
    },
  },
  list: {
    width: '100%',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#FFF',
    borderRadius: 4,
    padding: '10px',
  },
  listItem: {
    ...typography.h5,
    width: '100%',
    borderBottom: '1px solid #DEDDDD',
    cursor: 'pointer',
    color: palette.text.secondary,
    whiteSpace: 'pre',
  },
}));

function DiscoverSearchbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchbarFocused, setSearchbarFocused] = useState(false);

  const inputRef = useRef(null);
  const inputContainerRef = useRef(null);

  const allCities = useSelector((state) =>
    Object.values(state.Discover?.cities?.data).map((city) => ({
      title: city?.name,
      slug: city?.slug,
      country: city?.country,
    }))
  );

  const handleSearch = (e) => {
    setLoading(true);
    e.stopPropagation();
    e.preventDefault();
    setLoading(false);
  };

  const handleSelect = (option) => {
    navigate(`/discover/${option?.slug}`);
  };

  const filteredCities = allCities
    .filter((city) =>
      `${city?.title}, ${city?.country}`
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase())
    )
    .map((city) => {
      const searchTerm = `${city?.title}, ${city?.country}`;
      const startIndex = searchTerm
        ?.toLowerCase()
        .indexOf(searchValue?.toLowerCase());
      const endIndex = startIndex + (searchValue?.length || 0);
      return {
        ...city,
        parts: [
          searchTerm?.slice(0, startIndex),
          searchTerm?.slice(startIndex, endIndex),
          searchTerm?.slice(endIndex, searchTerm?.length),
        ],
        matchLength: endIndex - startIndex,
        matchStart:
          startIndex > city?.title?.length
            ? startIndex - (city?.title?.length || 0)
            : startIndex,
      };
    })
    .sort(
      // sort by relevance
      (c1, c2) =>
        c1.matchLength !== c2.matchLength
          ? c2.matchLength - c1.matchLength
          : c1.matchStart - c2.matchStart
    );

  return (
    <div
      className={classes.searchbarContainer}
      ref={inputContainerRef}
      style={{
        ...(searchbarFocused
          ? {
              border: '1px solid #FFA766',
            }
          : {}),
      }}>
      <SearchRoundedIcon
        style={{
          marginRight: 4,
          marginBottom: 2,
        }}
        color={searchbarFocused ? '#FFA766' : '#4E4E4E'}
      />
      <TextField
        inputRef={inputRef}
        fullWidth
        placeholder="Search cities"
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
        value={searchValue}
        InputProps={{
          classes: {
            notchedOutline: classes.searchFieldInput,
            input: classes.input,
          },
        }}
        inputProps={{
          className: `${classes.searchField} ${classList.discoverSearchBar}`,
        }}
        onFocus={() => {
          setAnchorEl(inputContainerRef?.current);
          setSearchbarFocused(true);
        }}
        onBlur={() => {
          setAnchorEl(null);
          setSearchbarFocused(false);
        }}
      />
      <Popper
        id="city-suggestions-popper"
        open={Boolean(anchorEl) && searchValue !== ''}
        anchorEl={anchorEl}
        sx={{
          width: inputContainerRef?.current?.offsetWidth,
        }}>
        <List className={classes.list}>
          {filteredCities?.length > 0 ? (
            filteredCities?.slice(0, 5)?.map((cityOption, index) => (
              <ListItem
                onMouseDown={() => {
                  handleSelect(cityOption);
                }}
                id={`discover-search-option-${cityOption?.id}`}
                key={`city-suggestion-${index}`}
                className={`${classes.listItem}`}>
                <span style={{ paddingRight: 8 }}>
                  <LocationOnOutlined
                    sx={{
                      strokeWidth: 4,
                    }}
                  />
                </span>
                {`${cityOption?.parts[0]}`}
                <span
                  style={{
                    fontWeight: 'bold',
                  }}>{`${cityOption?.parts[1]}`}</span>
                {`${cityOption?.parts[2]}`}
              </ListItem>
            ))
          ) : (
            <div
              style={{
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography>
                {searchValue !== '' && !loading
                  ? "Oops, we don't have that on our list, try a broader search"
                  : ''}
              </Typography>
            </div>
          )}
        </List>
      </Popper>
    </div>
  );
}

function Discover() {
  const classes = useStyles();

  // Load content if not already loaded
  const fetchStatus = useSelector((state) => state.Discover.cities.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchStatus === 'IDLE') {
      dispatch(fetchCities());
    }
  }, [fetchStatus]);

  return (
    <AppLayout>
      <Helmet>
        <title>Discover | Pilot Trip Planner</title>{' '}
      </Helmet>
      <div className={classes.header}>
        <Typography variant="h2Sub">Discover Cities</Typography>
        <div style={{ marginTop: 16 }} />
        {fetchStatus === 'SUCCESS' ? <DiscoverSearchbar /> : null}
      </div>

      <div className={classes.content}>
        {fetchStatus === 'LOADING' ? (
          <div
            style={{
              width: '100%',
              padding: '4% 0%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CircularProgress />
          </div>
        ) : fetchStatus === 'ERROR' ? (
          <Typography variant="h4Sub">
            Oops, something weird happend while getting your cities, please try
            again!
          </Typography>
        ) : (
          <PopularSection
            heading="Popular Destinations"
            description="Discover cities that people are travelling to right now"
          />
        )}
      </div>
    </AppLayout>
  );
}

export default Discover;
