import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Divider } from '@mui/material';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import ActivityButton from './ActivityButton';
import HotelButton from './HotelButton';
import DestinationButton from './DestinationButton';
import HeadingButton from './HeadingButton';
import TransportationButton from './TransportationButton';
import AiButton from './AI';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { stepName, tourName } from '../../../../assets/onboarding/steps';
import FLAGS from '../../../../featureFlags';
import actions from '../../../../redux/actions';
import useTour from '../../../molecules/Tour/useTour';
import ITEM_TYPES from '../../../../const';
import { getLocalItemId } from '../../../../utils';

const useStyles = makeStyles({
  mainContainer: {
    position: 'fixed',
    bottom: 25,
    left: '50%',
    width: '500px',
    transform: 'translateX(-50%)',
    zIndex: 999,
  },
});

const commonStyles = {
  componentsProps: {
    tooltip: {
      sx: {
        backgroundColor: '#000000',
        color: '#ffffff',
      },
    },
    popper: {
      sx: { zIndex: 9300 },
    },
    arrow: {
      sx: {
        color: '#000000',
      },
    },
  },
  shortcutKey: {
    display: 'inline-block',
    padding: '0px 2px',
    marginLeft: '8px',
    border: '0.2px solid #8A8A8A',
    borderRadius: '3px',
    fontSize: '12px',
    color: '#8A8A8A',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hoverStyle: {
    '&:hover': {
      backgroundColor: '#f4f4f4',
      cursor: 'grab',
      borderRadius: 2,
    },
    height: '35px',
    width: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    transition: 'background-color 0.3s, transform 0.3s',
  },
  listItemContainer: {
    height: '42px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      color: '#000000',
    },
  },
  listItemText: {
    '&:hover': {
      color: '#000000',
    },
    cursor: 'default',
  },
  clickedStyle: {
    backgroundColor: '#d9d9d9',
    cursor: 'pointer',
    borderRadius: 2,
    height: '35px',
    width: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s, transform 0.3',
    transform: 'scale(0.98)',
  },
  draggableIconClickStyle: {
    cursor: 'pointer',
    borderRadius: 2,
    height: '35px',
    width: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.6, box-shadow 0.6s',
    transform: 'scale(0.98)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  draggableExpandedState: {
    backgroundColor: '#ffffff',
    width: '520px',
    height: '50px',
    borderRadius: '8px',
    padding: '10px',
    border: '2px solid #ffa500',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'width 0.4s, height 0.4s, transform 0.4s',
  },
  arrowStyle: {
    height: '35px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  clickableTextContainer: {
    '&:hover': {
      backgroundColor: '#f4f4f4',
      cursor: 'pointer',
      borderRadius: 2,
    },
    height: '35px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  arrowClickedStyle: {
    backgroundColor: '#d9d9d9',
    cursor: 'pointer',
    borderRadius: 2,
    height: '35px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'background-color 0.3s, transform 0.3s',
    transform: 'scale(0.98)',
  },
};

function CommandBarV2({ tripId }) {
  const classes = useStyles();
  // const { setNewSection } = useItineraryDnd();
  const isAIButtonEnabled = useFeatureFlagEnabled(
    FLAGS.UNIVERSAL_ADD_BAR_AI_BUTTON
  );
  const isKeyboardShortcutEnabled = useFeatureFlagEnabled(
    FLAGS.UNIVERSAL_ADD_BAR_KEYBOARDSHORTCUTS
  );

  const dispatch = useDispatch();
  const aiPopperRef = useRef(null);
  const recommendedActivitiesRef = useRef([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [aiAnchorEl, setAiAnchorEl] = useState(null);
  const [value, setValue] = useState('');
  const [activeAISection, setActiveAISection] = useState('suggestActivities');
  const [headingAnchorEl, setHeadingAnchorEl] = useState(null);
  const [transportationAnchorEl, setTransportationAnchorEl] = useState(null);
  const currentTrip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);
  const { activities: activitiesList = [] } = useSelector(
    (state) => state.Recommendations || []
  );
  const allItems = useSelector((state) => state.Item.items);
  const tripItems = Object.values(allItems).filter(
    (item) => item.tripId === tripId
  );
  const locationIds = currentTrip?.children?.filter(
    (itemId) => allItems[itemId]?.type === ITEM_TYPES.DESTINATION
  );

  const locations = tripItems
    .filter((item) => item.type === ITEM_TYPES.DESTINATION)
    .reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

  const thingsToDo = tripItems
    .filter((item) => item.type === ITEM_TYPES.HEADING)
    .reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

  const activities = tripItems.filter(
    (item) => item.type === ITEM_TYPES.ACTIVITY
  );
  const commandBarState = useSelector((state) => state.View.commandBarV2);
  const lastStepSetAt = useSelector((state) => state.View.tour.lastStepSetAt);
  const isExpanded = useSelector(
    (state) => state.View.commandBar.isRecommendationsOpen
  );

  const initialLocationId = locationIds?.length > 0 ? locationIds[0] : null;

  const initialSectionId =
    Object.values(thingsToDo).length > 0
      ? Object.values(thingsToDo)[0].id
      : null;

  const activeLocationId = commandBarState?.activeLocationId || null;
  const activeSectionId = commandBarState?.activeSectionId || null;

  const [clickedButtons, setClickedButtons] = useState({});

  const { getTour, tourOpen } = useTour();

  useEffect(() => {
    recommendedActivitiesRef.current = activitiesList;
  }, [activitiesList]);

  useEffect(() => {
    dispatch(
      actions.View.setCommandBarV2({
        activeLocationId: initialLocationId,
        activeSectionId: initialSectionId,
      })
    );

    return () => {
      if (recommendedActivitiesRef.current.length > 0) {
        dispatch(actions.Recommendations.setActivities([]));
        dispatch(actions.View.setCommandBar({ isRecommendationsOpen: false }));
      }
    };
  }, []);

  const handleButtonClick = (buttonId) => {
    setClickedButtons((prev) => ({ ...prev, [buttonId]: true }));
    if (
      buttonId === 'destinationArrow' ||
      buttonId === 'transportationArrow' ||
      buttonId === 'headingArrow' ||
      buttonId === 'ai'
    ) {
      setTimeout(() => {
        setClickedButtons((prev) => ({ ...prev, [buttonId]: false }));
      }, 200);
    } else {
      setTimeout(() => {
        setClickedButtons((prev) => ({ ...prev, [buttonId]: false }));
      }, 1000);
    }
  };

  const handleClick = () => {
    handleButtonClick('ai');
    phTrackEvent({ event: EVENTS.PLAN_UNIVERESAL_ADD.AI_MENU_OPEN });
    phTrackEvent({ event: EVENTS.PLAN_UNIVERESAL_ADD.AI_MENU_CLICK });
    setAiAnchorEl(aiAnchorEl ? null : aiPopperRef.current);
    dispatch(
      actions.View.setCommandBar({
        isAiMenuOpen: !aiAnchorEl,
      })
    );
  };

  const handleAddItem = (itemType, childType, parentId = tripId) => {
    const getItemIndex = () => {
      if (
        (itemType === ITEM_TYPES.TRANSPORTATION ||
          itemType === ITEM_TYPES.DESTINATION) &&
        parentId === tripId
      ) {
        return (currentTrip?.children || []).indexOf(activeLocationId) + 1 || 0;
      }

      if (itemType === ITEM_TYPES.HEADING) {
        const index =
          (locations[activeLocationId]?.children?.indexOf(activeSectionId) ??
            -1) + 1;

        return index === 0
          ? (locations[activeLocationId]?.children?.length || 0) + 1
          : index;
      }

      if (itemType === ITEM_TYPES.ACTIVITY) {
        return thingsToDo[activeSectionId]?.children?.length || 0;
      }

      return null;
    };

    const index = getItemIndex();
    if (index === null) return;

    let itemContent = {};
    if (itemType === ITEM_TYPES.TRANSPORTATION) {
      const localConnectionId = getLocalItemId(ITEM_TYPES.TRANSPORT);
      dispatch(
        actions.Item.createLocalItem({
          localId: localConnectionId,
          item: {
            id: localConnectionId,
            type: ITEM_TYPES.TRANSPORT,
          },
          ignoreParentUpdate: true,
          parentId: tripId,
          index,
          tripId,
        })
      );
      itemContent = {
        connections: [localConnectionId],
        transportType: childType,
      };
    }
    const item = {
      id: getLocalItemId(itemType),
      tripId,
      type: itemType,
      content: itemContent,
    };

    dispatch(
      actions.Item.createLocalItem({
        localId: item.id,
        parentId,
        item,
        tripId,
        index,
        updateNewItemId: true,
      })
    );
    if (parentId === tripId) {
      dispatch(
        actions.TripsV2.addChildToTrip({ tripId, childId: item.id, index })
      );
    }
  };

  const handleAddAccommodation = () => {
    handleButtonClick('hotel');
    const hotelsList = locations[activeLocationId]?.children?.filter(
      (childId) => allItems[childId]?.type === ITEM_TYPES.ACCOMMODATION
    );
    const lastHotelId =
      hotelsList?.length > 0 ? hotelsList[hotelsList.length - 1] : null;
    dispatch(
      actions.Item.setOpenItemSearchbar({ id: lastHotelId || activeLocationId })
    );
  };

  const handleAddDestination = async () => {
    if (getTour().onActiveTour(tourName.commandBarFlowWeb)) {
      getTour().goToNextStep();
    } else if (getTour().isActiveStep(stepName.ENTER_DESTINATION)) {
      getTour()
        .onActiveStep([stepName.ENTER_DESTINATION])
        .goToNextStep()
        .closeTour();
    } else if (getTour().tourOpen) {
      return;
    }
    try {
      handleAddItem(ITEM_TYPES.DESTINATION);
    } catch (e) {
      // handle error
    }
  };

  const handleAddActivity = () => {
    handleButtonClick('activity');
    handleAddItem(ITEM_TYPES.ACTIVITY, null, activeSectionId);
  };

  const handleAddTransportation = (type) => {
    handleButtonClick('transportation');
    handleAddItem(ITEM_TYPES.TRANSPORTATION, type);
    const index = locationIds?.indexOf(activeLocationId) || 0;
    dispatch(
      actions.View.setCommandBar({
        openTransportationBlade: true,
        transportationBladeType: type,
        transportationBladeIndex: index,
      })
    );
  };

  const handleAddSection = () => {
    handleButtonClick('heading');
    handleAddItem(ITEM_TYPES.HEADING, null, activeLocationId);
  };

  useEffect(() => {
    if (!aiAnchorEl) {
      dispatch(
        actions.View.setCommandBar({
          sessionActive: false,
        })
      );
    }
  }, [aiAnchorEl]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { activeElement } = document;

      if (event.key === 'Escape' && aiAnchorEl) {
        if (activities && activities.length > 0 && isExpanded) {
          dispatch(
            actions.View.setCommandBar({
              isRecommendationsOpen: false,
            })
          );
        } else if (activities && activities.length > 0 && !isExpanded) {
          dispatch(actions.Recommendations.setActivities([]));
          dispatch(
            actions.View.setCommandBar({
              isRecommendationsOpen: true,
            })
          );
          handleButtonClick('ai');
          setAiAnchorEl(null);
          dispatch(
            actions.View.setCommandBar({
              isAiMenuOpen: false,
            })
          );
        }
        // else if (activeAISection === 'askAI') {
        // else if (activeAISection === 'suggestIdeas') {
        //   handleButtonClick('ai');
        //   setAiAnchorEl(null);
        //   dispatch(
        //     actions.View.setCommandBar({
        //       isAiMenuOpen: false,
        //     })
        //   );
        // }
        else if (activeAISection === 'suggestActivities') {
          handleButtonClick('ai');
          setAiAnchorEl(null);
          dispatch(
            actions.View.setCommandBar({
              isAiMenuOpen: false,
            })
          );
          // setActiveAISection('suggestIdeas');
          // setValue('Suggest Ideas ');
          // setValue('');
        }
        // else if (activeAISection === 'suggestIdeas') {
        //   setActiveAISection('askAI');
        //   setValue('');
        // }

        return;
      }

      if (
        activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' ||
        activeElement.isContentEditable
      ) {
        return;
      }

      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
        handleClick();
        event.preventDefault();
        return;
      }

      const shortcutActions = {
        D: handleAddDestination,
        S: handleAddAccommodation,
        A: handleAddActivity,
        T: handleAddTransportation,
        H: handleAddSection,
      };

      const action = shortcutActions[event.key.toUpperCase()];
      if (action) {
        event.preventDefault();
        action();
      }
    };

    if (isKeyboardShortcutEnabled) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (isKeyboardShortcutEnabled) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [
    isKeyboardShortcutEnabled,
    clickedButtons,
    activeLocationId,
    activeSectionId,
    activities,
    isExpanded,
    activeAISection,
  ]);

  const handleClickAway = () => {
    setAnchorEl(null);
    setHeadingAnchorEl(null);
    setTransportationAnchorEl(null);
  };

  return (
    <div>
      <div
        id="command-bar"
        className={classes.mainContainer}
        onClick={() => {
          if (tourOpen) {
            if (Date.now() - lastStepSetAt < 500) {
              return;
            }
            if (
              getTour().tourName === tourName.commandBarFlowWeb &&
              getTour().stepName === stepName.COMMAND_BAR_SELECTORS_INFO
            ) {
              getTour().complete();
            } else {
              setTimeout(() => {
                getTour()
                  .onActiveStep([
                    stepName.COMMAND_BAR_INTRO_STEP,
                    stepName.COMMAND_BAR_SELECTORS_INFO,
                  ])
                  .goToNextStep();
              }, 300);
            }
          }
        }}>
        <Paper
          sx={{
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '6px 0px',
          }}
          elevation={3}>
          <Grid
            container
            spacing={0.5}
            sx={{
              width: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'nowrap',
            }}>
            <DestinationButton
              tripId={tripId}
              activeLocationId={activeLocationId}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              locationIds={locationIds}
              locations={locations}
              thingsToDo={thingsToDo}
              handleClickAway={handleClickAway}
              commonStyles={commonStyles}
              isGlobeClicked={clickedButtons.destination}
              handleAddDestination={handleAddDestination}
              isArrowClicked={clickedButtons.destinationArrow}
              handleArrowClick={() => handleButtonClick('destinationArrow')}
              isKeyboardShortcutEnabled={isKeyboardShortcutEnabled}
            />
            <HeadingButton
              tripId={tripId}
              locations={locations}
              thingsToDo={thingsToDo}
              activeSectionId={activeSectionId}
              handleClickAway={handleClickAway}
              headingAnchorEl={headingAnchorEl}
              setHeadingAnchorEl={setHeadingAnchorEl}
              activeLocationId={activeLocationId}
              commonStyles={commonStyles}
              isHeadingClicked={clickedButtons.heading}
              isArrowClicked={clickedButtons.headingArrow}
              handleAddSection={handleAddSection}
              handleArrowClick={() => handleButtonClick('headingArrow')}
              isKeyboardShortcutEnabled={isKeyboardShortcutEnabled}
            />
            <TransportationButton
              commonStyles={commonStyles}
              transportationAnchorEl={transportationAnchorEl}
              setTransportationAnchorEl={setTransportationAnchorEl}
              handleClickAway={handleClickAway}
              activeLocationId={activeLocationId}
              locationIds={locationIds}
              isTransportationClicked={clickedButtons.transportation}
              isArrowClicked={clickedButtons.transportationArrow}
              handleAddTransportation={handleAddTransportation}
              handleArrowClick={() => handleButtonClick('transportationArrow')}
              isKeyboardShortcutEnabled={isKeyboardShortcutEnabled}
            />
            <HotelButton
              commonStyles={commonStyles}
              isClicked={clickedButtons.hotel}
              handleAddAccommodation={handleAddAccommodation}
              isKeyboardShortcutEnabled={isKeyboardShortcutEnabled}
            />
            <ActivityButton
              tripId={tripId}
              commonStyles={commonStyles}
              isClicked={clickedButtons.activity}
              handleAddActivity={handleAddActivity}
              isKeyboardShortcutEnabled={isKeyboardShortcutEnabled}
            />
            {isAIButtonEnabled && (
              <>
                <Grid item style={{ paddingLeft: 0 }}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ width: '1px', height: '30px', paddingLeft: 0 }}
                  />
                </Grid>
                <AiButton
                  aiPopperRef={aiPopperRef}
                  commonStyles={commonStyles}
                  isClicked={clickedButtons.ai}
                  handleButtonClick={() => handleButtonClick('ai')}
                  tripId={tripId}
                  activeLocationId={activeLocationId}
                  locations={locations}
                  locationIds={locationIds}
                  aiAnchorEl={aiAnchorEl}
                  isKeyboardShortcutEnabled={isKeyboardShortcutEnabled}
                  activeSection={activeAISection}
                  setActiveSection={setActiveAISection}
                  handleClick={handleClick}
                  value={value}
                  setValue={setValue}
                  setAiAnchorEl={setAiAnchorEl}
                />
              </>
            )}
          </Grid>
        </Paper>
      </div>
    </div>
  );
}

export default CommandBarV2;
