const typography = {
  fontFamily: 'Futura',
  // UI main font Inter
  h1: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '140%',
    fontFamily: 'Futura',
  },
  h2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '140%',
    fontFamily: 'Futura',
  },
  h3: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '140%',
    fontFamily: 'Futura',
  },
  h4: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '140%',
    fontFamily: 'Futura',
  },
  h5: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
    fontFamily: 'Futura',
  },

  // UI sub font Work Sans
  h2Sub: {
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: '-0.03em',
    fontFamily: 'Canela',
  },
  h3Sub: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '140%',
    letterSpacing: '-0.03em',
    fontFamily: 'Canela',
  },
  h4Sub: {
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: '-0.03em',
    fontFamily: 'Canela',
  },
  h5Sub: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '-0.03em',
    fontFamily: 'Canela',
  },
  h1Growth: {
    fontWeight: 400,
    fontSize: 32,
    fontFamily: 'Canela',
    lineHeight: '120%',
  },

  // CTA Button title
  ctaBig: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '140%',
    fontFamily: 'Futura',
  },
  ctaSmall: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '140%',
    fontFamily: 'Futura',
  },
  caption: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '140%',
    fontFamily: 'Futura',
  },
};

export default typography;
