import { useSelector } from 'react-redux';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Collapse } from '@mui/material';
import { useParams } from 'react-router-dom';
import ITEM_TYPES, {
  ITEM_DRAG_LEVELS,
  ITEM_DROP_LEVELS,
} from '../../../../const';
import Accommodation from './Accommodation';
import Activity from './Activity';
import Destination from './Destination';
import Heading from './Heading';
import Transportation from './Transportation';
import AddItemButton from '../../molecules/AddItemButton';
import { isLocalItem } from '../../../../utils';
import EmptyActivity from '../Activity/EmptyActivity';
import EmptyHeading from '../Heading/EmptyHeading';
import Spacer from '../../molecules/Spacer';
import AccommodationSearchBar from '../Accommodation/SearchBar';
import AccommodationAddModule from '../Accommodation/AddModule';
// import DraggableDnd from '../organisms/DragAndDrop/Draggable';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  spacing: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 15px)',
    // marginRight: 12,
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 24px)',
      // marginRight: 12,
    },
  },
  isDragging: {
    padding: '12px 8px',
    border: `2px solid ${palette.primary.light}`,
    borderRadius: 8,
    marginLeft: 'calc(3.6rem + 15px)',
    backgroundColor: '#FFF',
    boxShadow: '2px 2px 15px 0px rgba(0, 0, 0, 0.1)',
  },
}));

function ItemAdders({
  itemObj,
  parentId,
  index,
  nextItemId,
  isSectionHighlight,
}) {
  const { slug: tripId } = useParams();
  const nextItemType = useSelector(
    (state) => state.Item.items[nextItemId]?.type
  );
  const classes = useStyles();

  return (
    <div>
      {itemObj?.type === ITEM_TYPES.HEADING &&
      itemObj?.children?.length !== 0 &&
      !isLocalItem(itemObj?.id, itemObj?.type) ? (
        <div className={isSectionHighlight ? '' : classes.spacing}>
          <AddItemButton
            type={ITEM_TYPES.ACTIVITY}
            parentId={itemObj?.id}
            index={itemObj?.children?.length || 0}
            tripId={tripId}
          />
          <AddItemButton
            type={ITEM_TYPES.HEADING}
            parentId={parentId}
            index={index + 1}
            tripId={tripId}
          />
        </div>
      ) : null}
      {itemObj?.type === ITEM_TYPES.ACCOMMODATION &&
        nextItemType !== ITEM_TYPES.ACCOMMODATION &&
        !isLocalItem(itemObj?.id, itemObj?.type) && (
          <div className={isSectionHighlight ? '' : classes.spacing}>
            <AddItemButton
              type={ITEM_TYPES.ACCOMMODATION}
              parentId={itemObj?.id}
              index={itemObj?.children?.length || 0}
              tripId={tripId}
              itemId={itemObj?.id}
            />
          </div>
        )}
    </div>
  );
}

function Item({
  itemId,
  parentId,
  index,
  prevItemId,
  nextItemId,
  isCommandBarEnabled,
  isParentDragging,
}) {
  const classes = useStyles();
  const { slug: tripId } = useParams();
  const itemObj = useSelector((state) => state.Item.items[itemId]);
  const items = useSelector((state) => state.Item.items);
  const isSectionHighlight = useSelector(
    (state) => state.View.highlightedSection
  );
  const openAccommodationSearchbar = useSelector(
    (state) => state.Item.openItemSearchbar === itemId
  );

  const openAccommodationSearchbarIndex = useSelector(
    (state) => state.Item.openItemSearchbarIndex
  );

  const doesItemHaveChildType = (childType) => {
    return itemObj?.children?.some((child) => items[child]?.type === childType);
  };

  // for destination collapsible
  const [isCollapsed, setCollapsed] = useState(false);

  if (!itemObj || itemObj?.tempRemove) {
    return null;
  }

  const ITEM_MAP = {
    [ITEM_TYPES.ACCOMMODATION]: Accommodation,
    [ITEM_TYPES.ACTIVITY]: Activity,
    [ITEM_TYPES.DESTINATION]: Destination,
    [ITEM_TYPES.TRANSPORTATION]: Transportation,
    [ITEM_TYPES.HEADING]: Heading,
  };

  const ItemComponent = ITEM_MAP[itemObj?.type] || Activity;
  const isEmptyChildren =
    !itemObj?.children ||
    itemObj?.children?.length === 0 ||
    itemObj?.children?.every((child) => items[child]?.tempRemove);
  const hasNoChildrenOfType = (type) => {
    return (
      !itemObj?.children ||
      itemObj?.children
        ?.map((child) => items[child]?.type === type)
        .filter(Boolean)?.length === 0
    );
  };

  const isAncestorHighlighted = (itemID, highlightedId) => {
    const parent = items[highlightedId];

    if (!parent) return false;
    if (parent.id === itemID) return true;
    if (parent.children?.includes(itemID)) {
      return true;
    }

    return (
      parent.children?.some((childID) =>
        isAncestorHighlighted(itemID, childID)
      ) || false
    );
  };

  return (
    <div
      style={{
        marginBottom: 0,
      }}>
      {/* <DraggableDnd draggableId={itemId} type="ITEM" index={index}> */}
      <Draggable
        draggableId={`ITEM-${itemId}`}
        index={index}
        type={ITEM_DRAG_LEVELS[itemObj?.type]}>
        {(draggableProvided, draggableSnapshot) => {
          return (
            <div>
              <div
                className={
                  draggableSnapshot.isDragging || isSectionHighlight === itemId
                    ? ITEM_DROP_LEVELS[itemObj?.type]
                      ? classes.isDragging
                      : null
                    : ''
                }
                {...draggableProvided.draggableProps}
                ref={draggableProvided.innerRef}>
                <ItemComponent
                  itemId={itemId}
                  parentId={parentId}
                  item={itemObj}
                  index={index}
                  isDragging={draggableSnapshot.isDragging}
                  isCollapsed={isCollapsed}
                  setCollapsed={setCollapsed}
                  dragHandleProps={draggableProvided.dragHandleProps}
                  isCommandBarEnabled={isCommandBarEnabled}
                  isParentDragging={isParentDragging}
                  isSectionHighlight={isAncestorHighlighted(
                    itemId,
                    isSectionHighlight
                  )}
                />
                {ITEM_DROP_LEVELS[itemObj?.type] && (
                  <Droppable
                    direction="vertical"
                    ignoreContainerClipping
                    type={ITEM_DROP_LEVELS[itemObj?.type]}
                    droppableId={`DROPPABLE-ITEM-${itemId}`}
                    isDropDisabled={false}>
                    {(droppableProvided, droppableSnapshot) => {
                      return (
                        <Collapse
                          in={!isCollapsed}
                          timeout={0}
                          sx={{
                            flex: '1 1 0',
                          }}>
                          <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}>
                            <div id={`scrollable-container-${itemId}`}>
                              {openAccommodationSearchbar &&
                                !openAccommodationSearchbarIndex && (
                                  <div
                                    style={{ marginTop: 8, marginBottom: 8 }}>
                                    <AccommodationSearchBar
                                      parentId={itemId}
                                      tripId={tripId}
                                      index={0}
                                    />
                                  </div>
                                )}
                              {!openAccommodationSearchbar &&
                                itemObj?.type === ITEM_TYPES.DESTINATION &&
                                !isLocalItem(itemObj?.id, itemObj?.type) &&
                                !doesItemHaveChildType(
                                  ITEM_TYPES.ACCOMMODATION
                                ) && (
                                  <AccommodationAddModule
                                    parentId={itemId}
                                    fullWidth={
                                      draggableSnapshot.isDragging ||
                                      isParentDragging ||
                                      isSectionHighlight === itemId
                                    }
                                  />
                                )}
                              {itemObj?.children?.map((childId, idx) => (
                                <div key={`container-${childId}`}>
                                  <Spacer
                                    itemId={childId}
                                    prevItemId={
                                      idx === 0
                                        ? itemObj?.id
                                        : itemObj?.children[idx - 1]
                                    }
                                  />

                                  {openAccommodationSearchbar &&
                                    openAccommodationSearchbarIndex === idx && (
                                      <div
                                        style={{
                                          marginTop: 8,
                                          marginBottom: 8,
                                        }}>
                                        <AccommodationSearchBar
                                          parentId={itemId}
                                          tripId={tripId}
                                          index={
                                            openAccommodationSearchbarIndex || 0
                                          }
                                        />
                                      </div>
                                    )}

                                  <Item
                                    key={childId}
                                    itemId={childId}
                                    parentId={itemId}
                                    index={idx}
                                    isDraggingOver={
                                      droppableSnapshot.isDraggingOver
                                    }
                                    prevItemId={
                                      idx === 0
                                        ? itemObj?.id
                                        : itemObj?.children[idx - 1]
                                    }
                                    nextItemId={
                                      idx ===
                                      (itemObj?.children || []).length - 1
                                        ? null
                                        : itemObj?.children[idx + 1]
                                    }
                                    isCommandBarEnabled={isCommandBarEnabled}
                                    isParentDragging={
                                      draggableSnapshot.isDragging ||
                                      isParentDragging
                                    }
                                  />
                                </div>
                              ))}

                              {openAccommodationSearchbar &&
                                openAccommodationSearchbarIndex ===
                                  itemObj?.children?.length && (
                                  <div
                                    style={{
                                      marginTop: 8,
                                      marginBottom: 8,
                                    }}>
                                    <AccommodationSearchBar
                                      parentId={itemId}
                                      tripId={tripId}
                                      index={
                                        openAccommodationSearchbarIndex || 0
                                      }
                                    />
                                  </div>
                                )}
                            </div>
                            {droppableProvided.placeholder}
                          </div>
                        </Collapse>
                      );
                    }}
                  </Droppable>
                )}

                {!(
                  draggableSnapshot.isDragging ||
                  isParentDragging ||
                  isSectionHighlight
                ) &&
                  itemObj?.type === ITEM_TYPES.HEADING &&
                  !isLocalItem(itemObj?.id, itemObj?.type) &&
                  isEmptyChildren && (
                    <div style={{ marginTop: 8 }}>
                      <EmptyActivity parentId={itemId} />
                    </div>
                  )}
                {!(draggableSnapshot.isDragging || isParentDragging) && (
                  <ItemAdders
                    parentId={parentId}
                    itemObj={itemObj}
                    index={index}
                    prevItemId={prevItemId}
                    nextItemId={nextItemId}
                    isSectionHighlight={isAncestorHighlighted(
                      itemId,
                      isSectionHighlight
                    )}
                  />
                )}

                {!(draggableSnapshot.isDragging || isParentDragging) &&
                  itemObj?.type === ITEM_TYPES.DESTINATION &&
                  !isLocalItem(itemObj?.id, itemObj?.type) &&
                  !(itemObj?.id === isSectionHighlight) &&
                  hasNoChildrenOfType(ITEM_TYPES.HEADING) && (
                    <EmptyHeading parentId={itemId} />
                  )}
              </div>
              {draggableProvided.placeholder}
            </div>
          );
        }}
      </Draggable>
    </div>
  );
}

export default Item;
