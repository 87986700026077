import { useDispatch, useSelector } from 'react-redux';
import useTransporationsCreate from '../../organisms/Transportation/useTransporationsCreate';
import {
  updateImportAttachedTo,
  updateFile as updateFileV2,
  attachFiletoItems,
} from '../../../../redux/slices/FilesV2';
import actions from '../../../../redux/actions';
import { createItem, updateItem } from '../../../../redux/slices/Item';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { useMapUtils } from '../../organisms/Map';
import ITEM_TYPES, { COST_TYPES } from '../../../../const';
import { extractKeysFromObject } from '../../../../utils';

const useSmartImportAddToTrip = ({ tripId }) => {
  const dispatch = useDispatch();

  const {
    getPlaceDetails,
    createMapPinForPlace,
    createMapPinForPlaceId,
    extractAddressComponents,
  } = useMapUtils();

  const getMapPinForActivity = async ({ placeId }) => {
    if (placeId) {
      try {
        const {
          title,
          photo,
          rating,
          website,
          maps,
          ratingCount,
          long,
          lat,
          types,
          categoryId,
          openingHoursObj,
          addressComponents,
          formattedAddress,
          formattedPhoneNumber,
        } = await getPlaceDetails(placeId, 'ACTIVITY');
        const address = extractAddressComponents(addressComponents);
        const mapPin = await createMapPinForPlace(
          {
            title,
            photo,
            rating,
            website,
            maps,
            ratingCount,
            long,
            lat,
            types,
            placeId,
            categoryId,
          },
          'ACTIVITY',
          {
            openingHoursObj,
            addressComponents,
            formattedAddress,
            formattedPhoneNumber,
            maps,
            website,
          }
        );
        return {
          mapPin,
          address,
        };
      } catch (error) {
        return {
          mapPin: null,
          address: null,
        };
      }
    }
    return {
      mapPin: null,
      address: null,
    };
  };
  const getMapPinForAccommodation = async ({ placeId }) => {
    if (placeId) {
      const mapPinDetails = await createMapPinForPlaceId(
        placeId,
        'ACCOMMODATION'
      );
      return mapPinDetails;
    }
    return null;
  };

  const tripItems = useSelector((state) => state.Item?.items);

  const { handleCreateTransportation } = useTransporationsCreate({
    tripId,
  });

  const attachFileToItem = async ({ fileId, itemId }) => {
    await dispatch(
      attachFiletoItems({
        variables: {
          id: fileId,
          addToItems: [itemId],
          removeFromItems: [],
        },
      })
    );
    await dispatch(
      updateItem({
        variables: {
          id: itemId,
          files: [fileId],
        },
      })
    );
  };

  const attachToItem = async ({
    itemId,
    importId,
    itemIndex,
    fileId,
    fileIndex,
    isForwardedFile,
  }) => {
    await dispatch(
      updateImportAttachedTo({
        variables: { importId, itemIndex, attachedToItem: itemId },
      })
    );
    attachFileToItem({
      fileId,
      itemId,
    });
    if (isForwardedFile) {
      dispatch(
        actions.Auth.updateForwardedFileImportItemAttachedToItemId({
          index: fileIndex,
          fileId,
          itemIndex,
          attachedToItemId: itemId,
        })
      );
      await dispatch(
        updateFileV2({
          variables: {
            id: fileId,
            file: {
              attachedToId: itemId ? tripId : '',
              tripId,
              attachedToType: itemId ? 'Trip' : '',
            },
          },
        })
      );
    }
  };

  const onAddToTrip = async (
    item,
    {
      locationId = null,
      sectionId = null,
      fileId,
      fileIndex,
      isForwardedFile,
      placeId,
      importId,
      itemIndex,
      dropIndex,
    } = {}
  ) => {
    if (item?.type === 'flight') {
      // create flights for each stop created

      const { connectionIds } = await handleCreateTransportation(
        item.details.map((flight) => ({
          flight: {
            // ...flight,
            index: dropIndex || 0,
            content: {
              // ...flight,
              transportType: 'flight',
              expense: {
                amount: flight?.cost,
                currency: flight?.currency,
                costType: COST_TYPES[flight.costPer],
              },
              ...extractKeysFromObject(flight, [
                'flightNumber',
                'fromAirport',
                'toAirport',
              ]),
              description: flight?.notes,
              startDate: flight?.fromDate,
              endDate: flight?.toDate,
            },
            parentId: tripId,
          },
          additionalProps: {
            files: [fileId],
            attachedToType: 'Transportation',
            tripId,
            type: 'flight',
            index: dropIndex || 0,
            parentId: tripId,
          },
        })),
        tripId,
        dropIndex || 0,
        'flight'
      );
      const element = document.getElementById(
        `scrollable-container-${locationId}`
      );
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      await attachToItem({
        itemId: connectionIds[0],
        importId,
        itemIndex,
        fileId,
        fileIndex,
        isForwardedFile,
        placeId,
      });
    }

    if (['bus', 'train', 'other'].includes(item?.type)) {
      const { connectionIds } = await handleCreateTransportation(
        item.details.map((flight) => ({
          flight: {
            // ...flight,
            index: dropIndex || 0,
            content: {
              // ...flight,
              transportType: item?.type,
              expense: {
                amount: flight?.cost,
                currency: flight?.currency,
                costType: COST_TYPES[flight.costPer],
              },
              ...extractKeysFromObject(flight, [
                'flightNumber',
                'fromAirport',
                'toAirport',
              ]),
              description: flight?.notes,
              startDate: flight?.fromDate,
              endDate: flight?.toDate,
            },
            parentId: tripId,
          },
          additionalProps: {
            files: [fileId],
            attachedToType: 'Transportation',
            tripId,
            type: item?.type,
            index: dropIndex || 0,
            parentId: tripId,
          },
        })),
        tripId,
        dropIndex || 0,
        item?.type
      );
      await attachToItem({
        itemId: connectionIds[0],
        importId,
        itemIndex,
        fileId,
        fileIndex,
        isForwardedFile,
        placeId,
      });
    }

    if (item?.type === 'activity') {
      const index =
        !Number.isNaN(dropIndex) && dropIndex >= 0
          ? dropIndex
          : tripItems[sectionId]?.children?.length || 0;
      try {
        const newActivity = {
          ...item,
          thingsToDoId: sectionId,
          index:
            !Number.isNaN(dropIndex) && dropIndex >= 0
              ? dropIndex
              : tripItems[sectionId]?.children?.length || 0,
          files: [fileId],
        };
        const { mapPin } = await getMapPinForActivity({
          placeId,
        });
        if (mapPin) {
          newActivity.mapPin = mapPin.id;
        }
        const activityVariables = {
          type: ITEM_TYPES.ACTIVITY,
          ...extractKeysFromObject(newActivity, ['title']),
          content: {
            expense: {
              amount: item?.cost,
              currency: item?.currency,
              costType: COST_TYPES[item?.costPer],
            },
            startDate: item?.activityTime,
            description: `${item?.description || ''}\n${item?.notes || ''}`,
            address: extractKeysFromObject(newActivity, [
              'country',
              'city',
              'streetAddress',
              'zipCode',
              'state',
            ]),
          },
          tripId,
          children: [],
          index,
          parentId: sectionId,
        };
        const activity = await dispatch(
          createItem({
            variables: activityVariables,
            shouldAppendItem: true,
            tripId,
            parentId: sectionId,
            index,
          })
        ).unwrap();
        await attachToItem({
          itemId: activity?.createItem?.id,
          fileId,
          fileIndex,
          isForwardedFile,
          placeId,
          importId,
          itemIndex,
        });
        const element = document.getElementById(`todo-header-${sectionId}`);
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } catch (error) {
        console.log('error', error);
      }
    }
    if (item?.type === 'accommodation') {
      const mapPinDetails = await getMapPinForAccommodation({
        placeId,
      });

      try {
        const accommodation = await dispatch(
          createItem({
            variables: {
              type: ITEM_TYPES.ACCOMMODATION,
              tripId,
              index: dropIndex || 0,
              title: item?.name,
              content: {
                description: item?.notes || '',
                expense: {
                  costType: COST_TYPES[item?.costPer],
                  amount: item?.cost,
                  currency: item?.currency,
                },
                address: extractKeysFromObject(item, [
                  'country',
                  'city',
                  'streetAddress',
                  'zipCode',
                  'state',
                ]),
                checkInDate: item?.checkInDate,
                checkOutDate: item?.checkOutDate,
              },
              mapPin: mapPinDetails?.id,
              parentId: locationId,
            },
            tripId,
            parentId: locationId,
            index: dropIndex || 0,
          })
        ).unwrap();
        await attachToItem({
          itemId: accommodation?.createItem?.id,
          fileId,
          fileIndex,
          importId,
          itemIndex,
          isForwardedFile,
          placeId,
        });

        const element = document.getElementById(
          `scrollable-container-${locationId}`
        );
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } catch (error) {
        console.log('error', error);
      }
    }

    // once the item is added to the trip, change the tab
    if (isForwardedFile) {
      dispatch(actions.View.setForwardedFilesModalTabValue(1));
    }
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_HISTORY_BLADE_ADD,
    });
  };

  return { onAddToTrip };
};

export default useSmartImportAddToTrip;
