import { DialogTitle, Box, IconButton, Typography, Modal } from '@mui/material';
import {
  Close as CloseIcon,
  HotelRounded,
  InfoRounded,
  PersonRounded,
} from '@mui/icons-material';
import CustomCarousel from '../../../../molecules/Carousel';
import { OpenInFullIcon } from '../../../../atoms/Icon';

function RoomDetailsModal({ open, onClose, room }) {
  const descriptionProperties = [
    {
      name: 'Room size',
      value: `${room?.roomSizeSquare} ${
        room?.roomSizeUnit === 'm2' ? 'm<sup>2</sup>' : room?.roomSizeUnit
      }`,
      icon: <OpenInFullIcon />,
      alignItems: 'center',
    },
    {
      name: 'Occupancy',
      value: `Sleeps ${room?.maxOccupancy}`,
      icon: <PersonRounded sx={{ height: 16, width: 16 }} />,
    },
    {
      name: 'Bed type',
      value: room?.bedTypes
        ?.map((bed) => `${bed?.quantity} ${bed?.bedType}`)
        .join(', '),
      icon: <HotelRounded sx={{ height: 16, width: 16 }} />,
    },
  ];
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box
        sx={{
          position: 'relative',
          px: 6,
          py: 6,
          height: '552px',
          backgroundColor: '#FFF',
          width: '670px',
          overflow: 'auto',
          outline: 'none',
          borderRadius: 2,
        }}>
        <DialogTitle sx={{ p: 0, mb: 2, fontWeight: 600, fontSize: 20 }}>
          {room?.roomName}
        </DialogTitle>
        <IconButton
          onClick={(e) => {
            e?.stopPropagation();
            onClose();
          }}
          sx={{ position: 'absolute', right: 16, top: 16 }}>
          <CloseIcon />
        </IconButton>

        <CustomCarousel
          showArrows
          height="242px"
          images={room?.photos?.map((img) => ({ small: img?.url }))}
          sx={{
            objectFit: 'cover',
            width: '100%',
            borderRadius: 8,
            background: 'linear-gradient(180deg, #000000 0%, #000000 20%)',
            height: '242px',
          }}
        />

        <Typography variant="h4" mt={4} fontWeight={600}>
          Room Details
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 24,
            rowGap: 2,
            marginTop: 4,
            flexWrap: 'wrap',
          }}>
          {descriptionProperties?.map(({ value, icon, alignItems }) => (
            <div
              style={{
                display: 'flex',
                alignItems: alignItems || 'flex-start',
              }}>
              {icon}
              <Typography
                variant="h5"
                noWrap
                component="span"
                ml={1}
                dangerouslySetInnerHTML={{ __html: value }}
              />
            </div>
          ))}
        </div>
        <Typography variant="h5" marginTop={1}>
          {room?.description}
        </Typography>
        <Typography variant="h4" mt={4} fontWeight={600}>
          Amenities
        </Typography>

        <Box
          display="flex"
          mt={1}
          flexWrap="wrap"
          gap={1}
          alignItems="flex-start"
          sx={{
            width: '100%',
          }}>
          {room?.roomAmenities?.map((property) => (
            <Typography
              variant="caption"
              color="#222"
              fontSize={14}
              alignItems="center"
              display="flex"
              width="calc(33% - 8px)">
              <InfoRounded
                sx={{
                  height: 12,
                  width: 12,
                  marginRight: 1,
                  color: '#344054',
                }}
              />{' '}
              {property?.name}
            </Typography>
          ))}
        </Box>
      </Box>
    </Modal>
  );
}

export default RoomDetailsModal;
