import { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';

import { useDispatch } from 'react-redux';

import {
  DeleteOutlineRounded,
  DragIndicatorRounded,
} from '@mui/icons-material';

import { Draggable, Droppable } from '@hello-pangea/dnd';

import TransportationBlockImport from './ItineraryComponents/TransportationBlockImport';
import AccommodationBlockImport from './ItineraryComponents/AccommodationBlockImport';
import TodoItemImport from './ItineraryComponents/TodoItemImport';

// import { , updateFile } from '../../../../redux/slices/Files';
import { deleteImportItem } from '../../../../redux/slices/FilesV2';

import { EVENTS, phTrackEvent } from '../../../../analytics';
import { ITEM_DRAG_LEVELS } from '../../../../const';
import { ViewActions } from '../../../../redux/slices/View';

function SmartImportV2({
  fileId,
  item: initialItem,
  itemIndex,
  importId,
  width = '300px',
  flexDirection,
  isForwardedFile,
  isModalView,
  fileIndex,
}) {
  const itemId = `${importId}-${itemIndex}`;

  const dispatch = useDispatch();
  const [openBlade, setOpenBlade] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const [item, setItem] = useState(initialItem);

  useEffect(() => {
    dispatch(
      ViewActions.setImportItemsTransformed({
        ...item,
        id: itemId,
        additionalProps: {
          fileId,
          fileIndex,
          isForwardedFile,
          itemIndex,
          importId,
        },
      })
    );
  }, [item]);

  const levelMap = {
    Accommodation: ITEM_DRAG_LEVELS.Accommodation,
    Todo: ITEM_DRAG_LEVELS.Activity,
    Flight: ITEM_DRAG_LEVELS.Transportation,
    Bus: ITEM_DRAG_LEVELS.Transportation,
    Train: ITEM_DRAG_LEVELS.Transportation,
    Other: ITEM_DRAG_LEVELS.Transportation,
    Transportation: ITEM_DRAG_LEVELS.Transportation,
  };

  return (
    <Grid
      width="100%"
      marginLeft="-15px"
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
      container
      direction={flexDirection}
      position="relative"
      gap={isModalView && openBlade ? 2 : 0}>
      <Stack>
        <Droppable
          droppableId={`SI--${itemId}`}
          type={levelMap[item?.__typename] || ITEM_DRAG_LEVELS.Transportation}
          isDropDisabled>
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}>
              <Draggable
                draggableId={`si-activity-${itemId}`}
                index={itemIndex}>
                {(provided, snapshot) => (
                  <Grid item xs={12} display="flex">
                    <Stack
                      visibility={
                        snapshot.isDragging
                          ? 'hidden'
                          : showDeleteIcon
                          ? 'visible'
                          : 'hidden'
                      }
                      pb={item?.__typename !== 'Todo' ? '12px' : '0px'}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={0.2}>
                      <span>
                        <DeleteOutlineRounded
                          sx={{
                            fontSize: '1.2rem',

                            cursor: 'pointer',
                            color: '#8A8A8A',
                            '&:hover': {
                              cursor: 'pointer',
                              color: '#474747',
                            },
                          }}
                          onClick={() => {
                            phTrackEvent({
                              event:
                                EVENTS.SMART_IMPORT.PLAN_IMPORT_BLADE_DELETE,
                            });
                            dispatch(
                              deleteImportItem({
                                variables: { importId, itemIndex },
                              })
                            );
                          }}
                        />
                      </span>
                      <span
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <DragIndicatorRounded
                          sx={{
                            color: 'rgba(138, 138, 138, 1)',
                            fontSize: '1.2rem',
                          }}
                        />
                      </span>
                    </Stack>
                    <Box
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      sx={{
                        p: isModalView ? 0 : 2,
                        pl: {
                          xs: 0,
                          sm: 1,
                        },
                        width: '100%',
                        minWidth: {
                          xs: '100%',
                          sm: width,
                        },
                      }}>
                      {/* <Heading {...headingProps} /> */}

                      {item?.__typename === 'Accommodation' ? (
                        <AccommodationBlockImport
                          item={item}
                          setItem={setItem}
                          openBlade={openBlade}
                          setOpenBlade={setOpenBlade}
                        />
                      ) : item?.__typename === 'Todo' ? (
                        <TodoItemImport
                          todo={item}
                          setItem={setItem}
                          openBlade={openBlade}
                          setOpenBlade={setOpenBlade}
                        />
                      ) : (
                        <TransportationBlockImport
                          item={item}
                          setItem={setItem}
                          openBlade={openBlade}
                          setOpenBlade={setOpenBlade}
                        />
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: 2,
                        }}
                      />
                    </Box>
                  </Grid>
                )}
              </Draggable>
            </div>
          )}
        </Droppable>
        {/* <Stack width="100%" alignItems="flex-end">
          {attachedItemExists() && !showAsAddedonAdded ? (
            <Chip
              size="small"
              label={getAttachedItemName()}
              className={classes.chip}
              variant="outlined"
              onDelete={detachItem}
              deleteIcon={<Close />}
            />
          ) : null}
        </Stack> */}
      </Stack>
    </Grid>
  );
}

export default SmartImportV2;
