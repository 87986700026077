import React from 'react';
import { Typography, Box } from '@mui/material';
import { ChevronRightRounded, Facebook, Instagram } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import COPIES from './copies.json';
import { Quill, ChatBubble, Youtube, Spotify } from '../../../atoms/Icon';

const useStyles = makeStyles(({ palette }) => ({
  actionContainer: {
    margin: '8px 0',
    border: '1px solid',
    borderColor: palette.system.grey,
    padding: '12px 16px',
    color: 'text.main',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 2,
    '&:hover': {
      color: palette.primary.main,
    },
  },
  iconContainer: {
    color: palette.primary.main,
    alignItems: 'center',
    display: 'flex',
    marginRight: 4,
  },
  socialLinksContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialIconContainer: {
    color: palette.text.secondary,
    cursor: 'pointer',
    margin: '8px',
  },
}));

function SocialComponent({ boxStyles, titleProps }) {
  const classes = useStyles();
  const supportTypes = [
    {
      title: 'Give feedback',
      link: 'https://www.pilotplans.com/roadmap',
      Icon: ChatBubble,
    },
    {
      title: 'Write a review',
      link: 'https://www.trustpilot.com/evaluate/pilotplans.com',
      Icon: Quill,
    },
  ];
  const socailLinks = [
    {
      Icon: <Facebook sx={{ height: 28, width: 28 }} />,
      link: 'https://www.facebook.com/pilotplans',
    },
    {
      Icon: <Instagram sx={{ height: 28, width: 28 }} />,
      link: 'https://www.instagram.com/pilotplans/',
    },
    {
      Icon: <Youtube />,
      link: 'https://www.youtube.com/@pilot_plans',
    },
    {
      Icon: <Spotify />,
      link: 'https://open.spotify.com/user/8n79c8y5r6xuqajy7fing7od7',
    },
  ];
  return (
    <div>
      <Typography variant="h3" marginTop="16px" {...titleProps}>
        {COPIES.OTHER_SUPPORT.title}
      </Typography>
      <div>
        {supportTypes.map((type) => (
          <Box
            onClick={() => window.open(type.link, '_blank')}
            className={classes.actionContainer}
            sx={boxStyles}>
            <Box className={classes.iconContainer}>
              <type.Icon />
            </Box>
            {type.title}
            <ChevronRightRounded
              sx={{
                marginLeft: 'auto',
                color: 'text.secondary',
              }}
            />
          </Box>
        ))}
      </div>
      <div style={{ marginTop: 24 }}>
        <Typography variant="h5">{COPIES.OTHER_SUPPORT.socialCta}</Typography>
        <div className={classes.socialLinksContainer}>
          {socailLinks.map(({ Icon, link }) => (
            <div
              onClick={() => window.open(link, '_blank')}
              className={classes.socialIconContainer}>
              {Icon}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SocialComponent;
