import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  ClickAwayListener,
  Box,
} from '@mui/material';
import { HomeOutlined, OutlinedFlagRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import MapboxMap, {
  NavigationControl,
  Marker,
  Popup,
  Source,
  Layer,
} from 'react-map-gl';
// eslint-disable-next-line
import mapboxgl from '!mapbox-gl';
import MapPopupCardNew from '../../molecules/Card/MapPopupCard/MapPopupCardNew';
import { useMapUtils } from './useMapUtils';
import config from '../../../config';
import AutoCompleteSearchBar from '../../molecules/AutoCompleteSearchBar';
import {
  PIN_ICONS,
  TRAVEL_MODES,
  convertSecondsToFormatedDuration,
  convertMetresToKms,
  debounce,
} from '../../../../utils';
import {
  DefaultMapMarker,
  CustomMarker,
  DirectionsRight,
  Route,
  DirectionMarker,
  OriginDirectionMarker,
  DestinationDirectionMarker,
} from '../../../atoms/Icon';
import {
  BasicButton,
  SecondaryOutlinedButton,
} from '../../../atoms/Button/index';
import FLAGS from '../../../../featureFlags';
import actions from '../../../../redux/actions';
import DirectionsModal from '../../molecules/DirectionsModal';
import HotelDetailsCard from '../Stays/HotelDetailsCard';
import PoisCardPopup from '../../molecules/Card/PoisCardPopup';
import { useSounds, SOUNDS } from '../../../../sounds';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { useTour } from '../../../molecules/Tour/useTour';
import { stepName } from '../../../../assets/onboarding/steps';
import ITEM_TYPES, { MAPPINS_TYPES } from '../../../../const';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    '& .mapboxgl-marker': {
      '& svg:not(.customMarker)': {
        display: 'none',
      },
    },
  },
  markerCaption: (isDefaultMarker) => ({
    zIndex: 4,
    '& > .mapboxgl-popup-tip': {
      border: 0,
    },
    '& > .mapboxgl-popup-content': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      padding: 0,
      marginBottom: isDefaultMarker ? 45 : 32,
    },
  }),
  mapPopUpCard: {
    '& > .mapboxgl-popup-content': {
      padding: 0,
      width: 280,
      marginBottom: 0,
      borderRadius: '8px',
    },
  },
  mapElementWrapper: {
    position: 'absolute',
    zIndex: 800,
    top: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItem: {
    fontSize: 12,
    color: '#1877F2',
    fontWeight: 600,
    padding: '4px 8px',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(24, 119, 242, 0.2)',
      color: '#1877F2',
    },
    '& > .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: 4,
    },
  },
  routesButtonContainer: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      // place in between the navigation container and the centered searchbar
      left: 'calc(100% - calc(100% - calc(50% + 200px))/2 - 70px) ',
      height: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 8,
    },
  },
  toggleButtonsContainer: {
    position: 'absolute',
    zIndex: 800,
    top: 45,
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'left',
    left: 'calc(100% / 2 - 220px)',
  },
  activeHotelPin: {
    zIndex: 1,
  },
  hotelChip: {
    border: '2px solid #D9D9D9',
    cursor: 'pointer',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    padding: '4px 12px',
    fontSize: '12px',
    fontFamily: 'Inter',
    color: '#4E4E4E',
    zIndex: 4,
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    transition: 'all 0.1s linear',
    '&:hover': {
      border: '2px solid #ED702E',
      cursor: 'pointer',
      backgroundColor: '#FFF',
    },
  },
  activeHotelChip: {
    border: '2px solid #ED702E',
    cursor: 'pointer',
    backgroundColor: '#FFF1E0 !important',
    color: '#ED702E !important',
  },
  hoveredHotelChip: {
    border: '2px solid #ED702E',
    cursor: 'pointer',
    backgroundColor: '#ED702E',
    color: '#FFF',
  },
}));

export function CategoryMarker({ categoryId, color, ...props }) {
  return <CustomMarker icon={categoryId} fill={color} {...props} />;
}

function MapMarker({
  pin,
  pinData,
  isActivePin,
  handlePinClick,
  handleAddPinToDirections = () => {},
  isDirectionPin = false,
  fill = '#ED702E',
  isDestination = false,
  isOrigin = false,
  isCommandBarEnabled = false,
}) {
  const classes = useStyles(!isDirectionPin);
  const hoveredPin = useSelector((state) => state.MapV2.hoveredPin);
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const isExploreOpen = useSelector((state) => state.View.isExploreOpen);
  const isDirectionsModalOpen =
    useSelector((state) => state.MapV2.directionsView) && !isExploreOpen;

  const activateDirections = () => {
    dispatch(
      actions.MapV2.setDirectionsView({
        activateDirections: true,
      })
    );
    handleAddPinToDirections(pin, pinData);
  };
  const isHovered = (hoveredPin === pin?.id || hovered) && !isDirectionPin;
  const DirectionPinIcon = isDestination
    ? DestinationDirectionMarker
    : isOrigin
    ? OriginDirectionMarker
    : DirectionMarker;

  return (
    <>
      {hovered || (!isCommandBarEnabled && isActivePin) ? (
        <Popup
          latitude={pin.lat}
          longitude={pin.long}
          anchor="bottom"
          focusAfterOpen={false}
          closeOnClick={false}
          closeButton={false}
          closeOnMove={false}
          className={classes.markerCaption}>
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={handlePinClick}
            style={{
              padding: '4px 8px',
              maxWidth: 800,
              backgroundColor: '#FFF',
              zIndex: 1121,
              cursor: 'pointer',
              height: 32,
              borderRadius: 2,
              border: '2px solid #222',
              borderColor:
                isHovered && !isActivePin ? '#FFF1E0' : 'transparent',
              boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.15)',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography
              style={{
                color: isActivePin ? '#ED702E' : isHovered ? '#FFA766' : '#222',
                fontSize: 14,
                fontWeight: 500,
                '-webkit-user-select': 'none',
                '-ms-user-select': 'none',
                userSelect: 'none',
              }}
              noWrap>
              {pinData?.title}
            </Typography>
            {isActivePin && !isDirectionPin && !isExploreOpen ? (
              <IconButton
                onClick={activateDirections}
                sx={{
                  backgroundColor: '#FFF1E0',
                  marginLeft: '4px',
                  height: '26px',
                  width: '26px',
                  padding: '4px',
                  border: '1px solid #FFF1E0',
                  '&:hover': {
                    backgroundColor: '#FFF1E0',
                    borderColor: 'primary.main',
                  },
                }}>
                <DirectionsRight height="20" width="20" />
              </IconButton>
            ) : null}
          </div>
        </Popup>
      ) : null}
      <Marker
        latitude={pin.lat}
        longitude={pin.long}
        onClick={handlePinClick}
        color="#E5493D"
        anchor="bottom"
        offset={[0, isDestination ? 10 : 6]}>
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}>
          {isDirectionPin ? (
            <DirectionPinIcon className="customMarker" />
          ) : pin?.categoryId && pin?.categoryId !== 'default' ? (
            <CategoryMarker
              className="customMarker"
              categoryId={PIN_ICONS[pin.categoryId || 'default']?.id}
              color={
                isDirectionsModalOpen ? '#8A8A8A' : pin.pinColor || '#ED702E'
              }
            />
          ) : (
            <DefaultMapMarker
              className="customMarker"
              fill={isDirectionsModalOpen ? '#8A8A8A' : pin?.pinColor || fill}
            />
          )}
        </div>
      </Marker>
    </>
  );
}

function CustomMap({
  tripId,
  openMapPopup,
  setOpenMapPopup,
  isCommandBarEnabled = false,
}) {
  // uncomment this below line to enable the isCommandBarEnabled prop
  const classes = useStyles(false);
  const dispatch = useDispatch();
  const {
    place,
    setPlace,
    viewport,
    marker,
    setMarker,
    focusPlace,
    handleImageError,
    setCurrentMap,
    setViewport,
    getPlaceDetails,
  } = useMapUtils();

  const navigate = useNavigate();

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPilotBookings = useFeatureFlagEnabled(FLAGS.PILOT_BOOKINGS);

  const mapRef = useRef();
  const { playSound } = useSounds();

  const [map, setMap] = useState(null);

  const mapPinsObj = useSelector((state) => state.MapV2.mapPins[tripId] || {});
  const tripItems = useSelector((state) => state.Item.items || {});
  const hoveredMarkerIndex = useSelector(
    (state) => state.Recommendations.hoveredMarkerIndex
  );
  const tripItemsArray = Object.values(tripItems);
  const isExpanded = useSelector(
    (state) => state.View.commandBar.isRecommendationsOpen
  );

  const { getTour, tourName, tourCurrentStep } = useTour();
  const { tripLegs = [], travelMode = 'CAR' } = useSelector(
    (state) => state.MapV2.directionsRoutes || {}
  );
  const {
    hotelList,
    cityLocationDetails,
    hoveredCardIndex,
    activeHotelMarker,
    hoveredHotelMarker,
    numberOfNights,
  } = useSelector((state) => state.Bookings[tripId] || {});
  const isExploreOpen = useSelector((state) => state.View.isExploreOpen);

  const { pois: poisPlaceList = [] } = useSelector(
    (state) => state.Recommendations.recommendations[tripId] || {}
  );

  const { activities: activitiesList = [] } = useSelector(
    (state) => state.Recommendations || []
  );

  const { activePoisPlaceMarker, hoveredCardIndex: poisHoveredCardIndex } =
    useSelector((state) => state.Recommendations);

  const isStaysTabOpen = useSelector(
    (state) => state.View.activeExploreTab === 0
  );
  const poiDetailsStatus = useSelector((state) => state.View.poiDetails);
  const isDirectionsModalOpen = useSelector(
    (state) => state.MapV2.directionsView && !isExploreOpen
  );
  const directionsPins = useSelector(
    (state) => state.MapV2.directionsPins || []
  );

  const mapPins = Object.values(mapPinsObj);
  // filter out the activies from activityList which already have a mapPin
  const filteredActivitiesList = activitiesList?.filter((activity) => {
    const mapPin = mapPins.find(
      (pin) => pin?.placeId === activity?.placesData?.place_id
    );
    return !mapPin;
  });
  const filteredDirectionPins = directionsPins?.filter((pin) => pin.type);

  function openDirectionsModal() {
    setPlace(null);
    dispatch(
      actions.MapV2.setDirectionsView({
        activateDirections: true,
      })
    );
  }

  // function to calculate the viewport of the map on first load
  const fitMapToBounds = (pinsArray = mapPins, boundProps = {}) => {
    if (!map) return null;
    const coordinates = pinsArray.map((pin) => [pin?.long, pin?.lat]);

    if (coordinates.length === 0) {
      return null;
    }

    const bounds = new mapboxgl.LngLatBounds();
    coordinates?.forEach((coord) => bounds.extend(coord));

    if (!bounds) return null;

    map.fitBounds(bounds, {
      padding: 100,
      maxZoom: 12,
      ...boundProps,
    });

    return null;
  };

  // event listener to set the bounded viewport of the map when the map is loaded
  useEffect(() => {
    if (!map) return;
    fitMapToBounds();
  }, [map]);

  useEffect(() => {
    if (mapRef?.current) {
      if (!map) setMap(mapRef.current.getMap());
      setCurrentMap(mapRef.current.getMap());
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (cityLocationDetails?.longitude && cityLocationDetails?.latitude) {
      mapRef?.current?.getMap()?.flyTo({
        center: [cityLocationDetails?.longitude, cityLocationDetails?.latitude],
        zoom: 12,
      });
    }
  }, [cityLocationDetails]);

  useEffect(() => {
    if (!map) return;
    map?.jumpTo({
      center: [viewport?.longitude, viewport?.latitude],
      zoom: viewport.zoom,
    });
  }, [viewport]);

  const activeMapPin = mapPins.find(
    (pin) => place != null && place?.pinId === pin?.id
  );

  useEffect(() => {
    let timerId;
    if (!place) {
      dispatch(actions.View.setActiveMapPin({ mapPin: null }));
      return () => {};
    }
    if (activeMapPin?.id) {
      dispatch(actions.View.setActiveMapPin({ mapPin: activeMapPin?.id }));

      // adding delay to sync with the added div container
      timerId = setTimeout(() => {
        // auto scroll to the activity blade of the corresponding mapPin
        const element = document.getElementById(
          `activity-blade-${activeMapPin?.id}`
        );
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 200);
    } else {
      dispatch(actions.View.setActiveMapPin({ mapPin: null }));
    }
    return () => {
      // Clean up the timer when the effect is cleaned up
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [activeMapPin, place]);

  useEffect(() => {
    getTour()
      .onActiveTour([
        'sampleTripFlowWeb',
        'quickStartFlowWeb',
        'emptyTripFlowWeb',
        'blankTripFlowWeb',
      ])
      .onActiveStep(stepName.MAP_SEARCH_BAR, () => {
        setPlace(null);
      });
  }, [tourName, tourCurrentStep]);

  const activeDirectionMapPins = (directionsPins || []).map(
    (stop) => stop?.mapPinId
  );

  function addPinToDirections(pin, pinData) {
    const tmpStops = [...(directionsPins || [])].filter((stop) => stop.type);
    const newId = (tmpStops || []).length + 1;
    dispatch(
      actions.MapV2.setDirectionsPins([
        ...(tmpStops || []),
        {
          id: `stop_${newId}_${Date.now()}`,
          type: 'MAP_PIN',
          title: pinData?.title || '',
          long: pin?.long,
          lat: pin?.lat,
          mapPinId: pin?.id,
        },
        ...(tmpStops?.length === 0 ? [{ id: `stop_2_${Date.now()}` }] : []),
      ])
    );
  }

  useEffect(() => {
    dispatch(actions.MapV2.resetDirections());
  }, []);

  const handleHoveredHotelMarkerSwitch = debounce((hotel, index) => {
    if (index === hoveredHotelMarker?.cardIndex) return;
    dispatch(
      actions.Bookings.setHoveredHotelMarker({
        tripId,
        ...(hotel ? { hoveredHotelMarker: { cardIndex: index, hotel } } : {}),
      })
    );
  }, 500);

  const handleActiveHotelMarkerSwitch = debounce((hotel, index) => {
    if (index === activeHotelMarker?.cardIndex) return;
    dispatch(
      actions.Bookings.setActiveHotelMarker({
        tripId,
        ...(hotel ? { activeHotelMarker: { cardIndex: index, hotel } } : {}),
      })
    );
  }, 500);

  function HotelCardPopup() {
    const isSamePopup =
      activeHotelMarker?.cardIndex === hoveredHotelMarker?.cardIndex;
    const handleMarkerMouseLeave = (e) => {
      if (isSamePopup) handleHoveredHotelMarkerSwitch();
      if (!(e?.target?.id || '')?.startsWith('hotel-marker')) {
        handleActiveHotelMarkerSwitch();
        handleHoveredHotelMarkerSwitch();
      }
    };

    const activeMarker = isSamePopup
      ? activeHotelMarker
      : hoveredHotelMarker || activeHotelMarker;
    const activeHotel = activeMarker?.hotel;
    if (!activeHotel || !mapRef?.current) return null;
    return (
      <Popup
        latitude={activeHotel?.latitude}
        longitude={activeHotel?.longitude}
        anchor="bottom"
        closeButton={false}
        className={classes.markerCaption}
        style={{ zIndex: 4 }}>
        <ClickAwayListener onClickAway={handleMarkerMouseLeave}>
          <div style={{ width: 260 }}>
            <HotelDetailsCard
              hotelKey={activeHotel?.hotelKey}
              index={activeHotelMarker?.cardIndex}
              hotelName={activeHotel?.hotelName}
              starRating={activeHotel?.starRating}
              guestRating={activeHotel?.guestRating}
              numberOfReviews={activeHotel?.numberOfReviews}
              guestRatingSentiment={activeHotel?.guestRatingSentiment}
              imageLink={activeHotel?.imageLink}
              rates={activeHotel?.rates}
              providers={activeHotel?.providers}
              currencyCode={activeHotel?.currencyCode}
              referenceId={activeHotel?.id?.toString()}
              images={activeHotel?.images}
              city={cityLocationDetails?.title || ''}
              propertyType={activeHotel?.propertyType}
              numberOfNights={numberOfNights}
              isPopupCard
              disableProviders
              clickable={false}
              latlong={{
                latitude: activeHotel?.latitude,
                longitude: activeHotel?.longitude,
              }}
            />
          </div>
        </ClickAwayListener>
      </Popup>
    );
  }

  const handlePoisMarkerClick = (poisPlace, index) => {
    if (poiDetailsStatus?.isInView) return;
    dispatch(
      actions.Recommendations.setActivePoisPlaceMarker({
        cardIndex: index,
        poisPlace,
      })
    );
  };

  const handleHotelMarkerHover = (hotel, index) => {
    if (!isPilotBookings) return;
    // disable hover effect when hotel card is already active
    if (activeHotelMarker && Object.keys(activeHotelMarker).length > 0) return;
    handleHoveredHotelMarkerSwitch(hotel, index);
  };

  const handleCardMouseEnter = (index) => {
    if (!isMobile) {
      dispatch(
        actions.Recommendations.setHoveredCardIndex({
          cardIndex: index,
          tripId,
        })
      );
    }
  };

  const handleCardMouseLeave = () => {
    if (!isMobile) {
      dispatch(
        actions.Recommendations.setHoveredCardIndex({ cardIndex: null, tripId })
      );
    }
  };

  const handlePoisMarkerClickAway = (isCardActive) => {
    if (isCardActive) {
      dispatch(actions.Recommendations.setActivePoisPlaceMarker(null));
    }
  };

  const handleExploreButton = (type) => {
    phTrackEvent({
      event:
        type === 'ACCOMMODATION'
          ? EVENTS.PLAN_MAP.STAYS_START
          : EVENTS.PLAN_MAP.ACTIVITIES_START,
    });
    if (type === 'ACCOMMODATION') {
      // navigate to stays page
      navigate(`/trips/${tripId}/explore?focus=stays`);
    } else {
      // navigate to activities page
      navigate(`/trips/${tripId}/explore?focus=pois`);
    }
  };

  const hotelsMarkersList = hotelList?.map((hotel, index) => {
    const isChipActive = activeHotelMarker?.cardIndex === index;
    const isChipHovered =
      hoveredCardIndex === index ||
      (hoveredHotelMarker?.cardIndex === index &&
        hoveredHotelMarker?.shouldFocus);
    return (
      <Marker
        key={`marker-${index}`}
        longitude={hotel.longitude}
        latitude={hotel.latitude}
        anchor="bottom"
        style={{
          zIndex: isChipHovered || isChipActive ? 2 : 1,
        }}>
        <div
          id={`hotel-marker-${index}`}
          className={`${classes.hotelChip} ${
            isChipActive ? classes.activeHotelChip : ''
          } ${isChipHovered ? classes.hoveredHotelChip : ''}`}
          onMouseOver={() =>
            !isChipActive && handleHotelMarkerHover(hotel, index)
          }
          onMouseLeave={() => {}}
          onClick={() => handleActiveHotelMarkerSwitch(hotel, index)}>
          {hotel?.nightlyPrice?.length > 4
            ? hotel?.nightlyPrice
            : hotel?.nightlyPrice?.replace(/\.\d+/, '')}
        </div>
      </Marker>
    );
  });

  const activityMarkersList = filteredActivitiesList?.map((activity, index) => {
    const isChipHovered = hoveredMarkerIndex === index;
    return (
      <Marker
        key={`marker-${index}`}
        longitude={activity?.placesData?.long}
        latitude={activity?.placesData?.lat}
        anchor="bottom"
        style={{
          zIndex: 4,
        }}>
        <ClickAwayListener onClickAway={() => {}}>
          <Box
            onClick={() => {
              dispatch(
                actions.View.setCommandBar({
                  isRecommendationsOpen: false,
                })
              );
              getPlaceDetails(activity?.placesData?.place_id, 'ACTIVITY');
            }}
            onMouseEnter={() =>
              dispatch(actions.Recommendations.setHoveredMarkerIndex(index))
            }
            onMouseLeave={() =>
              dispatch(actions.Recommendations.setHoveredMarkerIndex(null))
            }
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              cursor: 'pointer',
              gap: '2px',
            }}>
            {isChipHovered && (
              <Box
                sx={{
                  background: '#FFFFFF',
                  borderRadius: '2px',
                  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.15)',
                  padding: 1,
                  zIndex:
                    index === poisHoveredCardIndex || isChipHovered ? 9299 : 1,
                }}>
                <Typography
                  style={{
                    color: '#222',
                    fontSize: 14,
                    fontWeight: 500,
                    '-webkit-user-select': 'none',
                    '-ms-user-select': 'none',
                    userSelect: 'none',
                  }}
                  noWrap>
                  {activity?.name}
                </Typography>
              </Box>
            )}
            <DefaultMapMarker
              className="customMarker"
              fill={isChipHovered ? '#ED702E' : '#FFA766'}
              onMouseLeave={() => handleCardMouseLeave()}
            />
          </Box>
        </ClickAwayListener>
      </Marker>
    );
  });

  const poisMarkersList = poisPlaceList?.map((poisObj, index) => {
    const isChipActive =
      activePoisPlaceMarker?.poisPlace?.referenceId === poisObj?.referenceId;
    const isChipHovered = poisHoveredCardIndex === index;
    return (
      <Marker
        key={`marker-${poisObj.referenceId}`}
        longitude={poisObj.coordinates.longitude}
        latitude={poisObj.coordinates.latitude}
        anchor="bottom"
        style={{
          zIndex: index === poisHoveredCardIndex || isChipActive ? 9200 : 1,
        }}>
        <ClickAwayListener
          onClickAway={() => handlePoisMarkerClickAway(isChipActive)}>
          <Box
            onClick={() => handlePoisMarkerClick(poisObj, index)}
            onMouseEnter={() => handleCardMouseEnter(index)}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              cursor: 'pointer',
              gap: '2px',
            }}>
            {(isChipHovered && !isChipActive) || poiDetailsStatus?.isInView ? (
              <Box
                sx={{
                  background: '#FFFFFF',
                  borderRadius: '2px',
                  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.15)',
                  padding: 1,
                  zIndex:
                    index === poisHoveredCardIndex || isChipActive ? 9200 : 1,
                }}>
                <Typography
                  style={{
                    color: '#222',
                    fontSize: 14,
                    fontWeight: 500,
                    '-webkit-user-select': 'none',
                    '-ms-user-select': 'none',
                    userSelect: 'none',
                  }}
                  noWrap>
                  {poisObj.title}
                </Typography>
              </Box>
            ) : null}
            <DefaultMapMarker
              className="customMarker"
              fill={isChipHovered || isChipActive ? '#ED702E' : '#FFA766'}
              onMouseLeave={() => handleCardMouseLeave()}
            />
          </Box>
        </ClickAwayListener>
      </Marker>
    );
  });

  useEffect(() => {
    if (
      (activeHotelMarker?.hotel ||
        (hoveredHotelMarker?.hotel && hoveredHotelMarker?.shouldFocus)) &&
      mapRef
    ) {
      const activeHotel = hoveredHotelMarker?.shouldFocus
        ? hoveredHotelMarker?.hotel
        : activeHotelMarker?.hotel;

      if (hoveredHotelMarker?.shouldFocus)
        mapRef?.current?.getMap()?.setZoom(10);
      mapRef?.current?.getMap()?.flyTo({
        center: [activeHotel?.longitude, activeHotel.latitude],
        offset: [0, 40],
        maxDuration: hoveredHotelMarker?.shouldFocus ? 1200 : 4000,
      });
    }
  }, [activeHotelMarker?.hotel, hoveredHotelMarker?.shouldFocus]);

  useEffect(() => {
    if (
      hoveredHotelMarker?.hotel &&
      hoveredHotelMarker?.shouldFocus &&
      mapRef
    ) {
      const activeHotel = hoveredHotelMarker?.hotel;
      mapRef?.current?.getMap()?.flyTo({
        center: [activeHotel?.longitude, activeHotel.latitude],
        offset: [0, 40],
      });
    }
  }, [hoveredHotelMarker]);

  useEffect(() => {
    if (activePoisPlaceMarker?.poisPlace && mapRef) {
      mapRef?.current?.getMap()?.flyTo({
        center: [
          activePoisPlaceMarker?.poisPlace?.coordinates?.longitude,
          activePoisPlaceMarker?.poisPlace?.coordinates?.latitude,
        ],
        offset: [0, 40],
        zoom: 12,
      });
    }
  }, [activePoisPlaceMarker?.poisPlace]);

  const ActiveTransitOptionIcon = TRAVEL_MODES[travelMode || 'CAR']?.icon;

  const ExploreMarkers = useMemo(() => {
    if (isExploreOpen) {
      if (isStaysTabOpen) {
        return hotelsMarkersList;
      }
      if (poiDetailsStatus?.isInView) {
        return poisMarkersList[poiDetailsStatus?.cardIndex];
      }
      return poisMarkersList;
    }
    return null;
  }, [
    isExploreOpen,
    isStaysTabOpen,
    activePoisPlaceMarker,
    hotelsMarkersList,
    poisMarkersList,
  ]);

  return (
    <div className={classes.mainContainer}>
      {isExploreOpen ? null : isDirectionsModalOpen ? (
        <div
          style={{
            zIndex: 800,
            position: 'absolute',
            top: 24,
            right: 24,
          }}>
          <DirectionsModal
            recenterMap={(pinsArray) =>
              fitMapToBounds(pinsArray, {
                padding: {
                  top: 100,
                  bottom: 100,
                  left: 100,
                  right: 400,
                },
              })
            }
          />
        </div>
      ) : (
        <div className={classes.mapElementWrapper}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              marginRight: 42,
              justifyContent: 'center',
            }}>
            <AutoCompleteSearchBar
              setPlace={setPlace}
              handleSelected={focusPlace}
              openMapPopup={openMapPopup}
              setOpenMapPopup={setOpenMapPopup}
            />

            <div className={classes.routesButtonContainer}>
              <BasicButton
                sx={{
                  padding: '4px 12px',
                  height: '100%',
                  minWidth: isTablet ? 0 : 'auto',
                }}
                onClick={openDirectionsModal}>
                <Route style={{ marginRight: isTablet ? 0 : 6 }} />
                {isTablet ? '' : 'Routes'}
              </BasicButton>
            </div>
            <div className={classes.toggleButtonsContainer}>
              <SecondaryOutlinedButton
                onClick={() => handleExploreButton('ACCOMMODATION')}
                startIcon={<HomeOutlined sx={{ height: 20, width: 20 }} />}
                sx={{
                  padding: '4px 11px',
                  fontSize: 12,
                  borderRadius: '20px',
                }}>
                Places to stay
              </SecondaryOutlinedButton>
              <SecondaryOutlinedButton
                onClick={() => handleExploreButton('ACTIVITY')}
                startIcon={
                  <OutlinedFlagRounded sx={{ height: 16, width: 16 }} />
                }
                sx={{
                  padding: '2px 11px',
                  fontSize: 12,
                  borderRadius: '20px',
                }}>
                Things to do
              </SecondaryOutlinedButton>
            </div>
          </div>
        </div>
      )}
      <MapboxMap
        ref={mapRef}
        onMoveEnd={({ viewState }) => {
          if (
            viewState.latitude === viewport.latitude &&
            viewState.longitude === viewport.longitude &&
            viewState.zoom === viewport.zoom
          )
            return;
          setViewport(viewState);
        }}
        onLoad={() => {
          if (mapRef?.current) setMap(mapRef.current.getMap());
        }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={config.mapboxAccessToken}
        mapLib={mapboxgl}>
        {isDirectionsModalOpen ? null : <NavigationControl />}
        <div
          id="categories-reference"
          style={{
            minHeight: 1,
            zIndex: 1121,
            position: 'absolute',
          }}
        />
        {isDirectionsModalOpen && !isExploreOpen ? (
          <div>
            {tripLegs?.map((leg, idx) => {
              const { summary } = leg;
              const duration = summary?.travelTimeInSeconds || 0;
              const distance = summary?.lengthInMeters || 0;
              const center = [
                leg.points[parseInt((leg.points.length - 1) / 2, 10)]?.latitude,
                leg.points[parseInt((leg.points.length - 1) / 2, 10)]
                  ?.longitude,
              ];
              return (
                <div>
                  <Source
                    id={`route-${idx}`}
                    type="geojson"
                    data={{
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: leg.points.map((point) => [
                          point.longitude,
                          point.latitude,
                        ]),
                      },
                    }}>
                    {[-1, 0, 1, 0].map((offset, i) => (
                      <Layer
                        id={`route-${idx}-${i}`}
                        type="line"
                        layout={{
                          'line-cap': 'round',
                        }}
                        paint={{
                          'line-color': i === 3 ? '#ED702E' : '#B16C2D',
                          'line-width': i === 3 ? 5 : 2,
                          'line-offset': offset * 2,
                        }}
                      />
                    ))}
                  </Source>
                  <Popup
                    latitude={center[0]}
                    longitude={center[1]}
                    anchor="top"
                    focusAfterOpen={false}
                    closeOnClick={false}
                    closeButton={false}
                    closeOnMove={false}
                    className={classes.markerCaption}>
                    <div
                      style={{
                        padding: '4px 8px',
                        borderRadius: 4,
                        backgroundColor: '#FFF',
                        border: '1px solid #1877F2',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <ActiveTransitOptionIcon
                        sx={{ height: 16, width: 16, fill: '#1877F2' }}
                      />
                      <span style={{ marginLeft: 4, color: '#1877F2' }}>
                        {' '}
                        • {convertSecondsToFormatedDuration(duration)}
                      </span>
                      <span style={{ marginLeft: 4, color: '#1877F2' }}>
                        {' '}
                        • {convertMetresToKms(distance)}
                      </span>
                    </div>
                  </Popup>
                </div>
              );
            })}
          </div>
        ) : null}
        {mapPins &&
          !poiDetailsStatus?.isInView &&
          [
            ...(mapPins.filter(
              (pin) =>
                place?.pinId !== pin?.id &&
                !(
                  isDirectionsModalOpen &&
                  activeDirectionMapPins.includes(pin?.id)
                )
            ) || []),
          ].map((pin) => {
            const isActivePin = place !== null && place?.pinId === pin?.id;
            const pinData = pin?.pinDetails;
            return (
              <MapMarker
                pin={pin}
                pinData={pinData}
                isActivePin={isActivePin}
                handlePinClick={() => {
                  let activeLocationId = null;
                  let activeSectionId = null;
                  dispatch(
                    actions.View.setCommandBar({
                      isRecommendationsOpen: false,
                    })
                  );
                  const findItemByPinId = (pinId) =>
                    tripItemsArray.find((item) => item.mapPin === pinId)?.id;

                  const findParent = (childId) =>
                    tripItemsArray.find((item) =>
                      item.children?.includes(childId)
                    )?.id;
                  if (pin) {
                    const pinId = pin.id;
                    if (pin.type === MAPPINS_TYPES.ACTIVITY) {
                      const activeTodoId = findItemByPinId(pinId);
                      if (activeTodoId) {
                        activeSectionId = findParent(activeTodoId);
                        activeLocationId = findParent(activeSectionId);
                      }
                    } else if (pin.type === MAPPINS_TYPES.LOCATION) {
                      activeLocationId = findItemByPinId(pinId);
                      activeSectionId = tripItems[
                        activeLocationId
                      ]?.children?.find(
                        (childId) =>
                          tripItems[childId]?.type === ITEM_TYPES.HEADING
                      );
                    } else if (pin.type === MAPPINS_TYPES.ACCOMMODATION) {
                      const activeHotelId = findItemByPinId(pinId);
                      activeLocationId = findParent(activeHotelId);
                      activeSectionId = tripItems[
                        activeLocationId
                      ]?.children?.find(
                        (childId) =>
                          tripItems[childId]?.type === ITEM_TYPES.HEADING
                      );
                    }
                  }
                  dispatch(
                    actions.View.setCommandBarV2({
                      activeSectionId,
                      activeLocationId,
                    })
                  );

                  if (isDirectionsModalOpen) {
                    addPinToDirections(pin, pinData);
                    return;
                  }
                  setMarker(null);
                  setPlace({
                    placeId: pin?.placeId,
                    title: pinData?.title,
                    maps: pinData?.mapUrl,
                    website: pinData?.website,
                    photo: pinData?.photo,
                    photos: pinData?.photos,
                    rating: pinData?.rating || 0,
                    ratingCount: pinData?.ratingCount || 0,
                    description: pinData?.description || '',
                    pinned: true,
                    pinId: pin.id,
                    lat: pin.lat,
                    long: pin.long,
                    type: pin?.type,
                    types: pinData?.types,
                    categoryId: pin?.categoryId,
                    pinColor: pin?.pinColor,
                    photoRef: pinData?.photoRef,
                  });
                  mapRef?.current?.getMap()?.flyTo({
                    center: [pin.long, pin.lat],
                    zoom: pin?.type === 'LOCATION' ? 8 : 14,
                    // todo set fetaure flag for offset
                    offset: [0, 150],
                  });
                }}
                isCommandBarEnabled={isCommandBarEnabled}
              />
            );
          })}
        {!isDirectionsModalOpen && activeMapPin && (
          <MapMarker
            pin={activeMapPin}
            handleAddPinToDirections={addPinToDirections}
            pinData={activeMapPin?.pinDetails}
            isActivePin
            handlePinClick={() => {}}
            isCommandBarEnabled={isCommandBarEnabled}
          />
        )}
        {marker && !isDirectionsModalOpen && (
          <Marker
            longitude={marker.longitude}
            latitude={marker.latitude}
            anchor="bottom"
            offset={[0, 6]}>
            {place?.categoryId && place?.categoryId !== 'default' ? (
              <CategoryMarker
                className="customMarker"
                categoryId={PIN_ICONS[place?.categoryId]?.id}
                color="#8A8A8A"
              />
            ) : (
              <DefaultMapMarker className="customMarker" fill="#8A8A8A" />
            )}
          </Marker>
        )}
        {isDirectionsModalOpen &&
          !isExploreOpen &&
          filteredDirectionPins?.map((pin, idx) => {
            return (
              <MapMarker
                pin={pin}
                pinData={pin}
                handlePinClick={() => {}}
                isDirectionPin
                isOrigin={idx === 0}
                isDestination={(filteredDirectionPins || []).length === idx + 1}
                isCommandBarEnabled={isCommandBarEnabled}
              />
            );
          })}

        {ExploreMarkers}
        {isExpanded && activityMarkersList}
        {isExploreOpen ? (
          isStaysTabOpen ? (
            <HotelCardPopup tripId={tripId} mapRef={mapRef?.current} />
          ) : (
            activePoisPlaceMarker?.poisPlace &&
            mapRef?.current && (
              <PoisCardPopup activePoi={activePoisPlaceMarker?.poisPlace} />
            )
          )
        ) : null}
        {place !== null && !isDirectionsModalOpen && !isExploreOpen && (
          <Popup
            latitude={place?.lat}
            longitude={place?.long}
            anchor="bottom"
            focusAfterOpen={false}
            closeOnClick={false}
            closeButton={false}
            closeOnMove={false}
            className={classes.mapPopUpCard}
            style={{ zIndex: 4 }}>
            <MapPopupCardNew
              {...place}
              tripId={tripId}
              pins={mapPins}
              handleImageError={handleImageError}
              handleClose={() => {
                playSound(SOUNDS.detailClose);
                focusPlace(null);
                setPlace(null);
              }}
              isCommandBarEnabled={isCommandBarEnabled}
            />
          </Popup>
        )}
      </MapboxMap>
    </div>
  );
}

export default CustomMap;
