import CollapsibleSection from './CollapsibleSection';
import PhoneNumberAuthenticaiton from './PhoneNumberAuth';

function PhoneAuthSection() {
  return (
    <CollapsibleSection title="Enter your phone number" defaultOpen>
      <div style={{ marginTop: 12 }} />
      <PhoneNumberAuthenticaiton />
    </CollapsibleSection>
  );
}

export default PhoneAuthSection;
