import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import UserAvatar from './UserAvatar';

// temporary solution until we have a global color sync
const colors = ['#E5493D', '#1592C2', '#3AA031'];

const useStyles = (index) =>
  makeStyles(() => ({
    message: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '12px',
      maxWidth: '100%',
    },
    sendChat: {
      marginLeft: 8,
      minWidth: 0,
      overflow: 'hidden',
    },
    user: {
      fontSize: 12,
      color: colors[index % 3],
      fontWeight: 600,
    },
    receiveText: {
      color: '#222',
      borderRadius: '4px',
    },
    sendText: {
      color: '#222',
      borderRadius: '4px',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      maxWidth: '100%',
    },
    time: {
      color: '#8A8A8A',
      marginLeft: 4,
      fontSize: 10,
      fontWeight: 400,
    },
  }))();

function Message({ userName, text, index, user, sentAt }) {
  const classes = useStyles(index);

  return (
    <div className={classes.message}>
      <UserAvatar
        index={index}
        user={user}
        avatarProps={{
          style: {
            fontSize: 12,
          },
        }}
      />
      <div className={classes.sendChat}>
        <Typography className={classes.user}>
          {userName} <span className={classes.time}>{sentAt}</span>
        </Typography>
        <Typography className={classes.sendText}>{text}</Typography>
      </div>
    </div>
  );
}

export default Message;
