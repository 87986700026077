const colors = {
  primary: {
    main: '#D30F54',
    dark: '#3B1956',
    light: '#F9828C',
    extraLight: '#FDCDD1',
    lightPink: '#FFEEEF',
    orange: '#F6AB7B',
    lightOrange: '#F9C6A5',
    olive: '#F1F1DE',
  },
  text: {
    main: '#222222',
    secondary: '#4E4E4E',
    light: '#8A8A8A',
  },
  system: {
    lightgrey: '#F4F4F4',
    grey: '#D9D9D9',
    white: '#FFFFFF',
  },
  warning: {
    main: '#E68A63',
  },
  error: {
    main: '#E5493D',
  },
  success: {
    main: '#43A047',
  },
  info: {
    main: '#1877F2',
  },
  loading: {
    bg: '#DCDCDC',
  },
};

export default colors;
