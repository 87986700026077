import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Chip,
  Rating,
  Modal,
  IconButton,
} from '@mui/material';
import { DoneRounded, InfoOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Warning } from '../../../atoms/Icon';
import {
  formatDateWithTime,
  formatPrice,
  getRatingPropsV2,
} from '../Stays/HotelDetails/utils';
import { getNumberOfNights } from '../Stays/bookingsUtils';
import RoomDetailsModal from './RoomDetailsModal';
import InHotelTaxesModal from './InHotelTaxesModal';
import CancellationPolicy from './CancellationPolicy';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '8px',
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: 'none',
    backgroundColor: '#fff',
    padding: '22px',
  },
}));

function CartSummary({
  name,
  totalPrice,
  address,
  cityName,
  rating,
  reviewsCount,
  hotelRoomRates,
  cancellationPolicies,
  isRefundable,
  checkInDate,
  checkOutDate,
  hotelImage,
  hotelDetails,
  hotelRooms,
}) {
  const classes = useStyles();
  const numberOfNights = getNumberOfNights({
    from: checkInDate,
    to: checkOutDate,
  });
  const [openRoomDetailsModal, setOpenRoomDetailsModal] = useState(false);
  const [openInHotelTaxesModal, setOpenInHotelTaxesModal] = useState(false);
  const [cancellationPolicyModal, setCancellationPolicyModal] = useState(false);
  // TODO: Remove this state, for some reason it's always empty if read from the API call.
  const [starRating, setStarRating] = useState(
    hotelDetails?.content?.starRating
  );

  const extraTaxes = hotelRoomRates
    ?.map((rate) =>
      rate?.retailRate?.taxesAndFees?.reduce((acc, tax) => {
        if (tax?.included === false) {
          return acc + tax.amount;
        }
        return acc;
      }, 0)
    )
    .flat();
  const includedTax = totalPrice?.taxes;
  const totalWithoutTaxes = totalPrice.priceBeforeTaxes;
  const pricePerNight = totalWithoutTaxes / numberOfNights;

  const [ratingSentiment, ratingColor, ratingTextColor] =
    reviewsCount > 25 ? getRatingPropsV2(rating) : [null, null, null];

  const handleCancellationPolicyModal = () => {
    setCancellationPolicyModal(true);
  };

  useEffect(() => {
    setStarRating(hotelDetails?.content?.starRating);
  }, [hotelDetails?.content?.starRating]);

  const handleClose = () => {
    setCancellationPolicyModal(false);
  };

  return (
    <>
      <Box className={classes.root}>
        <RoomDetailsModal
          open={Boolean(openRoomDetailsModal)}
          onClose={() => setOpenRoomDetailsModal(null)}
          room={hotelDetails?.content?.rooms?.find(
            (room) => room?.id === openRoomDetailsModal
          )}
        />
        <InHotelTaxesModal
          open={openInHotelTaxesModal}
          onClose={() => setOpenInHotelTaxesModal(false)}
          taxes={extraTaxes}
        />
        <Grid container spacing={2}>
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'stretch',
            }}>
            <Box
              component="img"
              src={hotelImage || hotelDetails?.content?.thumbnail} // Replace with your actual image source
              alt="Description"
              sx={{
                width: 118, // Fixed width as specified
                height: '100%', // Stretch to match container height
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
          </Grid>
          <Grid item xs container flexDirection="column">
            {starRating && (
              <Rating
                value={starRating}
                readOnly
                precision={0.5}
                size="small"
                sx={{
                  mt: 0.35,
                  mb: 0.45,
                }}
                max={starRating || 0}
              />
            )}
            <Typography variant="h2" fontWeight="bold">
              {name}
            </Typography>
            <Typography
              color="text.secondary"
              mt={0.25}
              fontSize="14px"
              variant="caption">{`${address}, ${cityName}`}</Typography>
            <Box mt={0.75} display="flex" alignItems="center">
              <Chip
                label={rating}
                sx={{
                  borderRadius: '4px',
                  backgroundColor: ratingColor,
                  color: ratingTextColor,
                  fontWeight: 'bold',
                  height: 'auto',
                  '& .MuiChip-label': {
                    padding: '8px',
                  },
                }}
                size="small"
              />
              <Box display="flex" alignItems="center" ml={1}>
                <Typography variant="h5" fontWeight={450}>
                  {ratingSentiment}
                </Typography>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  ml={1}
                  mt={0.25}>
                  ({reviewsCount}) <span>Reviews</span>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 2.5, mb: 1.5 }} />

        <Typography component="span" variant="h3">
          Room{hotelRooms?.length > 1 ? 's' : ''}
        </Typography>
        <Box mt={1.5} display="flex" flexDirection="column" gap={2}>
          {hotelRooms.map((room) => (
            <Typography
              key={room?.id} // Ensure unique key if available
              sx={{
                textDecoration: hotelDetails ? 'underline' : 'none',
                cursor: 'pointer',
              }}
              onClick={() =>
                hotelDetails
                  ? setOpenRoomDetailsModal(hotelRoomRates?.[0]?.mappedRoomId)
                  : null
              }>
              {room?.name}
            </Typography>
          ))}
        </Box>

        {/* {withBreakfast && (
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Typography variant="body2">With BreakFast</Typography>
          <Typography variant="body2" fontWeight="bold">
            ${formatCurrency(withBreakfast)}
          </Typography>
        </Box>
      )}
      {withWifi && (
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Typography>with WiFi</Typography>
          <Typography fontWeight="bold">${formatCurrency(withWifi)}</Typography>
        </Box>
      )} */}

        <Divider sx={{ mt: 2.5, mb: 1.5 }} />

        <Typography component="span" variant="h3">
          Cancellation Policy
        </Typography>

        {!isRefundable ? (
          <Box
            display="flex"
            alignItems="center"
            mt={1.5}
            onClick={handleCancellationPolicyModal}>
            <span style={{ marginRight: '4px' }}>
              <Warning />
            </span>
            <Typography
              sx={{
                color: '#453C37',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}>
              This booking is non-refundable
            </Typography>
          </Box>
        ) : (
          <Box>
            {cancellationPolicies?.length === 1 &&
            cancellationPolicies[0]?.refundabilityType === 'FR' ? (
              <Box
                display="flex"
                mt={1.5}
                onClick={handleCancellationPolicyModal}>
                <DoneRounded sx={{ fontSize: 20, mr: 1, mt: 0.1 }} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}>
                  Don&apos;t worry, you&apos;ll be able to cancel and get a full
                  refund until{' '}
                  {formatDateWithTime(cancellationPolicies[0]?.cancelTime)}
                </Typography>
              </Box>
            ) : (
              <Box
                display="flex"
                mt={1.5}
                onClick={handleCancellationPolicyModal}>
                <span style={{ marginRight: '4px' }}>
                  <Warning />
                </span>
                <Typography
                  sx={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}>
                  This booking has a cancellation policy depending on when you
                  cancel
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <Divider sx={{ mt: 2.5, mb: 1.5 }} />

        <Typography variant="h3" fontWeight={600}>
          Price Summary
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={1.5}>
          <Typography>
            {formatPrice(pricePerNight, totalPrice?.currency)} per night x{' '}
            {numberOfNights} nights
          </Typography>
          <Typography>
            {formatPrice(totalWithoutTaxes, totalPrice?.currency)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={1.5}>
          <Typography>Taxes & Fees</Typography>
          <Typography>
            {formatPrice(includedTax, totalPrice?.currency)}
          </Typography>
        </Box>

        <Divider
          sx={{
            mt: 1.5,
            mb: 1.5,
          }}
        />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="h2" fontWeight="bold">
            {`${formatPrice(totalPrice?.amount, totalPrice?.currency)}`}
          </Typography>
        </Box>

        {extraTaxes > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1.5}>
            <Typography
              onClick={() => setOpenInHotelTaxesModal(true)}
              color="text.secondary"
              sx={{
                color: '#80746C',
                cursor: 'pointer',
                verticalAlign: 'middle',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}>
              Taxes paid at hotel
              <InfoOutlined sx={{ fontSize: 16, ml: 1, mb: 0.5 }} />
            </Typography>
            <Typography variant="body2" sx={{ color: '#80746C' }}>
              {formatPrice(extraTaxes, totalPrice?.currency)}
            </Typography>
          </Box>
        )}
      </Box>
      <Modal
        open={cancellationPolicyModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: '#fff',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseOutlinedIcon />
          </IconButton>
          <CancellationPolicy
            isRefundable={isRefundable}
            cancellationPolicies={cancellationPolicies}
            hotelRoomRates={hotelRoomRates}
            totalPrice={totalPrice}
            isModal
          />
        </Box>
      </Modal>
    </>
  );
}

export default CartSummary;
