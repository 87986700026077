import { useSelector } from 'react-redux';
import { styled } from '@mui/styles';
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: '#8A8A8A',
    fontSize: 14,
  },
  '& .MuiInputLabel-shrink': {
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
  '& .MuiInputLabel-shrink.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiInputBase-input': {
    padding: '14px 16px',
  },
  '& .MuiInputBase-root': {
    '& fieldset': {
      borderColor: '#ECE7E3',
    },
  },
}));

function BasicInfo({ guestDetails, handleUpdateGuestInfo }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <StyledTextField
          fullWidth
          label="First Name"
          required
          variant="outlined"
          placeholder="John"
          name="firstName"
          value={guestDetails.firstName || ''}
          onChange={handleUpdateGuestInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StyledTextField
          fullWidth
          label="Last Name"
          variant="outlined"
          placeholder="Doe"
          required
          name="lastName"
          value={guestDetails.lastName || ''}
          onChange={handleUpdateGuestInfo}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          fullWidth
          label="Email"
          variant="outlined"
          required
          placeholder="johndoe@pilotplans.com"
          name="email"
          value={guestDetails.email || ''}
          onChange={handleUpdateGuestInfo}
        />
      </Grid>
    </>
  );
}

function PrimaryGuestInfo({ index, guestDetails, setCheckoutData = () => {} }) {
  const userObj = useSelector((state) => state.Auth.userData.user);
  const [bookingType, setBookingType] = useState('myself');

  useEffect(() => {
    if (userObj) {
      if (bookingType === 'myself') {
        setCheckoutData((prev) => {
          const updatedGuestDetails = [...prev.guestDetails];
          updatedGuestDetails[index] = {
            firstName: userObj.firstName,
            lastName: userObj.lastName,
            email: userObj.email,
            phoneNumber: userObj.phoneNumber,
          };
          return {
            ...prev,
            guestDetails: updatedGuestDetails,
          };
        });
      } else {
        setCheckoutData((prev) => {
          const updatedGuestDetails = [...prev.guestDetails];
          updatedGuestDetails[index] = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
          };
          return {
            ...prev,
            guestDetails: updatedGuestDetails,
          };
        });
      }
    }
  }, [userObj, bookingType]);

  const events = {
    firstName: EVENTS.CHECKOUT_GUEST_INFO.FIRST_NAME_UPDATE,
    lastName: EVENTS.CHECKOUT_GUEST_INFO.LAST_NAME_UPDATE,
    email: EVENTS.CHECKOUT_GUEST_INFO.EMAIL_UPDATE,
    phoneNumber: EVENTS.CHECKOUT_GUEST_INFO.PHONE_NUMBER_UPDATE,
  };

  const handleUpdateGuestInfo = (e) => {
    phTrackEvent({
      event: events[e.target.name],
    });

    setCheckoutData((prev) => {
      const updatedGuestDetails = [...prev.guestDetails];
      if (index >= updatedGuestDetails.length) {
        updatedGuestDetails[index] = { [e.target.name]: e.target.value };
      } else {
        updatedGuestDetails[index] = {
          ...updatedGuestDetails[index],
          [e.target.name]: e.target.value,
        };
      }
      return {
        ...prev,
        guestDetails: updatedGuestDetails,
      };
    });
  };
  return (
    <Box sx={{ width: '100%' }}>
      {index === 0 ? (
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={bookingType}
            sx={{
              '& .Mui-checked + .MuiFormControlLabel-label': {
                fontWeight: 450,
              },
              '& .MuiFormControlLabel-root': {
                marginLeft: 0,
              },
              '& .MuiFormControlLabel-label': {
                marginLeft: 2,
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
              '& .MuiRadio-root': {
                color: '#222 !important',
                '&:hover': {
                  color: '#222',
                  backgroundColor: '#fff',
                },
              },
              '& .Mui-checked': {
                color: '#222',
              },
            }}
            onChange={(e) => setBookingType(e.target.value)}>
            {/* Myself Option */}
            <Box
              width="100%"
              sx={{
                border:
                  bookingType === 'myself'
                    ? '1px solid #616161'
                    : '1px solid #E3E3E3',
                p: 1,
                borderRadius: '8px',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}>
              <FormControlLabel
                value="myself"
                control={<Radio />}
                label="Myself only"
                labelPlacement="start"
                sx={{ justifyContent: 'space-between', width: '100%' }}
              />
              {bookingType === 'myself' && (
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <BasicInfo
                      guestDetails={guestDetails}
                      handleUpdateGuestInfo={handleUpdateGuestInfo}
                    />
                  </Grid>
                </Box>
              )}
            </Box>

            {/* Others Option */}
            <Box
              width="100%"
              sx={{
                border:
                  bookingType === 'others'
                    ? '1px solid #616161'
                    : '1px solid #E3E3E3',
                p: 1,
                borderRadius: '8px',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              }}>
              <FormControlLabel
                value="others"
                control={<Radio />}
                label="Booking for someone else"
                labelPlacement="start"
                sx={{
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              />
              {bookingType === 'others' && (
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <BasicInfo
                      guestDetails={guestDetails}
                      handleUpdateGuestInfo={handleUpdateGuestInfo}
                    />
                  </Grid>
                </Box>
              )}
            </Box>
          </RadioGroup>
        </FormControl>
      ) : (
        <Box mt={1.5}>
          <Grid container spacing={2}>
            <BasicInfo
              guestDetails={guestDetails}
              handleUpdateGuestInfo={handleUpdateGuestInfo}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
}

function PrimaryGuestsInfo({ rooms, checkoutData, setCheckoutData }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        {rooms?.map((r, index) => (
          <div key={`room-${index}`} style={{ marginBottom: 24 }}>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h3"
                flexGrow={1}
                component="span"
                fontWeight={450}>
                {index === 0 ? 'Who is staying in' : ''} {r?.name}{' '}
                {index === 0 ? '(1)?' : `(${index + 1})`}
              </Typography>
            </Box>
            <PrimaryGuestInfo
              roomName={r?.name}
              index={index}
              setCheckoutData={setCheckoutData}
              guestDetails={checkoutData?.guestDetails[index] || {}}
            />
          </div>
        ))}
      </Box>
    </Box>
  );
}

export default PrimaryGuestsInfo;
