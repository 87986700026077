import getCfConnector from '../../../../cfConnector';
import config from '../../../../config';

export const createCheckoutSession = async (
  checkoutId,
  checkoutData,
  email,
  price,
  provider
) => {
  if (!checkoutId) return null;
  const response = await (
    await getCfConnector()
  ).post(`${config.checkoutAPI}`, {
    checkoutId,
    checkoutData,
    email,
    price,
    provider,
  });
  return response?.data;
};

export const updateCheckoutDetails = async (checkoutId, checkoutData) => {
  if (!checkoutId) return null;
  const response = await (
    await getCfConnector()
  ).put(`${config.checkoutAPI}/${checkoutId}`, { checkoutData });
  return response?.data;
};

export const getCheckoutDetails = async (checkoutId) => {
  if (!checkoutId) return null;
  const response = await (
    await getCfConnector()
  ).get(`${config.checkoutAPI}/${checkoutId}`);
  return response?.data;
};

export const getPrebookId = async (offerId) => {
  if (!offerId) return null;
  // const response = await (
  //   await getCfConnector()
  // ).post(`${config.nuiteAPI}/hotel/prebook`, {
  //   offerId,
  //   usePaymentSdk: false,
  //   voucherCode: '',
  // });
  // return response?.data;
  return 'xyxyxyxy';
};
