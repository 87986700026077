import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PlaceRounded,
  OutlinedFlagRounded,
  PeopleRounded,
} from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { getTravelStats, getTrips } from '../../../redux/slices/Trips';

const useStyles = makeStyles(() => ({
  stat: {
    textAlign: 'center',
    padding: '16px',
    border: '2px solid #D3D3D3',
    borderRadius: '8px',
    width: '100%',
    height: '90px',
  },
}));

function TravelStatsContainer() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const travelStats = useSelector((state) => state.Trips.travelStats);

  useEffect(async () => {
    // updates the travel stats with the latest data
    await dispatch(getTrips(userId));
    await dispatch(
      getTravelStats({
        variables: {
          userId,
        },
      })
    );
  }, []);

  const stats = [
    {
      copy: 'Trips Planned',
      count: travelStats?.tripsCount || 0,
      icon: PlaceRounded,
    },
    {
      copy: 'Activities Added',
      count: travelStats?.activityCount || 0,
      icon: OutlinedFlagRounded,
    },
    {
      copy: 'Friends Invited',
      count: travelStats?.friendsInvited || 0,
      icon: PeopleRounded,
    },
  ];

  return (
    <Container
      sx={{
        marginTop: isMobile ? 2 : 0,
        marginBottom: isMobile ? 2 : 4,
      }}>
      <Grid
        container
        spacing={isMobile ? 2 : 3}
        direction="row"
        justifyContent="center">
        {stats.map((stat, index) => (
          <Grid item xs={4} sm={4} key={index}>
            <Box
              className={classes.stat}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center">
              <Typography
                color="primary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                  fontSize: isMobile ? '20px' : '22px',
                  marginBottom: 1,
                }}>
                <stat.icon sx={{ marginRight: 1 }} />
                {stat.count}
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  fontWeight: 500,
                  fontSize: isMobile ? '12px' : '14px',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                }}>
                {stat.copy}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default TravelStatsContainer;
