import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Modal,
  Typography,
  Grid,
  IconButton,
  InputBase,
  Button,
  Divider,
  Snackbar,
  SnackbarContent,
  Fade,
  CircularProgress,
  Collapse,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Close, LinkRounded } from '@mui/icons-material';
// import { EditIcon } from '@iconicicons/react';

import copy from 'copy-to-clipboard';

import {
  createTripInvitation,
  deleteTripInvitation,
  removeUserFromTrip,
} from '../../../../../redux/slices/TripV2';
import FeedbackPopup from '../../../../molecules/Feedback/FeedbackPopup';
import { HappyEmoji } from '../../../../atoms/Icon';
import { firebaseAuth } from '../../../../../provider/AuthProvider';
import UserAvatar from '../../../../molecules/UserAvatar';
import config from '../../../../config';
import { trackEvents, Events } from '../../../../../intercom';
import classList from '../../../../classList';
import getCfConnector from '../../../../cfConnector';
import { EVENTS, phTrackEvent } from '../../../../../analytics';
import DeleteModal from '../../../../molecules/Modal/DeleteModal';

const useStyles = makeStyles(({ palette }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  iconButton: {
    height: 24,
    width: 24,
  },
  icon: {
    height: 16,
    width: 16,
  },
  root: {
    outline: 0,
    backgroundColor: '#FFF',
    padding: '24px 0px',
    borderRadius: 8,
    boxShadow:
      '0px 8px 40px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',

    // repsonsivity
    minWidth: 300,
    width: '95%',
    maxWidth: 500,
  },

  input: {
    border: '1px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 8px',
    fontSize: 14,
  },
  accessDropdownAnchor: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    padding: '4px 8px',
    borderRadius: 4,
    alignItems: 'center',
  },
  activeAnchor: {
    backgroundColor: '#F4F4F4',
  },
  menu: {
    marginTop: 8,
    '& .MuiList-root': {
      paddingTop: 4,
      paddingBottom: 4,
      border: '1px solid #DEDDDD',
      borderRadius: 4,
    },
  },
  inviteButton: {
    marginLeft: 12,
    '&:disabled': {
      color: '#FFF',
      backgroundColor: '#DEDDDD',
    },
  },
  emailTag: {
    backgroundColor: palette.primary.light,
    fontSize: '0.875rem',
    padding: '4px 8px',
    borderRadius: 4,
  },

  // Invite via link styles
  copyLink: {
    color: '#ED702E',
    cursor: 'pointer',
    padding: '4px 8px',
    borderRadius: 4,
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: palette.primary.extraLight,
    },
  },
  link: {
    color: '#8A8A8A',
    fontSize: '0.875rem',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  linkContainer: {
    minWidth: 0,
    flexBasis: '50%',
    display: 'flex',
    flex: 1,
  },
  snackbarRoot: {
    position: 'absolute',
    bottom: 0,
  },
  snackbarContent: {
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#ED702E',
    minWidth: 'min-content',
    padding: '0.25em 3em',
    borderRadius: '4px 4px 0px 0px',
  },
}));

function UserAction({
  user,
  invitation,
  isInvitation,
  tripId,
  setFeedback = () => {},
}) {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleRemoveUser = async () => {
    if (isInvitation) {
      setLoader(true);
      try {
        await dispatch(
          deleteTripInvitation({
            variables: {
              tripId,
              id: invitation?.invitationId,
              joinTrip: false,
            },
          })
        );
      } catch (err) {
        // handle error
      }
      setLoader(false);
    } else {
      dispatch(
        removeUserFromTrip({
          variables: {
            id: invitation?.id,
            tripId,
            userId: user?.id,
          },
        })
      );
    }
    setFeedback({
      type: 'SUCCESS',
      feedbackText: 'Removed from your trip!',
    });
    setDeleteModalOpen(false);
  };

  return (
    <>
      <div
        className={classes.accessDropdownAnchor}
        style={{
          color: '#E68A63',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => setDeleteModalOpen(true)}>
        {loader ? <CircularProgress size={10} /> : 'Remove'}
      </div>
      <DeleteModal
        executeFunc={handleRemoveUser}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        emoji="😮"
        alertText="Slow down!"
        descriptionText={`You're about to remove ${
          user?.firstName || invitation?.email
        } from your trip. Are you sure you'd like to do that?`}
        confirmText="Yes, remove them."
        cancelText="No, keep them."
        modalProps={{
          style: { maxWidth: '392px' },
        }}
      />
    </>
  );
}

function AccessDropdown({ removeIndicator = false, ...props }) {
  const classes = useStyles();
  return (
    <div style={{ marginLeft: 8, display: 'flex' }}>
      {removeIndicator && <UserAction {...props} />}
      <div className={classes.accessDropdownAnchor}>
        {props?.isInvitation ? 'Invited' : 'Editor'}
      </div>
    </div>
  );
}

function UserListItem({
  itemText = '',
  profilePhoto = null,
  index = 0,
  customEmojiProps = {},
  customAvatarStyles = {},
  isOwner = false,
  removeIndicator,
  tripId,
  tripUser,
  isInvitation,
  setFeedback = () => {},
}) {
  return (
    <Grid container style={{ alignItems: 'center', marginTop: 12 }}>
      <UserAvatar
        user={{}}
        index={index}
        avatarProps={{
          style: {
            height: 28,
            width: 28,
            ...customAvatarStyles,
          },
          src: profilePhoto,
        }}
        showBorder={false}
        CustomContent={(props) => (
          <HappyEmoji {...props} {...customEmojiProps} />
        )}
      />
      <Typography
        style={{
          display: 'flex',
          flex: 1,
          marginLeft: 16,
        }}>
        {itemText}
      </Typography>
      {isOwner ? (
        <Typography
          style={{
            color: '#8A8A8A',
            fontSize: '0.875rem',
            marginRight: 8,
          }}>
          Owner
        </Typography>
      ) : (
        <AccessDropdown
          removeIndicator={removeIndicator}
          tripId={tripId}
          user={tripUser}
          isInvitation={isInvitation}
          invitation={tripUser}
          setFeedback={setFeedback}
        />
      )}
    </Grid>
  );
}

function ShareTripModal({
  tripId,
  context = 'trip',
  trackerMeta = null,
  open,
  handleClose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const trip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);

  // to store the current input email, and list of emails (future)
  const [shareEmail, setShareEmail] = useState('');
  const [shareEmailList, setShareEmailList] = useState([]);

  const [inputActive, setInputActive] = useState(false);
  const [snackbarText, setSnackbarText] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [feedback, setFeedback] = useState(null);

  // timer to close the feedback popup after 15s
  let feedbackTimer;
  useEffect(() => {
    if (feedback) {
      feedbackTimer = window.setTimeout(() => {
        setFeedback(null);
      }, 15000);
    }

    return () => window.clearTimeout(feedbackTimer);
  }, [feedback]);

  const handleInputBlur = () => {
    setInputActive(false);
    if (shareEmail !== '') setShareEmailList([shareEmail]);
    setShareEmail('');
  };

  const handleInputFocus = () => {
    setInputActive(true);
    setShareEmailList([]);
    setShareEmail(shareEmailList?.length > 0 ? shareEmailList[0] : '');
  };

  const isValidEmail = (
    !inputActive && shareEmailList?.length > 0 ? shareEmailList[0] : shareEmail
  )
    .toLowerCase()
    .match(/\S+@\S+\.\S+/);

  const { user = {} } = useContext(firebaseAuth);
  const { origin } = window.location;

  const inviteLink = `${origin}/join/${tripId}`;

  const handleCopyLink = () => {
    trackEvents(Events.InviteLinkCopied);
    phTrackEvent({
      event:
        context === 'dashboard'
          ? EVENTS.TRIPS_DASHBOARD.TRIP_SHARE_LINK_COPY_CLICK
          : EVENTS.TRIP_NAV.INVITE_LINK_COPY_CLICK,
      meta: trackerMeta,
    });
    copy(inviteLink);
    setSnackbarText('Link copied!');
  };

  const checkRepeatedEmail = (email) => {
    if (shareEmailList?.length > 0) {
      const allEmails = [
        ...(trip?.users?.map((tripUser) => tripUser.email) || []),
        ...(trip?.invitations?.map((invitation) => invitation.email) || []),
      ];
      if (allEmails?.includes(email)) {
        return true;
      }
    }
    return false;
  };

  const handleSendEmail = () => {
    if (showLoader) return;
    setShowLoader(true);
    const email = shareEmailList[0];
    if (checkRepeatedEmail(email)) {
      setFeedback({
        type: 'ERROR',
        feedbackText:
          'An user with this email has already been added or invited!',
      });
      setShowLoader(false);
      setShareEmailList([]);
      return;
    }
    dispatch(
      createTripInvitation({
        variables: {
          userEmail: email,
          tripId,
        },
      })
    )
      .then(async () => {
        /*
          TODO: Manage send email as part of the createInvitation call on the backend.
        */
        try {
          await (
            await getCfConnector()
          ).post(config.emailAPI, {
            toEmail: email,
            tripName:
              trip?.title ||
              (user?.firstName ? `${user?.firstName}'s trip` : 'a trip'),
            inviterName: user?.displayName,
            tripLink: inviteLink,
            tripCoverImage: trip?.coverImage === '' ? null : trip?.coverImage,
          });
        } catch (err) {
          setFeedback({
            type: 'ERROR',
            feedbackText:
              'There was a problem with sending the email! Please try again after sometime',
          });
        }
      })
      .then(() => {
        phTrackEvent({
          event:
            context === 'dashboard'
              ? EVENTS.TRIPS_DASHBOARD.TRIP_SHARE_INVITE_SEND
              : EVENTS.TRIP_NAV.INVITE_SEND,
          meta: trackerMeta,
        });
        setFeedback({ feedbackText: 'Invite sent!', type: 'SUCCESS' });
      })
      .catch((err) => {
        console.log(err);
        setFeedback({
          type: 'ERROR',
          feedbackText:
            'Looks like the user has already been added! If not, please try again.',
        });
      })
      .finally(() => {
        setShareEmailList([]);
        setShowLoader(false);
      });
  };

  const inputRef = useRef();

  function EmailTag({ tag }) {
    return (
      <div
        className={classes.emailTag}
        onClick={() => {
          inputRef?.current?.focus();
        }}>
        {tag}
      </div>
    );
  }

  function onClose() {
    setFeedback(null);
    handleClose();
  }

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <>
        <Collapse
          in={Boolean(feedback)}
          style={{
            minWidth: 300,
            width: '95%',
            maxWidth: 500,
            marginBottom: 8,
          }}>
          <FeedbackPopup {...feedback} />
        </Collapse>
        <Grid
          container
          className={`${classes.root} ${classList.shareTripModal}`}
          direction="column">
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0px 24px',
            }}>
            <Typography
              variant="h3Sub"
              style={{ flex: '1 0', display: 'flex', fontWeight: 600 }}>
              {trip?.title}
            </Typography>
            <IconButton className={classes.iconButton} onClick={onClose}>
              <Close className={classes.icon} />
            </IconButton>
          </Grid>

          {
            // Invite via Email
          }
          <Grid
            container
            direction="column"
            style={{ marginTop: 24, padding: '0px 24px' }}>
            <Grid item>
              <Typography
                style={{
                  color: '#222',
                  fontWeight: 600,
                }}>
                Invite a tripmate
                <Fade
                  in={showLoader}
                  style={{
                    transitionDelay: showLoader ? '800ms' : '0ms',
                  }}
                  unmountOnExit>
                  <CircularProgress size={10} style={{ marginLeft: 8 }} />
                </Fade>
              </Typography>
            </Grid>
            <Grid
              item
              style={{ marginTop: 12, display: 'flex', flexDirection: 'row' }}>
              <InputBase
                inputRef={inputRef}
                placeholder={
                  shareEmailList?.length > 0 ? '' : 'Add an email address'
                }
                className={classes.input}
                fullWidth
                value={shareEmail}
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.currentTarget.blur();
                  }
                }}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onChange={(e) => setShareEmail(e.target?.value?.trim())}
                inputProps={{
                  style: {
                    padding: 0,
                  },
                }}
                startAdornment={
                  !inputActive &&
                  shareEmailList?.length > 0 &&
                  shareEmailList?.map((userEmail, idx) => (
                    <EmailTag tag={userEmail} key={idx} />
                  ))
                }
                endAdornment={<AccessDropdown />}
              />
              <Button
                disabled={!isValidEmail}
                onClick={handleSendEmail}
                className={classes.inviteButton}>
                Invite
              </Button>
            </Grid>
          </Grid>

          {
            // trip users container
          }
          <div style={{ marginTop: 4, padding: '0px 16px 0px 24px' }}>
            <Grid
              container
              direction="row"
              style={{
                overflowY: 'scroll',
                maxHeight: 120,
                minHeight: 0,
              }}>
              {[
                ...(trip?.users || []),
                ...(trip?.invitations?.map(({ email: userEmail, id }) => ({
                  firstName: userEmail,
                  isInvitation: true,
                  invitationId: id,
                })) || []),
              ]?.map((tripUser, index) => (
                <Grid item xs={12} key={tripUser?.id || tripUser?.invitationId}>
                  <UserListItem
                    key={index}
                    profilePhoto={tripUser?.profilePicture}
                    itemText={`${tripUser?.firstName || ''} ${
                      tripUser?.lastName || ''
                    }`}
                    index={index}
                    isOwner={tripUser?.id === trip?.owner?.id && index === 0}
                    removeIndicator={user?.uid === trip?.owner?.firebaseUid}
                    tripId={tripId}
                    isInvitation={tripUser?.isInvitation}
                    tripUser={tripUser}
                    setFeedback={setFeedback}
                  />
                </Grid>
              ))}
            </Grid>
          </div>

          {
            // Invite via Link
          }
          <Divider style={{ marginTop: 24 }} />

          <div style={{ position: 'relative' }}>
            <Snackbar
              className={classes.snackbarRoot}
              open={Boolean(snackbarText)}
              autoHideDuration={3000}
              onClose={() => setSnackbarText(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              TransitionComponent={Fade}>
              <SnackbarContent
                className={classes.snackbarContent}
                message={snackbarText}
              />
            </Snackbar>
          </div>
          <Grid
            container
            direction="column"
            style={{ marginTop: 24, flex: 1, padding: '0px 24px' }}>
            <Grid item>
              <Typography
                style={{
                  color: '#222',
                  fontWeight: 600,
                }}>
                Invite via link
              </Typography>
            </Grid>
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
              <LinkRounded
                sx={{
                  height: 20,
                  width: 20,
                  color: '#8A8A8A',
                }}
              />
              <Typography
                className={classes.accessDropdownAnchor}
                style={{ flex: 1 }}>
                Anyone with this link can edit
              </Typography>
              <Typography className={classes.accessDropdownAnchor}>
                Editor
              </Typography>
            </div>
            <Grid
              container
              className={classes.input}
              style={{
                marginTop: 12,
                flexWrap: 'wrap',
                alignItems: 'center',
              }}>
              <div className={classes.linkContainer}>
                <Typography className={classes.link}>{inviteLink}</Typography>
              </div>
              <Typography className={classes.copyLink} onClick={handleCopyLink}>
                Copy
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
}

export default ShareTripModal;
