import { useState } from 'react';
import { Box, Chip, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { Close, DeleteOutlineRounded } from '@mui/icons-material';
import { capitalize } from 'lodash';
import TransportationBlockImport from './ItineraryComponents/TransportationBlockImport';
import AccommodationBlockImport from './ItineraryComponents/AccommodationBlockImport';
import TodoItemImport from './ItineraryComponents/TodoItemImport';
import {
  createFlight,
  createTransportation,
  deleteTransportation,
} from '../../../redux/slices/Transportation';
import { createActivity, deleteActivity } from '../../../redux/slices/Activity';
import {
  createAccommodation,
  deleteAccommodation,
} from '../../../redux/slices/Accommodation';
import {
  deleteImportItem,
  updateFile,
  updateImportAttachedTo,
} from '../../../redux/slices/Files';
import actions from '../../../redux/actions';
import { updateTrip } from '../../../redux/slices/Trips';
import { useMapUtils } from '../../organisms/MapUtils';
import { createLocation } from '../../../redux/slices/Location';
import AddToTripV from './AddToTrip';
import { createSection } from '../../../redux/slices/sharedThunks';
import { EVENTS, phTrackEvent } from '../../../analytics';

function SmartImport({
  fileId,
  item: initialItem,
  tripId,
  itemIndex,
  importId,
  attachedToItem,
  width = '300px',
  flexDirection,
  isForwardedFile,
  isModalView,
  showAsAddedonAdded,
  fileIndex,
}) {
  const attachmentPopperStyles = makeStyles((theme) => ({
    attachItemsButton: {
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 4,
    },
    chip: {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontSize: 12,
      maxWidth: '120px',
      padding: '13px 4px',
      '& .MuiChip-deleteIcon': {
        color: theme.palette.primary.main,
      },
    },
    attachButton: {
      marginTop: 8,
    },
  }));

  const classes = attachmentPopperStyles();
  const dispatch = useDispatch();
  const [openBlade, setOpenBlade] = useState(false);
  const trip = useSelector((state) => state.Trips.trips[tripId]);
  const thingsToDo = useSelector((state) => state.Section.sections);
  const user = useSelector((state) => state.Auth.firebaseUser);
  const transports = useSelector((state) => state.Transportation.transports);
  const accommodations = useSelector((state) => state.Accommodation.hotels);
  const activties = useSelector((state) => state.Activity.todos);
  const [locationText, setLocationText] = useState('');
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const [item, setItem] = useState(initialItem);

  const {
    createMapPinForPlaceId,
    getPlaceDetails,
    createMapPinForPlace,
    extractAddressComponents,
  } = useMapUtils();

  const getMapPinForActivity = async () => {
    if (initialItem?.placeId) {
      try {
        const {
          title,
          photo,
          rating,
          website,
          maps,
          ratingCount,
          long,
          lat,
          types,
          categoryId,
          openingHoursObj,
          addressComponents,
          formattedAddress,
          formattedPhoneNumber,
        } = await getPlaceDetails(initialItem?.placeId, 'ACTIVITY');
        const address = extractAddressComponents(addressComponents);
        const mapPin = await createMapPinForPlace(
          {
            title,
            photo,
            rating,
            website,
            maps,
            ratingCount,
            long,
            lat,
            types,
            placeId: initialItem?.placeId,
            categoryId,
          },
          'ACTIVITY',
          {
            openingHoursObj,
            addressComponents,
            formattedAddress,
            formattedPhoneNumber,
            maps,
            website,
          }
        );
        return {
          mapPin,
          address,
        };
      } catch (error) {
        return {
          mapPin: null,
          address: null,
        };
      }
    }
    return {
      mapPin: null,
      address: null,
    };
  };

  const getMapPinForAccommodation = async () => {
    if (initialItem?.placeId) {
      const mapPinDetails = await createMapPinForPlaceId(
        initialItem?.placeId,
        'ACCOMMODATION'
      );
      return mapPinDetails;
    }
    return null;
  };
  const attachToItem = async (itemId) => {
    await dispatch(
      updateImportAttachedTo({
        variables: { importId, itemIndex, attachedToItem: itemId },
      })
    );
    if (isForwardedFile) {
      dispatch(
        actions.Auth.updateForwardedFileImportItemAttachedToItemId({
          index: fileIndex,
          fileId,
          itemIndex,
          attachedToItemId: itemId,
        })
      );
      await dispatch(
        updateFile({
          variables: {
            id: fileId,
            attachedToID: itemId ? tripId : '',
            tripId: itemId ? tripId : '',
            attachedToType: itemId ? 'Trip' : '',
          },
        })
      );
      await dispatch(
        updateTrip({
          variables: {
            id: tripId,
            files: itemId
              ? [...(trip?.files || []), fileId]
              : trip?.files || [],
          },
          addFiles: !itemId ? [fileId] : [],
        })
      );
    }
  };
  const detachItem = async () => {
    await attachToItem(null);
    if (['bus', 'train', 'other', 'flight'].includes(item?.type)) {
      await dispatch(
        deleteTransportation({
          variables: { id: attachedToItem, tripId },
          extra: { tripId },
        })
      );
    }
    if (item?.type === 'activity') {
      const attachedToSectionId = activties[attachedToItem]?.thingsToDoId;
      await dispatch(
        deleteActivity(
          {
            variables: {
              id: attachedToItem,
              thingsToDoId: attachedToSectionId,
            },
            sectionId: attachedToSectionId,
          },
          {
            mapPin: activties[attachedToItem]?.mapPin,
            sectionId: attachedToSectionId,
          }
        )
      );
    } else {
      const attachedLoacationId = accommodations[attachedToItem]?.locationId;
      await dispatch(
        deleteAccommodation({
          variables: { id: attachedToItem, locationId: attachedLoacationId },
          extra: { locationId: attachedLoacationId },
        })
      );
    }
  };

  const getAttachedItemName = () => {
    if (['bus', 'train', 'other', 'flight'].includes(item?.type)) {
      if (transports[attachedToItem]?.details[0]) {
        return (
          transports[attachedToItem]?.details[0]?.flightNumber ||
          transports[attachedToItem]?.details[0]?.toAirport
        );
      }
    }
    return (
      accommodations[attachedToItem]?.name ||
      activties[attachedToItem]?.title ||
      null
    );
  };

  const attachedItemExists = () => {
    if (!attachedToItem || !item?.type) return false;
    if (
      ['bus', 'train', 'other', 'flight'].includes(item?.type) &&
      transports[attachedToItem]
    ) {
      return true;
    }
    return Boolean(accommodations[attachedToItem] || activties[attachedToItem]);
  };
  const onAddToTrip = async ({
    locationId = null,
    sectionId = null,
    locationIndex = null,
  } = {}) => {
    // if (isAddedToTrip) return;
    if (item?.type === 'flight') {
      // create flights for each stop created
      const flights = await Promise.all(
        item.details.map(async (flight) => {
          const newCreatedFlight = await dispatch(
            createFlight({
              variables: {
                ...flight,
                files: [fileId],
              },
              extra: {
                attachedToType: 'Transportation',
                tripId,
                type: 'flight',
              },
            })
          );
          return newCreatedFlight?.payload?.createFlight;
        })
      );

      const transportation = await dispatch(
        createTransportation({
          variables: {
            trip: tripId,
            type: 'flight',
            details: flights.map((flight) => flight.id),
            index: locationIndex - 1,
          },
          extra: {
            attachedFlights: flights,
            index: locationIndex - 1,
          },
        })
      ).unwrap();
      const element = document.getElementById(
        `scrollable-container-${locationId}`
      );
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      await attachToItem(transportation?.createTransportation?.id);
    }

    if (['bus', 'train', 'other'].includes(item?.type)) {
      const newCreatedFlight = await dispatch(
        createFlight({
          variables: {
            ...item?.details[0],
            files: [fileId],
          },
          extra: {
            type: item?.type,
            attachedToType: 'Transportation',
            tripId,
          },
        })
      );

      const transportation = await dispatch(
        createTransportation({
          variables: {
            trip: tripId,
            type: item?.type,
            details: [newCreatedFlight?.payload?.createFlight?.id],
            index: locationIndex - 1,
          },
          extra: {
            attachedFlights: [newCreatedFlight?.payload?.createFlight],
            index: locationIndex - 1,
          },
        })
      ).unwrap();
      await attachToItem(transportation?.createTransportation?.id);
    }

    if (item?.type === 'activity') {
      try {
        const newActivity = {
          ...item,
          thingsToDoId: sectionId,
          index: thingsToDo[sectionId]?.todos?.length || 0,
          files: [fileId],
        };
        const { mapPin, address } = await getMapPinForActivity();
        if (mapPin) {
          newActivity.mapPin = mapPin.id;
        }
        const activity = await dispatch(
          createActivity({
            variables: {
              ...newActivity,
              ...(address || {}),
            },
            sectionId,
            index: thingsToDo[sectionId]?.todos?.length,
            extra: {
              attachedToType: 'Activity',
              tripId,
            },
          })
        ).unwrap();
        await attachToItem(activity?.createTodo?.id);
        const element = document.getElementById(`todo-header-${sectionId}`);
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } catch (error) {
        console.log('error', error);
      }
    }
    if (item?.type === 'accommodation') {
      const mapPinDetails = await getMapPinForAccommodation();
      const accommodation = await dispatch(
        createAccommodation({
          variables: {
            ...item,
            user: [{ user: user.uid, option: '' }],
            type: 1,
            trip: tripId,
            index: 0,
            locationId,
            files: [fileId],
            mapPin: mapPinDetails?.id,
          },
          extra: {
            locationId,
            attachedToType: 'Accommodation',
            tripId,
          },
        })
      ).unwrap();
      const element = document.getElementById(
        `scrollable-container-${locationId}`
      );
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      await attachToItem(accommodation?.createAccommodation?.id);
    }
    // once the item is added to the trip, change the tab
    if (isForwardedFile) {
      dispatch(actions.View.setForwardedFilesModalTabValue(1));
    }
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_HISTORY_BLADE_ADD,
    });
  };

  const handleAddLocation = async (location) => {
    const mapPinId = await createMapPinForPlaceId(
      location?.place_id,
      'LOCATION'
    );
    const res = await dispatch(
      createLocation({
        variables: {
          name: location?.structured_formatting.main_text,
          tripID: tripId,
          mapPin: mapPinId,
          index: 0,
        },
      })
    );
    if (item?.type === 'activity') {
      const section = await dispatch(
        createSection({
          variables: {
            trip: tripId,
            locationId: res.payload.createLocation.id,
            name: '',
            startDate: null,
            endDate: null,
            todos: [],
          },
          index: 0,
          locationId: res.payload.createLocation.id,
        })
      );
      onAddToTrip({
        sectionId: section.payload.createThingsToDo.id,
      });
    } else {
      onAddToTrip({
        locationId: res.payload.createLocation.id,
        locationIndex: 0,
      });
    }

    // setOpenSearchbar(false);
  };

  return (
    <Grid
      width="100%"
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
      container
      direction={flexDirection}
      position="relative"
      gap={isModalView && openBlade ? 2 : 0}>
      {showDeleteIcon && (
        <DeleteOutlineRounded
          sx={{
            position: 'absolute',
            top: 20,
            left: 0,
            cursor: 'pointer',
            fontSize: '1.2rem',
            color: '#8A8A8A',
            '&:hover': {
              cursor: 'pointer',
              color: '#474747',
            },
          }}
          onClick={() => {
            phTrackEvent({
              event: EVENTS.SMART_IMPORT.PLAN_IMPORT_BLADE_DELETE,
            });
            dispatch(
              deleteImportItem({
                variables: { importId, itemIndex },
              })
            );
          }}
        />
      )}
      <Grid item xs={9} display="flex">
        <Box
          sx={{
            p: isModalView ? 0 : 2,
            pl: {
              xs: 0,
              sm: 4,
            },
            width: '100%',
            minWidth: {
              xs: '100%',
              sm: width,
            },
          }}>
          {/* <Heading {...headingProps} /> */}

          {item?.__typename === 'Accommodation' ? (
            <AccommodationBlockImport
              item={item}
              setItem={setItem}
              openBlade={openBlade}
              setOpenBlade={setOpenBlade}
            />
          ) : item?.__typename === 'Todo' ? (
            <TodoItemImport
              todo={item}
              setItem={setItem}
              openBlade={openBlade}
              setOpenBlade={setOpenBlade}
            />
          ) : (
            <TransportationBlockImport
              item={item}
              setItem={setItem}
              openBlade={openBlade}
              setOpenBlade={setOpenBlade}
            />
          )}
        </Box>
      </Grid>

      <Grid
        item
        xs={3}
        display="flex"
        justifyContent="flex-end"
        alignItems="center">
        {attachedItemExists() && !showAsAddedonAdded ? (
          <Chip
            size="small"
            label={getAttachedItemName()}
            className={classes.chip}
            variant="outlined"
            onDelete={detachItem}
            deleteIcon={<Close />}
          />
        ) : (
          <AddToTripV
            searchBarProps={{
              autoSearch: true,
              openOnFocus: true,
              isCitySearch: true,
              showBackdrop: true,
              enterToSelect: true,
              tripLocation: false,
              disableLocationBias: true,
              placeholder: 'Name your Destination...',
              popperPlacement: 'bottom',
              locationBias: null,
              handleSelect: (selectedLocation) => {
                handleAddLocation(selectedLocation);
              },
              value: locationText,
              controlledValue: locationText,
              onChange: setLocationText,
            }}
            showHeading={item?.type === 'activity'}
            isAdded={attachedItemExists()}
            handleSelectHeading={
              item?.type === 'activity'
                ? (sectionId) => {
                    onAddToTrip({
                      sectionId,
                    });
                  }
                : undefined
            }
            handleSelectLocation={async (locationId, index) => {
              if (item?.type === 'activity') {
                // temporarily disabled because it's being triggered along with handleSelectHeading, causing the section to be created twice
                // const section = await dispatch(
                //   createSection({
                //     variables: {
                //       trip: tripId,
                //       locationId,
                //       name: '',
                //       startDate: null,
                //       endDate: null,
                //       todos: [],
                //     },
                //     index: 0,
                //     locationId,
                //   })
                // );
                // onAddToTrip({
                //   sectionId: section.payload.createThingsToDo.id,
                // });
              } else {
                onAddToTrip({ locationId, locationIndex: index });
              }
            }}
            popoverTitle={
              ['bus', 'train', 'other', 'flight', 'accommodation'].includes(
                item?.type
              )
                ? `${
                    item?.type === 'other'
                      ? 'Transport'
                      : capitalize(item?.type)
                  } Destination: `
                : null
            }
          />
        )}
      </Grid>
    </Grid>
  );
}

export default SmartImport;
