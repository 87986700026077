import { createTheme } from '@mui/material/styles';

import palette from './colors';
import typography from './typography';
import breakpoints from './breakpoints';

const pilotThemeV2 = createTheme({
  palette,
  typography,
  breakpoints,
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: palette.text.main,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          borderRadius: 24,
          padding: '8px 16px',
          backgroundColor: palette.primary.main,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: palette.primary.light,
            color: 'white',
          },
          '&:disabled': {
            fontFamily: 'Inter',
            backgroundColor: palette.system.lightgrey,
            color: palette.text.light,
          },
        },
        startIcon: {
          marginRight: 8,
          marginLeft: '-4px',
        },
        endIcon: {
          marginLeft: 8,
          marginRight: '-4px',
        },
        outlined: {
          borderRadius: '0',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          boxShadow:
            '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },

    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          ...typography.h3,
          fontWeight: 400,
          color: palette.text.light,
          textTransform: 'none',
          '&:hover': {
            color: palette.primary.light,
          },
          '&.Mui-selected': {
            color: palette.primary.main,
            fontWeight: 700,
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#222',
          backgroundColor: palette.system.white,
          '&:hover': {
            backgroundColor: palette.primary.extraLight,
            color: palette.primary.main,
            transition: 'color 10ms ease-out',
          },
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          color: '#222',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            color: palette.primary.main,
            transition: 'color 10ms ease-out',
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: '#F6F3F1',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid transparent !important',
            outline: 'none',
          },
          '& .MuiInputBase-input': {
            padding: '10px 12px',
          },
        },
      },
    },
  },
});

export default pilotThemeV2;
