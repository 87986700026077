import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  Popover,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnnouncementRounded,
  EventNoteRounded,
  StarHalfRounded,
  SettingsRounded,
  ExitToAppRounded,
  LocalActivityRounded,
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  FavoriteBorderRounded,
} from '@mui/icons-material';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { firebaseAuth } from '../../../../provider/AuthProvider';
import { Tiktok } from '../../../atoms/Icon';
import SocialIcon from '../../SocialIcon';
import SettingsPopoverOption from './SettingsPopoverOption';
import actions from '../../../../redux/actions';
import FLAGS from '../../../../featureFlags';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    borderRadius: 0,
    padding: 24,
    width: 286, // this is fixed in the designs, hence implemented a fixed width.
    '& .MuiListItem-root': {
      '&:hover': {
        backgroundColor: '#FFF',
      },
    },
  },
  avatar: {
    backgroundColor: palette.primary.main,
    width: 48,
    height: 48,
  },
  profile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileLink: {
    textTransform: 'capitalize',
    marginLeft: 16,
  },
  listItemProfile: {
    padding: 8,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 16,
    borderBottom: '1px solid #D2D2D2',
    '&:hover': {
      cursor: 'pointer',
      '& $profileLink': {
        color: palette.primary.main,
        textDecoration: 'underline',
      },
    },
  },
  socialContainer: {
    marginTop: 18,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tiktok: {
    height: 16,
    width: 16,
    '& path': {
      fill: '#a7a7a7',
    },
    '&:hover': {
      '& path': {
        fill: '#000000',
      },
      cursor: 'pointer',
    },
  },
}));

function SettingsPopover({ anchorEl, setAnchorEl, letters, setActiveItem }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* Feature flag start */
  const showPwywModal = useFeatureFlagEnabled(FLAGS.PWYW);
  /* Feature flag end */

  const handleLinkOpen = (link) => {
    window.open(link, '_blank');
  };
  const { user, firstName, lastName, handleSignout } = useContext(firebaseAuth);
  const userProfilePicture = useSelector(
    (state) => state.Auth.userData.user?.profilePicture
  );
  const handlePwywClick = () => {
    dispatch(
      actions.View.setContribute({ action: 0, source: 'dashboard-menu' })
    );
  };

  const menuItems = [
    // {
    //   Key: 0,
    //   Icon: <OffersIcon />,
    //   content: 'Offers',
    //   id: 'navmenu-offers',
    //   action: () => navigate(`/offers`),
    // },
    {
      Key: 1,
      Icon: <AnnouncementRounded />,
      content: 'Report a bug',
      id: 'navmenu-bug-report',
      action: () => handleLinkOpen('https://forms.gle/YxBh5j1wadZ88qif7'),
    },
    ...(showPwywModal
      ? [
          {
            Key: 6,
            Icon: <FavoriteBorderRounded />,
            content: 'Help Pilot grow',
            id: 'navmenu-support',
            action: handlePwywClick,
          },
        ]
      : []),
    {
      Key: 2,
      Icon: <EventNoteRounded />,
      content: 'Product requests',
      id: 'navmenu-product-requests',
      action: () => handleLinkOpen('https://www.pilotplans.com/roadmap'),
    },
    {
      Key: 1.5,
      Icon: <LocalActivityRounded />,
      content: 'Plan group trips',
      id: 'navmenu-plan-group-trips',
      action: () =>
        handleLinkOpen('https://www.pilotplans.com/retreat-planning-service'),
    },
    {
      Key: 3,
      Icon: <StarHalfRounded />,
      content: 'Leave a review',
      id: 'navmenu-leave-review',
      action: () =>
        handleLinkOpen('https://www.trustpilot.com/evaluate/pilotplans.com'),
    },
    {
      Key: 4,
      Icon: <SettingsRounded />,
      content: 'Settings',
      id: 'navmenu-settings',
      action: () => {
        navigate(`/settings`);
        setActiveItem(-1);
      },
    },
    {
      Key: 5,
      Icon: <ExitToAppRounded />,
      id: 'navmenu-logout',
      content: 'Log out',
      action: handleSignout,
    },
  ];

  const socialIcons = [
    {
      Key: 1,
      Icon: <Facebook />,
      link: 'https://www.facebook.com/pilotplans',
      hoverColor: '#1877F2',
    },
    {
      Key: 2,
      Icon: <Instagram />,
      link: 'https://www.instagram.com/pilotplans/',
      hoverColor: '#E4405F',
    },
    {
      Key: 3,
      Icon: <LinkedIn />,
      link: 'https://www.linkedin.com/company/pilotplans/',
      hoverColor: '#0077B5',
    },
    {
      Key: 4,
      Icon: <Tiktok className={classes.tiktok} />,
      link: 'https://www.tiktok.com/@pilotplans',
      hoverColor: '#000000',
    },
    {
      Key: 5,
      Icon: <Pinterest />,
      link: 'https://www.pinterest.ca/pilotplans/',
      hoverColor: '#BD081C',
    },
  ];

  return (
    <Popover
      id="settings-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <List className={classes.root}>
        <ListItem
          className={classes.listItemProfile}
          onClick={() => {
            navigate(`/profile/${user.uid}`);
          }}>
          <div className={classes.profile}>
            <Avatar src={userProfilePicture} className={classes.avatar}>
              {letters}
            </Avatar>
            <div className={classes.flexColumn}>
              <Typography variant="h4">
                {firstName} {lastName}
              </Typography>
              <Typography variant="h5" className={classes.profileLink}>
                View profile
              </Typography>
            </div>
          </div>
        </ListItem>
        {menuItems.map((listItem) => (
          <SettingsPopoverOption key={listItem.Key} {...listItem} />
        ))}
        <Divider />
        <Box className={classes.socialContainer}>
          {socialIcons.map((icon) => (
            <SocialIcon key={icon.Key} {...icon} />
          ))}
        </Box>
      </List>
    </Popover>
  );
}

export default SettingsPopover;
