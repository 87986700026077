const ITEM_TYPES = {
  ACCOMMODATION: 'Accommodation',
  ACTIVITY: 'Activity',
  DESTINATION: 'Destination',
  TRANSPORTATION: 'Transportation',
  HEADING: 'Heading',
  TRANSPORT: 'Transport',
};

export const COST_TYPES = {
  0: 'PER_PERSON',
  1: 'TOTAL',
};

export const TRANSPORT_TYPES = {
  FLIGHT: 'flight',
  BUS: 'bus',
  OTHER: 'other',
};

export const DROP_TYPES = {
  ITEM: 'item',
  TRIP: 'trip',
};

export const DROP_LEVELS = {
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
};

export const ITEM_DRAG_LEVELS = {
  Destination: 'L1',
  Heading: 'L2',
  Accommodation: 'L2',
  Activity: 'L3',
  Transportation: 'L1',
};

export const ITEM_DROP_LEVELS = {
  Destination: 'L2',
  Heading: 'L3',
  Accommodation: null,
  Activity: null,
  Transportation: null,
};

export const SAVED_ITEM_TYPES = {
  HOTEL: 'HOTEL',
  ACTIVITY: 'ACTIVITY',
};

export const MAPPINS_TYPES = {
  ACTIVITY: 'ACTIVITY',
  LOCATION: 'LOCATION',
  ACCOMMODATION: 'ACCOMMODATION',
};

export const LAST_EDITED_DESTINATION_KEY = 'lastEditedDestination';

export default ITEM_TYPES;
