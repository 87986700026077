import React from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function InHotelTaxesModal({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: 600,
          margin: 'auto',
          padding: '48px 32px 30px 32px',
        },
      }}>
      <Box>
        <DialogTitle sx={{ p: 0 }} variant="h2">
          Tourism & Lodging Taxes
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 36,
            top: 30,
            color: 'grey.500',
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 0, mt: 2 }}>
          <Typography sx={{ mb: 2, mt: 2 }}>
            Some regions charge a mandatory hotel tax on short-term stays—also
            called an occupancy, tourism, or lodging tax.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            We can't include these taxes in the price you pay upfront, since
            they must be collected directly by the hotel.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Revenue from these taxes typically goes toward supporting local
            tourism, infrastructure, and community services.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 0, mt: 2, justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            disableRipple
            sx={{
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
            }}
            onClick={onClose}>
            Done
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default InHotelTaxesModal;
