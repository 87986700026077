import { Box, Grid, Tooltip, Typography, Fade } from '@mui/material';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import PortalAwareDraggableItem from './PortalAwareDraggableItem';
import { ActivityIcon, DraggableIconPlaceHolder } from '../../../atoms/Icon';
import { ITEM_DRAG_LEVELS } from '../../../../const';

function ActivityButton({
  commonStyles,
  isClicked,
  handleAddActivity,
  isKeyboardShortcutEnabled,
  tripId,
}) {
  return (
    <Grid item sx={{ mr: 1 }}>
      <Droppable
        droppableId={`TRIP-${tripId}-activity`}
        type={ITEM_DRAG_LEVELS.Activity}
        isDropDisabled>
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}>
            <Tooltip
              title={
                <Box display="flex" alignItems="center">
                  <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                    Activity
                  </Typography>
                  {isKeyboardShortcutEnabled && (
                    <Box sx={commonStyles.shortcutKey}>A</Box>
                  )}
                </Box>
              }
              enterDelay={500}
              TransitionComponent={Fade}
              placement="top"
              arrow
              componentsProps={commonStyles.componentsProps}>
              <Draggable draggableId="commandBar-activity" index={0}>
                {(provided, snapshot) => (
                  <PortalAwareDraggableItem snapshot={snapshot}>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <Box
                        sx={
                          snapshot.isDragging
                            ? commonStyles.draggableExpandedState
                            : isClicked
                            ? commonStyles.draggableIconClickStyle
                            : commonStyles.hoverStyle
                        }
                        onClick={handleAddActivity}>
                        <ActivityIcon />
                      </Box>
                    </div>
                  </PortalAwareDraggableItem>
                )}
              </Draggable>
              {droppableProvided.placeholder &&
                droppableSnapshot.isUsingPlaceholder && (
                  <DraggableIconPlaceHolder />
                )}
            </Tooltip>
          </div>
        )}
      </Droppable>
    </Grid>
  );
}
export default ActivityButton;
