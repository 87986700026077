import { Box, Grid, Typography } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { CalendarIcon } from '../../../atoms/Icon';
import { formatDateWithTime } from '../Stays/HotelDetails/utils';

function CancellationPolicy({
  isRefundable,
  cancellationPolicies,
  isModal = false,
}) {
  return (
    <Box>
      <Typography
        noWrap
        minWidth={200}
        sx={{
          mb: isModal ? 4 : 2,
          fontSize: isModal ? '20px' : '16px',
          fontWeight: 700,
        }}
        id="hotel-rules-cancellation-policy">
        Cancellation Policy
      </Typography>
      {isRefundable ? (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            {cancellationPolicies?.map((policy) =>
              policy?.refundabilityType === 'FR' ? (
                <>
                  <Box sx={{ display: 'flex', mb: 1 }}>
                    <CalendarIcon />
                    <Typography variant="h4" ml={1}>
                      {`Fully refundable until ${formatDateWithTime(
                        policy?.cancelTime
                      )}`}
                    </Typography>
                  </Box>
                  <Typography variant="h5" ml={4}>
                    This booking is fully refundable before{' '}
                    <strong>{formatDateWithTime(policy?.cancelTime)}</strong>.
                    Cancellations made before this will incur no charge.
                  </Typography>
                </>
              ) : (
                <>
                  <Box sx={{ display: 'flex', mb: 1 }}>
                    <CalendarIcon />
                    <Typography variant="h4" ml={1}>
                      {`Partially refundable until ${formatDateWithTime(
                        policy?.cancelTime
                      )}`}
                    </Typography>
                  </Box>
                  <Typography variant="h5" ml={4}>
                    This booking is partially refundable before{' '}
                    <strong>{formatDateWithTime(policy?.cancelTime)}</strong>.
                    Cancellations made before{' '}
                    {formatDateWithTime(policy?.cancelTime)} will incur a fee of{' '}
                    <strong>{policy?.amount}</strong>.
                  </Typography>
                </>
              )
            )}
          </Grid>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <ErrorOutlineOutlined sx={{ mr: 1 }} />
            <Typography variant="h5" sx={{ color: '#80746C' }}>
              Note that cancellation costs are based on the total booking value.
              All dates and times mentioned are in{' '}
              {cancellationPolicies?.[0]?.timezone}.
            </Typography>
          </Box>
        </Grid>
      ) : (
        <Typography variant="h5" color="text.secondary" mb={2}>
          This booking is non-refundable, for more flexibility, return to choose
          rooms and select a different cancellation policy.
        </Typography>
      )}
    </Box>
  );
}

export default CancellationPolicy;
