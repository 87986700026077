import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '../../../molecules/Logo';

const useStyles = makeStyles(({ breakpoints }) => ({
  appbar: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #DEDDDD',
    padding: '24px 60px',
    [breakpoints.down('lg')]: {
      padding: '18px 8px',
    },
    [breakpoints.down('sm')]: {
      padding: '6px 8px',
    },
    zIndex: 1098,
  },
  toolbar: {
    minHeight: '40px',
    [breakpoints.down('lg')]: {
      minHeight: '65px',
    },
    [breakpoints.down('sm')]: {
      minHeight: '80px',
    },
  },
  startContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  endContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
}));

function CheckoutNavbar() {
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" className={classes.appbar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div style={{ marginLeft: '10px' }}>
            <Logo fullLogo imageWidth="100px" useV2 />
          </div>
        </Toolbar>
      </AppBar>
      <div
        style={{
          minHeight: 93,
        }}
      />
    </>
  );
}

export default CheckoutNavbar;
