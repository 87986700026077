import React, { useState, useRef, useEffect } from 'react';
import {
  Popper,
  List,
  ListItem,
  ClickAwayListener,
  ListItemIcon,
  Divider,
  ListItemText,
  ButtonGroup,
} from '@mui/material';
import {
  ArrowDropDownRounded,
  AddCircleOutlineRounded,
} from '@mui/icons-material';

import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

import { useMapUtils } from '../../organisms/Map';
import { ButtonDefault } from '../../../atoms/Button/index';
import { useSounds, SOUNDS } from '../../../../sounds';
import actions from '../../../../redux/actions';
import useCityPlaceId from './useCityPlaceId';
// import { updateSessionStorageForLastEditedSection } from '../../../../utils';
import { useTour } from '../../../molecules/Tour/useTour';
import { stepName } from '../../../../assets/onboarding/steps';

import ITEM_TYPES from '../../../../const';
import { createItem } from '../../../../redux/slices/Item';
import ItemDropDown from './ItemDropdown';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1121,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
  listItemText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  buttonContainer: {
    display: 'flex',
  },
  trashIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s ease-in-out',
  },
  trashIcon: {
    cursor: 'pointer',
    color: 'grey',
    marginRight: 8,
  },
}));

function AddToTripButtonNew({
  tripId,
  placeDetails,
  // info we aren't storing in the backend
  additionalPlaceDetails = {},
  id: idSelector,
  className: classSelector,
  buttonProps = {},
  isAdded = false,
  customAnchor = null,
  CustomButton = null,
  setLoader = () => {},
  handleClose = () => {},
  trigger = null,
  handleAddActivity = () => {},
  setToastMessage = () => {},
  setShowSnackBar = () => {},
  isCommandBarEnabled = false,
}) {
  const classes = useStyles({ isCommandBarEnabled });
  const [anchorEl, setAnchorEl] = useState(null);
  const { playSound } = useSounds();
  const dispatch = useDispatch();
  // const currentTrip = useSelector((state) => state.TripsV2.tripsAtc[tripId]);
  const destinationIds = useSelector((state) =>
    Object.values(state.Item.items).filter(
      (item) => item.tripId === tripId && item.type === ITEM_TYPES.DESTINATION
    )
  );
  const items = useSelector((state) => state.Item.items);
  const { getTour } = useTour();
  const ref = useRef(null);
  const hoverTimeRef = useRef(null);
  const [open, setOpen] = useState(false);
  const commandBarState = useSelector((state) => state.View.commandBarV2);
  const { activeLocationId, activeSectionId } = commandBarState;
  const setLocationPopperOpen = (value) =>
    dispatch(actions.View.setLocationPopperOpen(value));

  const popperId = open ? 'search-location-popper' : undefined;

  const { placeId, cityName, setCityName, reset } = useCityPlaceId();

  const {
    createMapPinForPlace,
    extractAddressComponents,
    createMapPinForPlaceId,
  } = useMapUtils();

  const handleDropDownClick = (e) => {
    getTour().onActiveStep(stepName.ADD_TO_TRIP_BUTTON).closeTour();
    e.stopPropagation();
    setAnchorEl(CustomButton ? customAnchor : ref?.current);
    setOpen(!open);
  };

  const handleDropDownHover = (e) => {
    e.stopPropagation();
    clearTimeout(hoverTimeRef.current);
    if (!open) {
      setAnchorEl(ref?.current);
      setOpen(true);
    }
  };

  const resetHighlightScroll = () => {
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );
  };

  const handleDropDownLeave = (e) => {
    e.stopPropagation();
    // Adding delay to avoid flickering
    hoverTimeRef.current = setTimeout(() => {
      setAnchorEl(null);
      setOpen(false);
      resetHighlightScroll();
    }, 300);
  };

  useEffect(() => {
    if (trigger && !anchorEl) {
      setAnchorEl(customAnchor);
      setOpen(true);
    }
  }, [trigger]);

  useEffect(() => {
    // clean up when component unmounts
    return () => {
      if (hoverTimeRef.current) {
        clearTimeout(hoverTimeRef.current);
        hoverTimeRef.current = null;
      }
      dispatch(
        actions.View.setHighlightedSection({
          section: null,
        })
      );
      dispatch(
        actions.View.setHighlightedSection({
          section: null,
        })
      );
      setShowSnackBar(false);
      setToastMessage('');
    };
  }, []);

  const handleAddItem = async (sourceItemId, type) => {
    const itemTitle = placeDetails?.title;
    let parentId = sourceItemId;
    setLoader(true);
    playSound(SOUNDS.inOutClick);
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().closeTour();
    }

    if (type === ITEM_TYPES.DESTINATION) {
      const headingId = (
        await dispatch(
          createItem({
            variables: {
              title: 'Things to do',
              tripId,
              parentId: sourceItemId,
              type: ITEM_TYPES.HEADING,
              index: 0,
            },
            index: 0,
            tripId,
            parentId: sourceItemId,
          })
        )
      )?.payload?.createItem?.id;
      parentId = headingId;
      dispatch(
        actions.View.setCommandBarV2({
          activeLocationId: sourceItemId,
          activeSectionId: headingId,
        })
      );
    }

    // addMapPin
    const mapPin = await createMapPinForPlace(
      {
        title: itemTitle,
        ...placeDetails,
      },
      'ACTIVITY',
      additionalPlaceDetails
    );
    const address = extractAddressComponents(
      additionalPlaceDetails?.addressComponents
    );

    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });

    dispatch(
      createItem({
        variables: {
          type: ITEM_TYPES.ACTIVITY,
          title: itemTitle,
          mapPin: mapPin?.id,
          content: {
            address,
          },
          parentId,
          tripId,
          index: items[parentId]?.children?.length || 0,
        },
        index: items[parentId]?.children?.length || 0,
        tripId,
        parentId,
      })
    );

    setLoader(false);
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().goToNextStep().openTour({
        delay: 1000,
      });
    } else {
      getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).openTour({
        delay: 1000,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (placeId) {
        setLoader(true);
        //   once got the place id then create a new location under that.
        const mPin = await createMapPinForPlaceId(placeId);
        const res = await dispatch(
          createItem({
            variables: {
              type: ITEM_TYPES.DESTINATION,
              title: cityName,
              tripId,
              parentId: tripId,
              mapPin: mPin,
              index: 0,
            },
            index: 0,
            tripId,
            parentId: tripId,
          })
        );
        if (res?.payload?.createItem?.id) {
          handleAddActivity(
            res.payload.createItem.id,
            null,
            null,
            cityName,
            false
          );
          dispatch(
            actions.View.setCommandBarV2({
              activeLocationId: res?.payload?.createItem?.id,
              activeSectionId: null,
            })
          );
        }
        reset();
        setLoader(false);
      }
    })();
  }, [placeId]);

  const handleAddLocation = async (addAsNewDestination = true) => {
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().closeTour();
    }
    setLoader(true);
    playSound(SOUNDS.inOutClick);
    const mapPinId = await createMapPinForPlace(
      placeDetails,
      'LOCATION',
      additionalPlaceDetails,
      tripId,
      false
    );
    const isDestination =
      placeDetails &&
      placeDetails.types &&
      ['locality', 'political'].some((type) =>
        placeDetails.types.includes(type)
      );
    const { city, state, country } = extractAddressComponents(
      additionalPlaceDetails?.addressComponents
    );
    const res = await dispatch(
      createItem({
        variables: {
          type: ITEM_TYPES.DESTINATION,
          title:
            addAsNewDestination || isDestination
              ? placeDetails.title
              : city || state || country || 'New Destination',
          tripId,
          parentId: tripId,
          mapPin: addAsNewDestination || isDestination ? mapPinId : null,
          index: 0,
        },
        index: 0,
        tripId,
        parentId: tripId,
      })
    );

    if (!addAsNewDestination && !isDestination) {
      await handleAddItem(res?.payload?.createItem?.id, ITEM_TYPES.DESTINATION);
    } else {
      dispatch(
        actions.View.setCommandBarV2({
          activeLocationId: res?.payload?.createItem?.id,
          activeSectionId: null,
        })
      );
    }

    setLoader(false);
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().goToNextStep().openTour({ delay: 1000 });
    } else {
      getTour()
        .onActiveStep([
          stepName.FIRST_ACTIVITY_HOVER_INFO,
          stepName.ACTIVITY_ADDED_TO_TRIP_MOBILE,
        ])
        .openTour({ delay: 1000 });
    }
  };

  const handleClickAway = () => {
    setOpen(false);
    setLocationPopperOpen(null);
    handleClose(null);
  };

  const handleAddToTripClick = async (activityTitle, activityDescription) => {
    getTour()
      .onActiveTour([
        'emptyTripFlowWeb',
        'blankTripFlowWeb',
        'sampleTripFlowWeb',
        'quickStartTripFlowWeb',
      ])
      .onActiveStep(stepName.ADD_TO_TRIP_BUTTON)
      .closeTour()
      .goToNextStep();

    // getTour().onActiveStep(stepName.ADD_TO_TRIP_BUTTON).goToNextStep();

    // Here handles the custom logic to add a activity or destination under itinerary
    const isDestination =
      placeDetails &&
      placeDetails.types &&
      ['locality', 'political'].some((type) =>
        placeDetails.types.includes(type)
      );
    if (isDestination) {
      await handleAddLocation();
    } else {
      let locationId = null;
      let locationName = null;
      let sectionId = null;
      let sectionName = null;

      destinationIds.find((locId) => {
        const location = items[locId];
        if (location) {
          const { title } = location;
          // Find if the location name exists in the activity title or activity description
          if (
            activityTitle.includes(title) ||
            activityDescription.includes(title)
          ) {
            locationId = locId;
            locationName = title;
            sectionId = location?.children?.find(
              (childId) => items[childId]?.type === ITEM_TYPES.HEADING
            );
            if (sectionId) {
              // get the section name
              sectionName = items[sectionId]?.title;
            }
            return true;
          }
        }
        return false;
      });

      if (locationId) {
        await handleAddActivity(
          locationId,
          sectionId,
          sectionName,
          locationName,
          true
        );
        getTour()
          .onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO)
          .openTour({ delay: 1000 });
      } else {
        // create a new mapPin with the new location and then add activity under that.
        const { city, state, country } = extractAddressComponents(
          additionalPlaceDetails?.addressComponents
        );
        // if address component doesn't include city then consider state and then country
        setCityName(city || state || country);
        getTour()
          .onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO)
          .openTour({ delay: 1000 });
      }
    }
  };

  return (
    <>
      {CustomButton ? (
        <CustomButton ref={ref} onClick={handleDropDownClick} />
      ) : (
        <div className={classes.buttonContainer}>
          <ButtonGroup
            variant="contained"
            ref={ref}
            {...buttonProps}
            sx={{
              boxShadow: 'none',
              backgroundColor: isAdded ? '#43A047' : '#ED702E',
              '& .MuiButtonGroup-grouped': {
                minWidth: 0, // Set minWidth for grouped buttons
                backgroundColor: isAdded ? '#43A047' : '#ED702E',
              },
            }}>
            <ButtonDefault
              sx={{
                fontSize: '14px',
                padding: '4px 8px',
                margin: 0,
                whiteSpace: 'nowrap',
                border: 'none !important',
                backgroundColor: isAdded ? '#43A047' : '#ED702E',
                '&:hover': {
                  backgroundColor: isAdded ? '#43A047' : '#D35E07',
                },
              }}
              disableFocusRipple
              disableRipple
              onClick={(e) => {
                // disable the button if the activity is already added to the trip
                if (isAdded) return handleDropDownClick(e);
                // if command bar is enabled then directly add the activity to the selected destination and section in nav bar
                if (isCommandBarEnabled) {
                  if (!activeLocationId) {
                    const isDestination =
                      placeDetails &&
                      placeDetails.types &&
                      ['locality', 'political'].some((type) =>
                        placeDetails.types.includes(type)
                      );
                    if (!isDestination) {
                      const { city, state, country } = extractAddressComponents(
                        additionalPlaceDetails?.addressComponents
                      );
                      // if address component doesn't include city then consider state and then country
                      setCityName(city || state || country);
                    } else {
                      handleAddLocation();
                    }
                  } else {
                    handleAddActivity(
                      activeLocationId,
                      activeSectionId,
                      items[activeSectionId]?.title,
                      items[activeLocationId]?.title,
                      false
                    );
                  }
                } else {
                  handleAddToTripClick(
                    placeDetails?.title,
                    placeDetails?.description
                  );
                }
              }}>
              {isAdded ? 'Added to trip' : 'Add to trip'}
            </ButtonDefault>
            <ButtonDefault
              onMouseOver={(e) =>
                !isCommandBarEnabled ? handleDropDownHover(e) : null
              }
              onMouseLeave={handleDropDownLeave}
              onClick={handleDropDownClick}
              size="small"
              sx={{
                backgroundColor: isAdded ? 'success.main' : '#ED702E',
                padding: '2px 2px',
                '&:hover': {
                  backgroundColor: isAdded ? 'success.main' : '#D35E07',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '0',
                  top: '30%',
                  height: '40%',
                  width: '1px',
                  backgroundColor: 'white',
                },
              }}>
              <ArrowDropDownRounded sx={{ padding: 0, margin: 0 }} />
            </ButtonDefault>
          </ButtonGroup>
        </div>
      )}
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        onMouseOver={handleDropDownHover}
        onMouseLeave={handleDropDownLeave}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <List className={classes.list}>
            <ListItem
              key="create-new-location"
              onClick={async () => {
                await handleAddLocation(false);
                handleClickAway();
              }}
              onMouseOver={() => {
                setLocationPopperOpen('create-new-location');
              }}
              className={classes.listItem}
              style={{
                whiteSpace: 'nowrap',
                justifyContent: 'flex-start',
              }}>
              <ListItemIcon
                style={{
                  minWidth: 0,
                  marginRight: 4,
                }}>
                <AddCircleOutlineRounded
                  style={{
                    height: 14,
                    width: 14,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="New Destination"
                primaryTypographyProps={{
                  fontSize: '14px',
                }}
              />
            </ListItem>
            <Divider style={{ marginTop: 4, marginBottom: 4 }} />
            <ItemDropDown
              handleClickAway={handleClickAway}
              handleAddItem={handleAddItem}
              destinationIdSelector={idSelector}
              destinationClassSelector={classSelector}
              enableScroll
            />
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default AddToTripButtonNew;
